import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from '../components/Home/Home';
import Login from '../components/Login/Login';
import PrivateRoute from './PrivateRoute';
import Auth from '../utils/authenticate';

function Routes() {
    return (<>
        <Switch>
            <Route path="/login" render={() => Auth.isAuthenticated() && Auth.user() ? <Redirect to="/" /> : <Login />} />
            <PrivateRoute path='/' component={Home} />
            {/* <Route path="/graph" render={() => <GangDashboardGraph gang={1} />} /> */}
            {/* <Route path="/graph">
                <GraphComponent />
            </Route> */}
        </Switch>
    </>
    )
}

export default Routes