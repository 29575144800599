import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Service from '../../config/networkutils';
import { roles_group_allocation_api, roles_group_remove_api } from '../../config/apiList';
import Auth from '../../utils/authenticate';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const token = Auth.token();

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  progress: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    justifyContent: 'center',
    padding: '20px',
    background: '#E0E0E0'
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function TransferList(props) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(props.roles);
  const [right, setRight] = useState([]);
  const [roleSearch, setRoleSearch] = useState('');
  const [leftcopy] = useState(left);
  // const [roles, setRoles] = useState(props.roles)
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllocatedRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function getAllocatedRoles() {
    Service.get(roles_group_allocation_api + '?role_group=' + props.rowData.id, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        // this.setState({ data: res.data })
        let d = []
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < props.roles.length; j++) {
            if (res.data[i].role === props.roles[j].id) {
              props.roles[j]['allocation_id'] = res.data[i].id
              d.push(props.roles[j])
            }
          }
        }
        setIsLoading(false)
        setLeft(not(props.roles, d))
        setRight(d)
      })
      .catch(error => {
        props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });

      })
  }

  function postAllocatedRoles() {
    setIsLoading(true)
    let data = [];
    for (let i = 0; i < leftChecked.length; i++) {
      data.push({
        role_group: props.rowData.id,
        role: leftChecked[i].id
      })
    }
    Service.post(roles_group_allocation_api + '?role_group=' + props.rowData.id, {
      headers: {
        Authorization: "Token " + token,
      },
      data
    })
      .then(res => {
        props.enqueueSnackbar('Roles in group updated', {
          variant: 'success'
        });
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        // this.setState({ data: res.data })
        setIsLoading(false)
      })
      .catch(error => {
        props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });

        setIsLoading(false)
      })
  }

  function removeAllocatedRoles() {
    setIsLoading(true)
    let data = [];
    for (let i = 0; i < rightChecked.length; i++) {
      data.push(rightChecked[i].allocation_id)
    }
    Service.delete(roles_group_remove_api, {
      headers: {
        Authorization: "Token " + token,
      },
      data: {
        ids: data
      }
    })
      .then(res => {
        props.enqueueSnackbar('Roles removed from group', {
          variant: 'success'
        });
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        setIsLoading(false)
      })
      .catch(error => {
        props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });

        setIsLoading(false)
      })
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  // const handleAllRight = () => {
  //   setRight(right.concat(left));
  //   setLeft([]);
  // };

  const handleCheckedRight = () => {
    // setRight(right.concat(leftChecked));
    // setLeft(not(left, leftChecked));
    // setChecked(not(checked, leftChecked));
    postAllocatedRoles()
  };

  const handleCheckedLeft = () => {
    // setLeft(left.concat(rightChecked));
    // setRight(not(right, rightChecked));
    // setChecked(not(checked, rightChecked));

    removeAllocatedRoles()
  };

  // const handleAllLeft = () => {
  //   setLeft(left.concat(right));
  //   setRight([]);
  // };

  const filterByName = (e) => {
    let value = e.target.value
    let leftFilterd = leftcopy.filter((role) => role.job_roles.toLowerCase().includes(value))
    setRoleSearch(leftFilterd)
    if (value === "") {
      setLeft(leftcopy)
      setRoleSearch("")
    } else if (leftFilterd.length > 0) {
      setRoleSearch(value)
      setLeft(leftFilterd)
    } else {
      this.props.enqueueSnackbar(
        "No roles for the search!",
        {
          variant: "warning",
        }
      );
    }
  }

  const customList = (items, title) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        title={title}
      />
      <Divider />
      {title === 'All Roles' ? <div style={{
        paddingLeft: '1rem',
        width: '70%',
      }}>
        <TextField
          onChange={(e) => filterByName(e,)}
          id="standard-basic"
          label="Search by Employee"
          type="text"
          variant="standard"
          value={roleSearch}

        />
        {title === 'All Roles' && roleSearch ? (
          <IconButton
            aria-label="close"
            onClick={() => { setRoleSearch(""); setLeft(leftcopy) }}
            style={{
              position: "relative",
              left: "15%",
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
        : null}
      <Divider />
      <List
        dense
        component="div"
        role="list"
        style={{ height: '400px', width: '350px', overflow: 'auto' }}
      >
        {items.map((value) => {
          const labelId = `${props.rowData.id}-${value.job_roles}`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.job_roles}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <div>
      {isLoading ?
        <div className={classes.progress}>
          <CircularProgress />
        </div>
        :
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item>{customList(left, 'All Roles')}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList(right, 'Roles in Group')}</Grid>
        </Grid>
      }
    </div>
  );
}

export default withSnackbar(TransferList)