import React from 'react';
import MaterialTable from 'material-table'
import { CheckCircleOutline, HighlightOff } from '@material-ui/icons'
import { withSnackbar } from 'notistack';
import Service from '../../config/networkutils';
import Auth from '../../utils/authenticate';
const token = Auth.token()

class ManageHR extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            isLoading: false
        }
    }

    componentDidMount() {
        this.fetchOps()
    }

    fetchOps() {
        this.setState({ isLoading: true })
        Service.get(`/api/users/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let resData = res.data;
                this.setState({ data: resData, isLoading: false })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false })
            });
    }

    putOps(data) {
        this.setState({ isLoading: true })
        Service.put("/api/request/password/" + data.email + "/?email=" + data.email, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        })
            .then(res1 => {
                this.setState({ isLoading: false });
                this.props.enqueueSnackbar('Access updated for ' + data.email, {
                    variant: 'success',
                });
                this.fetchOps();
            })
            .catch(error => {
                this.setState({ isLoading: false });
                // console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    render() {
        return (
            <>
                {/* {this.state.isLoading ? <RotateCircleLoading color="#005D99" /> : */}
                <MaterialTable
                    title="Manage HR"
                    columns={[
                        { title: 'Name', field: 'full_name', render: (rowData) => rowData.first_name + ' ' + rowData.last_name },
                        { title: 'Email', field: 'email' },
                        {
                            title: 'Active Status',
                            field: 'is_active',
                            lookup: { true: 'Active', false: 'Inactive' },
                        },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    actions={[
                        rowData => ({
                            icon: () => <CheckCircleOutline style={{ color: rowData.is_hr === true ? '#4CAF50' : '#9E9E9E' }} />,
                            tooltip: rowData.is_hr === true ? 'Accessible' : 'Grant Access',
                            onClick: (event, rowData) => {
                                rowData.is_hr = true;
                                this.putOps(rowData)
                            },
                            disabled: rowData.is_hr === true
                        }),
                        rowData => ({
                            icon: () => <HighlightOff style={{ color: rowData.is_hr === false ? '#F44336' : '#9E9E9E' }} />,
                            tooltip: rowData.is_hr === true ? 'Revoke Access' : 'Unaccessible',
                            onClick: (event, rowData) => {
                                rowData.is_hr = false;
                                this.putOps(rowData)
                            },
                            disabled: rowData.is_hr === false
                        })
                    ]}
                    options={{
                        paging: false,
                        pageSize: 20,
                        pageSizeOptions: [20, 50, 100, 200, 500],
                        headerStyle: {
                            fontWeight: 'bold',
                        },
                        actionsColumnIndex: -1,
                        filtering: true
                    }}
                />
                {/* } */}
            </>
        )
    }

}

export default withSnackbar(ManageHR);
