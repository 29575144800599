import { Box, Grid } from '@material-ui/core';
import React, { Component } from 'react'
import Introduction from './Introduction/Introduction.js';
import Projects from './Projects/Projects.js';
import ProjectDetails from './ProjectDetails/ProjectDetails.js';

class UIRefereshment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            height: 0,
            projectHeight: 0,
        }
        this.handleHeight = this.handleHeight.bind(this)
        this.handleProjectHeight = this.handleProjectHeight.bind(this)
        this.handleGraph = this.handleGraph.bind(this)
        this.callProjectDetails = React.createRef()
        this.handleProjectCompleteLoading = this.handleProjectCompleteLoading.bind(this)
    }

    handleHeight(childData) {
        this.setState({
            height: childData
        })
    }

    handleProjectHeight(childData) {
        this.setState({
            projectHeight: childData
        })
        // alert(childData)
    }

    handleGraph(childData) {
        if (this.callProjectDetails && this.callProjectDetails.current) {
            this.callProjectDetails.current.getData(childData)
        }
    }

    handleProjectCompleteLoading(){
        if (localStorage.getItem('lastSelectedProject')) {
            this.callProjectDetails.current.getData(JSON.parse(localStorage.getItem('lastSelectedProject')))
        }
    }

    screenSizeCheck() {
        if (window.innerWidth > 1600) {
            return true
        } else {
            return false
        }
    }


    render() {
        return (
            <div>
                <Box marginTop={1} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Introduction
                                projectHeight={this.state.projectHeight !== 0 && this.state.projectHeight}
                                access={this.props.access}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <Projects
                                handleHeight={this.handleHeight}
                                projectHeight={this.handleProjectHeight}
                                access={this.props.access}
                                getGraph={this.handleGraph}
                                projectCompleteLoading={this.handleProjectCompleteLoading}
                            // getGraph={}
                            />
                        </Grid>
                        <Grid item xs={5} >
                            <ProjectDetails
                                ref={this.callProjectDetails}
                                height={this.state.height !== 0 && this.state.height}
                                access={this.props.access}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </div>
        )
    }
}

export default UIRefereshment;
