import moment from 'moment';
import React, { Component } from 'react';
import Chart from "react-apexcharts";

class OverlapViewGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                chart: {
                    stacked: false,
                    height: 350,
                    zoom: {
                        enabled: true,
                        type: "x",
                        autoScaleYaxis: true,
                        zoomedArea: {
                            fill: {
                                color: "#90CAF9",
                                opacity: 0.4,
                            },
                            stroke: {
                                color: "#0D47A1",
                                opacity: 0.4,
                                width: 1,
                            },
                        },
                    },
                },
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.55,
                        opacityTo: 0,
                    },
                },
                xaxis: {
                    type: "catogory",
                    categories: props.dateLabels,
                    labels: {
                        show: true,
                        rotate: -45,
                        rotateAlways: false,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: false,
                        minHeight: undefined,
                        maxHeight: 120,
                        offsetY: 10,
                        style: {
                            colors: [],
                            fontSize: "12px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 400,
                            cssClass: "apexcharts-xaxis-label",
                        },
                    },
                    title: {
                        text: "Weeks",
                        style: {
                            color: undefined,
                            fontSize: "12px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 600,
                            cssClass: "apexcharts-xaxis-title",
                        },
                    },
                },
                yaxis: {
                    show: true,
                    showAlways: true,
                    showForNullSeries: true,

                    labels: {
                        show: true,
                        align: "left",
                        minWidth: 0,
                        maxWidth: 160,
                        offsetX: -10,
                        style: {
                            colors: [],
                            fontSize: "12px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 400,
                            cssClass: "apexcharts-yaxis-label",
                        },
                    },
                },
                title: {
                    text: "Schedules",
                    rotate: -90,
                    style: {
                        color: undefined,
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 600,
                        cssClass: "apexcharts-yaxis-title",
                    },
                },
                stroke: {
                    show: true,
                    curve: "straight",
                    colors: undefined,
                    width: 3,
                    dashArray: 0,
                },
                grid: {
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                },
            },
            series: [
                {
                    name: props.selectedCrm.name,
                    data: props.schedules
                },
            ],
            rows: props.data
        }
    }

    getWeekOfDates = (startDate, endDate) => {
        const dayINeed = 1; // for Monday

        let currDate = moment(startDate).startOf("day");
        let lastDate = moment(endDate).startOf("day");
        let dates = [["Weeks"]];
        while (currDate.diff(lastDate) <= 0) {
            if (currDate.isoWeekday() === dayINeed) {
                dates.push([currDate.format("DD/MM/YYYY")]);
            }
            currDate.add(1, "days");
        }
        return dates;
    };

    graphData() {
        let rows = this.props.data
        let lowestDate = rows[0].from
        let highestDate = rows[0].to
        for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            if (element.from < lowestDate) {
                lowestDate = element.from
            }
            if (element.to > highestDate) {
                highestDate = element.to
            }
        }
        let allWeekDates = this.getDatesArray(this.getWeekOfDates(lowestDate, highestDate))

        let dates = {}
        for (let i = 0; i < allWeekDates.length; i++) {
            const element = allWeekDates[i];
            dates[element] = 0
        }

        for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            let allWeekDatess = this.getDatesArray(this.getWeekOfDates(element.from, element.to));
            for (let i = 0; i < allWeekDatess.length; i++) {
                const element1 = allWeekDatess[i];
                if (dates.hasOwnProperty(element1)) {
                    dates[element1] = parseFloat(dates[element1]) + element.hasOwnProperty('updated_qty') ? parseFloat(element.updated_qty) : parseFloat(element.qty)
                }
            }
        }

        let series = []
        let data = []
        let dataLables = []
        for (const [key, value] of Object.entries(dates)) {
            data.push(value)
            dataLables.push(key)
        }
        series.push({
            name: "Total",
            data: data
        })
        this.setState({
            allWeekDates: allWeekDates,
            dateLabels: dataLables,
            series: series,
            options: {
                ...this.state.options,
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: dataLables
                }
            }
        })
    }

    componentDidMount() {
        this.graphData()
    }

    getDatesArray(data) {
        let datesArray = []
        for (let i = 1; i < data.length; i++) {
            const element = data[i];
            datesArray.push(element[0])
        }
        return datesArray
    }
    render() {
        return (
            <div>
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    height={500}
                    width={"100%"}
                    type={"area"}
                />
            </div>
        );
    }
}

export default OverlapViewGraph;