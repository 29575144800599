import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react'
import Auth from '../../utils/authenticate';
import Service from '../../config/networkutils';
import RoleWorkTypeLink from './RoleWorkTypeLink';
import { roles_api } from '../../config/apiList';



const token = Auth.token();

class WorkType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roles:{},
            loading:true
        }
        this.handleRefresh = this.handleRefresh.bind(this)
    }

    getData() {
        Service.get('/api/role/worktype/role_work_type_link/', {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            this.setState({ data: res.data , loading:false})
        }).catch(error => {
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    postData(newData) {
        if (newData.name.length === 0) {
            this.props.enqueueSnackbar('Work Type Name cannot be Empty', {
                variant: 'success'
            });
            return
        } else {
            Service.post('/api/worktype/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: newData
            }).then(res => {
                this.getData()
                this.props.enqueueSnackbar('Work Type Added Successfully', {
                    variant: 'success'
                });
            }).catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        }
    }

    editData(newData) {
        if (newData.name.length === 0) {
            this.props.enqueueSnackbar('Work Type Name cannot be Empty', {
                variant: 'warning'
            });

        } else {
            Service.put('/api/worktype/' + newData.id, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: newData
            }).then(res => {
                this.getData()
                this.props.enqueueSnackbar('Work Type Edited Successfully', {
                    variant: 'success'
                });
            }).catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        }

    }

    getRoles(){
        Service.get(roles_api, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            this.setState({ roles: res.data })
        }).catch(error => {
            console.log(error)
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }


    componentDidMount() {
        this.getData()
        this.getRoles()
    }

    handleRefresh(){
        this.setState({
            loading:true
        })
        this.getData()
    }

    render() {
        return (
            <div>
                <Paper elevation={1}>
                    <MaterialTable
                        title={'Work Type'}
                        columns={[{ title: 'Name', field: 'name' }, { title: 'Short Form', field: 'short_form' }]}
                        data={this.state.data}
                        isLoading={this.state.loading}
                        detailPanel={rowData => {return(
                            <div>
                                <RoleWorkTypeLink roles={this.state.roles} rowData={rowData} refresh={this.handleRefresh}/>
                            </div>
                        )}}
                        onRowClick={(event, rowData, togglePanel) => togglePanel()}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        if (Object.keys(newData).length === 0) {
                                            this.props.enqueueSnackbar('Work Type Name cannot be Empty', {
                                                variant: 'warning'
                                            });
                                            reject()
                                        } else {
                                            this.postData(newData)
                                            resolve();
                                        }
                                    }, 1000);
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        if (Object.keys(newData).length === 0) {
                                            this.props.enqueueSnackbar('Work Type Name cannot be Empty', {
                                                variant: 'warning'
                                            });
                                            reject()
                                        } else {
                                            this.editData(newData)
                                            resolve();
                                        }
                                    }, 1000);
                                }),
                            //       onRowDelete: oldData =>
                            //           new Promise((resolve, reject) => {
                            //               setTimeout(() => {

                            //                   this.deleteOps(oldData.id)

                            //                   resolve();
                            //               }, 1000);
                            //           })
                        }}
                    >

                    </MaterialTable>
                </Paper>
            </div>
        )
    }
}

export default withSnackbar(WorkType);
