import React, { Component } from 'react';
import { withStyles, Box, Card, Divider, Paper, InputAdornment } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { getCookie } from '../../utils/CookieHelper';
import Service from '../../config/networkutils';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const token = getCookie("ptd_ts_token");

const useStyles = (theme) => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 300,
        height: 400,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
});

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}
class GangRolesTransfer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: [],
            left: [],
            right: [],
            loading: true,
            leftCopy: [],
            rightCopy: [],
            leftSearch: '',
            rightSearch: '',
        }
    }

    componentDidMount() {
        this.getRoles();
    }

    getRoles() {
        // this.setState({ loading: true })
        Service.get(`/api/gang/report/roles/gang_report_roles/?gang_level=${this.props.gang_level}&category=${this.props.gang?.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var left = res.data.roles
                var right = res.data.linked_roles
                this.setState({
                    left,
                    leftCopy: left,
                    rightCopy: right,
                    right,
                    leftSearch: '',
                    rightSearch: '',
                    loading: false
                });
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    linkAndUnlinkRole = (action, data) => {
        Service.post(`/api/gang/report/roles/link_and_delink_role/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                gang_level: this.props.gang_level,
                category: this.props.gang?.id,
                role: data.map(role => role.role_id),
                action: action,
            }
        })
            .then(res => {
                // this.props.enqueueSnackbar("Role linked successfully", { variant: "success" });
                this.getRoles()
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.getRoles()
            });
    }

    filterLeftRole = (value) => {
        let data = (this.state.left.length === 0 ? this.state.leftCopy : this.state.left);
        let search = value.toLowerCase();
        let filteredData = data.filter(item => item.role_name.toLowerCase().includes(search));
        this.setState({ left: filteredData, leftSearch: value }, () => {
            if (value === "") {
                this.setState({ left: this.state.leftCopy, leftSearch: "" })
            }
        });
    }

    filterRightRole = (value) => {
        let data = (this.state.right.length === 0 ? this.state.rightCopy : this.state.right);
        let search = value.toLowerCase();
        let filteredData = data.filter(item => item.role_name.toLowerCase().includes(search));
        this.setState({ right: filteredData, rightSearch: value }, () => {
            if (value === "") {
                this.setState({ right: this.state.rightCopy, rightSearch: "" })
            }
        });
    }

    render() {

        const { classes } = this.props;

        const leftChecked = intersection(this.state.checked, this.state.left);
        const rightChecked = intersection(this.state.checked, this.state.right);

        const handleToggle = (value) => () => {
            const currentIndex = this.state.checked.indexOf(value);
            const newChecked = [...this.state.checked];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            this.setState({ checked: newChecked });
        };

        const handleCheckedRight = () => {
            this.setState({
                right: this.state.right.concat(leftChecked),
                left: not(this.state.left, leftChecked),
                checked: not(this.state.checked, leftChecked)
            }, () => {
                this.linkAndUnlinkRole("link", leftChecked)
            });

        };

        const handleCheckedLeft = () => {
            this.setState({
                left: this.state.left.concat(rightChecked),
                right: not(this.state.right, rightChecked),
                checked: not(this.state.checked, rightChecked)
            }, () => {
                this.linkAndUnlinkRole("delink", rightChecked)
            });
        };

        const customLeftList = (items, title) => (
            <Card>
                <Box display='flex' flexDirection='row' alignItems="center" m={1}>
                    <Box flexGrow={1} >
                        <b>{title + " (" + this.state.left.length + ") "}</b>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                        <TextField
                            autoComplete='off'
                            size='small'
                            placeholder="Search"
                            id="search"
                            value={this.state.leftSearch}
                            onChange={(e) => this.filterLeftRole(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                            }}
                        />
                    </Box>
                </Box>
                <Divider />
                <Paper className={classes.paper}>
                    <List dense component="div" role="list">
                        {items.map((value) => {
                            const labelId = `transfer-list-item-${value}-label`;
                            return (
                                <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={this.state.checked.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={value?.role_name} />
                                </ListItem>
                            );
                        })}
                        <ListItem />
                    </List>
                </Paper>
            </Card>
        );

        const customRightList = (items, title) => (
            <Card>
                <Box display='flex' flexDirection='row' alignItems="center" m={1}>
                    <Box flexGrow={1}>
                        <b>{title + " (" + this.state.right.length + ") "}</b>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                        <TextField
                            autoComplete='off'
                            size='small'
                            placeholder="Search"
                            id="search"
                            value={this.state.rightSearch}
                            onChange={(e) => this.filterRightRole(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                            }}
                        />
                    </Box>
                </Box>
                <Divider />
                <Paper className={classes.paper}>
                    <List dense component="div" role="list">
                        {items.map((value) => {
                            const labelId = `transfer-list-item-${value}-label`;
                            return (
                                <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={this.state.checked.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={value?.role_name} />
                                </ListItem>
                            );
                        })}
                        <ListItem />
                    </List>
                </Paper>
            </Card>
        );

        return (
            <div>
                {
                    this.state.loading ? <div><Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                        <CircularProgress color='primary' />
                    </Box></div> :
                        <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            className={classes.root}
                        >
                            <Grid item>{customLeftList(this.state.left, 'Roles',)}</Grid>
                            <Grid item>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        &gt;
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        &lt;
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item>{customRightList(this.state.right, 'Linked Roles', 'right')}</Grid>
                        </Grid>
                }
            </div >
        );
    }
}

export default withStyles(useStyles)(withSnackbar(GangRolesTransfer));
