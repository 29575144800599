import React, { Component, createRef } from 'react'
import Button from '@material-ui/core/Button';
import { Grid, Dialog, DialogContent, Typography, TextField, InputAdornment, Slide, AppBar, Toolbar, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import Service from '../../../config/networkutils';
import RotateCircleLoading from 'react-loadingg/lib/RotateCircleLoading';
import { withSnackbar } from 'notistack';
import { getCookie } from '../../../utils/CookieHelper';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { generalSortOrder } from '../../../config/abstractedVariables';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import UnallocatedRoles from './UnallocatedRoles';
import StaffLocationView from './StaffLocationView';
import { LoopCircleLoading } from 'react-loadingg';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const token = getCookie("ptd_ts_token");
const months = { 0: 'January', 1: 'Febrauary', 2: 'March', 3: 'April', 4: 'May', 5: 'June', 6: 'July', 7: 'August', 8: 'September', 9: 'October', 10: 'November', 11: 'December' }


const useStyle = (theme) => ({
  customGridStyle: {
    margin: 0,
    paddingRight: '8px',
    paddingLeft: '16px',
    marginBottom: '16px'
  },
  appBar: {
    position: 'fixed',
    top: 0,
  },
  formContainer: {
    marginTop: '64px'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    width: "calc(100% - 20px)"
  },
  rows: {
    backgroundColor: "#EDFAFF",
  },
  leftSticky: {
    zIndex: 99, position: "sticky",
    left: 0,
    background: "#F8F8F8",
    boxShadow: "1px 2px 1px grey",
    textAlign: "center"
  }
})

// const validQty = [0.2, 0.4, 0.5, 0.6, 0.8, 1]

class UnallocatedCRM extends Component {
  constructor(props) {
    super(props);
    this.empTableRef = React.createRef()
    this.state = {
      columns: [],
      data: [],
      copyData: [],
      zoom: 0.9,
      project_name: '',
      project_no: '',
      contract_start_date: '',
      contract_end_date: '',
      weekDateRange: [],
      start_date: '',
      subTableCol: [],
      subTabledata: [],
      showDialog: false,
      selectedRole: [],
      selectedEmployee: {},
      modelOpen: false,
      roles: [],
      rolesObj: {},
      rolesToPost: "",
      isLoading: true,
      allEmp: [],
      allEmpObj: {},
      reviewModal: false,
      dataToUpload: [],
      dropZoneModal: false,
      reviewTableCol: [],
      reviewTableData: [],
      newRoles: [],
      disciplineArr: [],
      disciplineObj: {},
      discipline: '',
      owner_delivery_unit: {},
      owner_region: {},
      sector: {},
      rolesForSort: [],
      sortByRoles: [
        // { id: 117 },
        // { id: 3 },
        // { id: 2 },
      ],
      totalCountType: false,
      rowDelete: false,
      deleteRowData: [],
      deleteRowIndex: [],
      deleteDisabled: true,
      deleteDisabled1: false,
      addDisabled: false,
      multipleSelect: false,
      assignDisabled: true,
      workType: [],
      selectedEmployees: [],
      customQtyValues: [],
      singleCustomQtyValues: [],
      singleShowDialog: false,
      type: null,
      addQtyDisbaled: false,
      deleteQtyID: '',
      allocationDialogSize: 'xs',
      allocationDialog: false,
      role: null,
      addResource: false,
      scrollTop: false,
      openedRows: [],
      allSubSectors: [],
      allRoles: [],
      allOwnerRegions: [],
      allOwnerDeliveryUnits: [],
      allProjects: [],
      selectedSubSector: [],
      selectedRoleGroup: {},
      selectedOwnerRegion: [],
      selectedOwnerDelivery: [],
      selectedRoles: [],
      selectedCRM: {},
      tabValue: 'role',
      allMonths: [],
      finalColumns: [],
      selectedStartDate: moment().subtract(4, 'months').startOf("month").format("YYYY-MM-DD"),
      selectedEndDate: moment().add(4, 'months').endOf("month").format("YYYY-MM-DD"),
      isRoleLoading: true
    }
    this.topRef = createRef()
    this.roleRef = createRef()
    this.handleAllReports = this.handleAllReports.bind(this)
  }


  componentDidMount() {
    this.getData()
  }

  getDialogSize = (size) => {
    this.setState({ allocationDialogSize: size })
  }

  closeDialog = () => {
    this.setState({ allocationDialog: false, allocationDialogSize: "xs", addResource: false }, () => {
      this.handleReload()
    })
  }

  handleRowData(data) {
    let empData = JSON.parse(JSON.stringify(data))
    this.setState({
      singleCustomQtyValues: empData.shdl_data,
      selectedEmployee: empData,
      type: empData.type
    })
  }

  handleOpenDialog() {
    this.setState({ singleShowDialog: true })
  }

  handleReload() {
    this.getAllCRM()
  }

  handleReset() {
    this.setState({
      multipleSelect: false,
      assignDisabled: true
    })
  }

  handleSelectedData(data) {
    let selectedEmployees = [...this.state.selectedEmployees]
    if (data.is_checked) {
      selectedEmployees.push(data)
    } else {
      for (let i = 0; i < selectedEmployees.length; i++) {
        const element = selectedEmployees[i];
        if (element.id === data.id) {
          selectedEmployees.splice(i, 1)
        }
      }
    }
    this.setState({
      selectedEmployees: selectedEmployees
    }, () => {
      if (this.state.selectedEmployees.length > 0) {
        this.setState({
          assignDisabled: false
        })
      } else {
        this.setState({
          assignDisabled: true
        })
      }
      let customQtyValues = []
      for (let i = 0; i < this.state.selectedEmployees.length; i++) {
        const element = this.state.selectedEmployees[i];
        if (customQtyValues.length === 0) {
          let shdl_data = []
          for (let l = 0; l < element.shdl_data.length; l++) {
            const ite = element.shdl_data[l];
            if (ite.from_date && ite.to_date) {
              ite['from'] = new Date(ite.from_date)
              ite['to'] = new Date(ite.to_date)
              shdl_data.push(ite)
            } else {
              shdl_data.push({ from: this.props.project.contractor_start_date, to: this.props.project.contractor_end_date, qty: 0 })
            }
          }
          customQtyValues.push({
            role_id: element.role_id,
            type: element.type,
            shdl_data: shdl_data
          })
        } else {
          let found = customQtyValues.find(ele => ele.role_id === element.role_id)
          if (found) {
            let shdl_data = found.shdl_data
            for (let k = 0; k < element.shdl_data.length; k++) {
              const item = element.shdl_data[k];
              if (item.from_date && item.to_date) {
                item['from'] = new Date(item.from_date)
                item['to'] = new Date(item.to_date)
                shdl_data.push(item)
              } else {
                shdl_data.push({ from: this.props.project.contractor_start_date, to: this.props.project.contractor_end_date, qty: 0 })
              }
              found.shdl_data = shdl_data
            }
          } else {
            let shdl_data = []
            for (let j = 0; j < element.shdl_data.length; j++) {
              const item = element.shdl_data[j];
              if (item.from_date && item.to_date) {
                item['from'] = new Date(item.from_date)
                item['to'] = new Date(item.to_date)
                shdl_data.push(item)
              } else {
                shdl_data.push({ from: this.props.project.contractor_start_date, to: this.props.project.contractor_end_date, qty: 0 })
              }
            }
            customQtyValues.push({
              role_id: element.role_id,
              type: element.type,
              shdl_data: shdl_data
            })
          }
        }
      }
      this.setState({ customQtyValues: customQtyValues })
    })
  }

  // getAllRoles(){
  //   Service.get(`/api/prp/template/get_role_unallocated_schedules/?archive_reason=${0}`, {
  //     headers: {
  //       Authorization: "Token " + token,
  //     },
  //   })
  //     .then(res => {
  //       this.makeReport(res.data, res.data.all_dates, 'week')

  //     })
  //     .catch(error => console.log(error))
  // }

  getDropdownData() {
    this.setState({ isLoading: true })
    Service.get('/api/roles/get_default_data/', {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        this.setState({
          allRoles: res.data.roles,
          allOwnerRegions: res.data.owner_region_sector,
          allSubSectors: res.data.sub_sector,
          allOwnerDeliveryUnits: res.data.owner_delivery,
          roleGroup: res.data.role_group
        }, () => {
          if (this.state.tabValue === 'role') {
            this.getAllRoles()
          } else {
            this.getAllCRM()
          }
        })
      })
      .catch(error => console.log(error))

  }

  getAllRoles() {
    this.setState({
      isRoleLoading: true
    })
    Service.get(`/api/prp/template/get_role_unallocated_schedules/?archive_reason=${0}&from_date=${this.state.selectedStartDate}&to_date=${this.state.selectedEndDate}`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        this.makeReport(res.data.crm_data, res.data.all_dates, 'week')
      })
      .catch(error => {
        console.log(error)
        this.props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        })
      })
  }

  makeReport(allRoles, datesArray, type) {

    let reportedRoles = []
    let templateRoles = []
    let splittedCRM = []
    let finalColumns = []
    let allMonths = []

    for (let index = 0; index < allRoles.length; index++) {
      const element = allRoles[index];

      const startOfMonth = this.state.selectedStartDate ? moment(this.state.selectedStartDate).startOf('month') : moment().subtract(2, 'months').startOf('month');
      const endOfMonth = this.state.selectedEndDate ? moment(this.state.selectedEndDate).endOf('month') : moment().add(6, 'months').endOf('month');

      let startDate = moment(startOfMonth)
      let endDate = moment(endOfMonth)

      let result = [];

      if (endDate.isBefore(startDate)) {
        throw new Error("End date must be greater than start date.")
      }

      while (startDate.isBefore(endDate)) {
        result.push(months[startDate.month()] + ' ' + startDate.year());
        startDate.add(1, 'month');
      }

      // let allWeekDates = weekStartDates(startOfMonth, endOfMonth);

      let final = []
      let colspan = 1;
      if (type === 'week') {
        for (let i = 0; i < datesArray.length; i++) {
          // debugger
          let date = moment(datesArray[i], 'DD/MM/YYYY')
          let monthYear = months[date.month()] + ' ' + date.year()
          if (!result.length) {
            result.push(monthYear)
            final.push({ month: monthYear, colSpan: colspan })
          }
          else {
            if (result[result.length - 1] === monthYear) {
              colspan += 1
              final[final.length - 1]['colSpan'] = colspan
            }
            else {
              colspan = 1
              result.push(monthYear)
              final.push({ month: monthYear, colSpan: colspan })
            }
          }

        }
      } else if (type === 'month') {
        for (let i = 0; i < datesArray.length; i++) {
          let date = moment(datesArray[i])
          if (final.map((ele) => ele.month).indexOf(date.year()) === -1) {
            final.push({ month: date.year(), colSpan: 1 })
          } else {
            final[final.map((ele) => ele.month).indexOf(date.year())]['colSpan'] += 1
          }

        }

      } else {
        for (let i = 0; i < datesArray.length; i++) {
          let year = datesArray[i].split('-')[1]
          if (final.map((ele) => ele.month).indexOf(year) === -1) {
            final.push({ month: year, colSpan: 1 })
          } else {
            final[final.map((ele) => ele.month).indexOf(year)]['colSpan'] += 1
          }

        }
      }
      for (let index = 0; index < final.length; index++) {
        const element = final[index];
        element.width = 150 * element.colSpan
      }

      allMonths = final
      // reportedRoles.push(this.state.allRoles.filter((ele) => ele.id === element.role_id)[0])
      let weeks = [];
      let weekdata = [];
      // debugger
      for (let m = 0; m < element.project_details.length; m++) {
        let dates = element.project_details[m].dates
        Object.keys(dates).forEach(key => {
          let date = key
          if (dates.hasOwnProperty(date) && dates[date] !== 0) {
            if (weeks.indexOf(date) === -1) {
              weeks.push(date);
              weekdata.push({ name: [element.project_details[m].crm_name], project_code: [[element.project_details[m].project_code]], crmId: [element.project_details[m].crm_id], crm_color_code: [element.project_details[m].color_code], qty: [dates[date]] })
            } else {
              if (weekdata[weeks.indexOf(date)]['name'] !== "") {
                weekdata[weeks.indexOf(date)]['name'].push(element.project_details[m].crm_name)
                weekdata[weeks.indexOf(date)]['project_code'].push(element.project_details[m].project_code)
                weekdata[weeks.indexOf(date)]['crmId'].push(element.project_details[m].crm_id)
                weekdata[weeks.indexOf(date)]['crm_color_code'].push(element.project_details[m].color_code)
                weekdata[weeks.indexOf(date)]['qty'].push(dates[date])
              } else if (weekdata[weeks.indexOf(date)]['name'] === "") {
                weekdata[weeks.indexOf(date)]['name'].push(element.project_details[m].crm_name)
                weekdata[weeks.indexOf(date)]['project_code'].push(element.project_details[m].project_code)
                weekdata[weeks.indexOf(date)]['crmId'].push(element.project_details[m].crm_id)
                weekdata[weeks.indexOf(date)]['crm_color_code'].push(element.project_details[m].color_code)
                weekdata[weeks.indexOf(date)]['qty'].push(dates[date])
              }
            }
          }
          else {
            if (weeks.indexOf(date) === -1) {
              weeks.push(date);
              weekdata.push({ name: [], crmId: [], project_code: [], crm_color_code: [], qty: [] })
            }
          }
        })
      }
      element['weeks'] = weeks;
      element['weekdata'] = weekdata
      if (element.weekdata.length > 0) {
        if ((!element.end_date ? true : moment(element.end_date).isAfter(moment().format()))) {
          // filterEmp.push(element)
        }
      }

      let columns = []
      for (let i = 0; i < datesArray.length; i++) {
        let newObj = {
          title: type === "week" ? moment(datesArray[i], 'DD/MM/YYYY').format('DD MMM') : type === "month" ? moment(datesArray[i]).format('MMM YYYY') : type === "quarter" ? datesArray[i] : '',
          field: datesArray[i],
          type: 'number',
          width: 150,
          sorting: false,
          editable: 'never',
          filtering: false
        }
        columns.push(newObj)
      }
      finalColumns = columns


    }
    // allRoles.sort((a, b) => b.role_name - a.role_name);
    splittedCRM.push({ role: 0, allCRMCopy: allRoles, allCRM: allRoles })
    this.setState({ splittedCRM, reportedRoles, templateRoles, isLoading1: false, allMonths: allMonths, finalColumns: finalColumns, isRoleLoading: false, isLoading: false }, () => {
      console.log(this.state.splittedCRM)
    })
  }

  getData() {
    this.getDropdownData()
  }

  handleDisabled(childData) {
    this.setState({
      assignDisabled: childData
    })
  }



  getAllCRM = () => {
    this.setState({ isLoading: true })
    Service.get('/api/crm/get_unallocated_demand_crm/', {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        let prptArray = res.data.crm_data;
        let newData = []
        this.prpData = prptArray;
        this.setColumns(res.data)
        prptArray.forEach(prptObj => {
          let dates = {}
          // let prptEmpArray = prptObj.prp_role
          // let allEmpRoleObj = {}
          // debugger
          // prptEmpArray.forEach(empEle => {
          //     allEmpRoleObj = { ...allEmpRoleObj, ...{ [empEle.id]: empEle.job_title } }
          // })

          let set = []
          for (const item in dates) {
            set.push(item)
          }
          let sorted = set.sort(function (a, b) {
            // sorts the dates array
            a = a.toString().split('/');
            b = b.toString().split('/');
            return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
          });

          let st_en_date = { start_date: sorted[0], end_date: sorted[sorted.length - 1] }
          dates = {
            ...dates,
            // total: total.toFixed(2),
            ...prptObj,
            ...st_en_date,
            // project_total: project_total.toFixed(2),
            // tender_total: tender_total.toFixed(2)
          }
          newData.push(dates)
        })
        let rolesForSort = [];

        let sorted = [];
        for (let i = 0; i < generalSortOrder.length; i++) {
          for (let j = 0; j < newData.length; j++) {
            if (this.state.rolesObj[newData[j].role] ? this.state.rolesObj[newData[j].role].includes(generalSortOrder[i]) : undefined) {
              sorted.push(newData[j])
            }
          }
        }

        for (let i = 0; i < newData.length; i++) {
          let matched = false
          for (let j = 0; j < generalSortOrder.length; j++) {
            if (this.state.rolesObj[newData[i].role] ? this.state.rolesObj[newData[i].role].includes(generalSortOrder[j]) : undefined) {
              matched = true;
            }
          }
          if (!matched) {
            sorted.push(newData[i])
          }
        }

        sorted.forEach((item) => item.isChecked = false)
        sorted.sort((a, b) => (!this.state.rolesObj[a.role] || !this.state.rolesObj[b.role] ? null : this.state.rolesObj[a.role] > this.state.rolesObj[b.role] ? 1 : -1))
        let keys = Object.keys(this.state)
        for (let i = 0; i < keys.length; i++) {
          const element = keys[i];
          if (element >= 0) {
            this.setState({ [element]: false }, () => {
              // this.setState({ [element]: true })
            })
          }
        }
        this.setState({ data: sorted, rolesForSort: rolesForSort, copyData: sorted, selectedOwnerDelivery: [], selectedRole: [], selectedRoleGroup: [], selectedOwnerRegion: [], selectedSubSector: [] }, () => {
          // if (localStorage.getItem("searchKey")) {
          //     this.handleSearchChange(localStorage.getItem("searchKey"))
          // }
          // if (localStorage.getItem("openedRows")) {
          //     let openedRows = JSON.parse(localStorage.getItem("openedRows"))
          //     for (let index = 0; index < openedRows.length; index++) {
          //         const element = openedRows[index];
          //         this.setState({ [element]: true })
          //     }
          // }
          this.setState({ isLoading: false })
        })
        this.copyOfData = newData
        // }, 1000);
      }).catch(error => {
        console.log(error)
        this.setState({ isLoading: false })
        this.props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        })
      })
  }

  getWeekOfDates = (startDate, endDate) => {
    const dayINeed = 1; // for Monday

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');
    let dates = []
    while (currDate.diff(lastDate) <= 0) {
      if (
        currDate.isoWeekday() === dayINeed ||
        currDate.format('DD/MM/YYYY') === moment(startDate).startOf('day').format('DD/MM/YYYY')
      ) {
        dates.push(currDate.format('DD/MM/YYYY'));
      }
      currDate.add(1, 'days')
    }
    return dates
  }


  setColumns(data) {
    let column = [];
    let dates = data.all_dates
    for (let index = 0; index < dates.length; index++) {
      const element = dates[index];
      let newObj = {
        title: element,
        field: element,
        type: "numeric",
        sorting: false,
        headerStyle: {
          // transform: "rotate(270deg)",
          width: '50px',
          textAlign: "center",
        },
        cellStyle: {
          textAlign: "center",
        },
        editable: "never",
        filtering: false,
      };
      if (!column.find(ele => ele.field === element)) {
        column.push(newObj);
      }
    }
    // column.sort((a, b) => new Intl.DateTimeFormat('en-US').format(new Date(a.field)) -
    // new Intl.DateTimeFormat('en-US').format(new Date(b.field)))
    console.log(column)
    this.setState({
      columns: column,
    });
  }

  tableCellRender(role, columnLabel) {
    // returns a lookup names
    if (columnLabel.field === "role") {
      return columnLabel.lookup[role[columnLabel.field]];
    } else if (columnLabel.field === "descipline") {
      return columnLabel.lookup[role[columnLabel.field]];
    } else if (columnLabel.editable === "never") {
      return role.dates[columnLabel.field];
    }
    return role[columnLabel.field];
  }

  handleSearchChange(data) {
    console.log(data)
    const data_lowercase = data.toLowerCase()
    var search_words = data_lowercase.split(" ");
    search_words = search_words.filter(n => n)
    var filtered_data = []
    if (search_words.length === 0) {
      filtered_data = this.state.copyData
    }

    for (let i = 0; i < this.state.copyData.length; i++) {
      const element = this.state.copyData[i];
      var num_of_passed = false
      for (let j = 0; j < search_words.length; j++) {
        const el = search_words[j];
        // const role = this.state.rolesObj[element.role]
        if (element.name) {
          const role = element.name
          if (role !== undefined)
            num_of_passed = role.toLowerCase().includes(el)
          if (!num_of_passed) {
            break
          }
        }

      }
      if (num_of_passed) {
        filtered_data.push(element)
      }
    }
    if (data.length === 0) {
      localStorage.removeItem("searchKey")
      localStorage.removeItem("openedRows")
    } else {
      localStorage.setItem("searchKey", data)
    }
    this.setState({ data: filtered_data })
  }


  scrolltoTop() {
    if (this.topRef.current) {
      this.topRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  handleRoleGroupChange = (e, value) => {
    let searchParams = new URLSearchParams(window.location.search)
    if (value) {
      if (Object.keys(value).length > 0) {
        let selectedRoleGroupIds = [];
        Object.keys(value).forEach((region) => {
          if (region === "id")
            selectedRoleGroupIds.push(value[region])
        })
        if (searchParams.has("roleGroup")) {
          searchParams.set("roleGroup", selectedRoleGroupIds.join(","))
        } else {
          searchParams.append("roleGroup", selectedRoleGroupIds.join(","))
        }
      } else if (Object.keys(value).length === 0) {
        searchParams.delete("roleGroup")
      }
      let hash = window.location.hash
      let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;
      window.history.pushState(null, '', newRelativePathQuery);
    }
    this.setState({ selectedRoleGroup: value })
  }

  handleSubSectorChange = (e, value) => {
    let searchParams = new URLSearchParams(window.location.search)
    if (value.length > 0) {
      let selectedSubSectorIds = [];
      value.forEach((subSector) => {
        selectedSubSectorIds.push(subSector.id)
      })
      if (searchParams.has("selectedSubSector")) {
        searchParams.set("selectedSubSector", selectedSubSectorIds.join(","))
      } else {
        searchParams.append("selectedSubSector", selectedSubSectorIds.join(","))
      }

    } else if (value.length === 0) {
      searchParams.delete("selectedSubSector")
    }
    // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
    // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
    let hash = window.location.hash
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

    window.history.pushState(null, '', newRelativePathQuery);


    this.setState({ selectedSubSector: value })
  }

  handleRoleChange = (e, value) => {
    let searchParams = new URLSearchParams(window.location.search)
    if (value.length > 0) {
      let selectedRolesIds = [];
      value.forEach((region) => {
        selectedRolesIds.push(region.id)
      })
      if (searchParams.has("role")) {
        searchParams.set("role", selectedRolesIds.join(","))
      } else {
        searchParams.append("role", selectedRolesIds.join(","))
      }

    } else if (value.length === 0) {
      searchParams.delete("role")

    }
    // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
    // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
    let hash = window.location.hash
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

    window.history.pushState(null, '', newRelativePathQuery);

    this.setState({ selectedRole: value, reportedRoles: value })
  }


  handleOwnerDeliveryChange = (e, value) => {
    let searchParams = new URLSearchParams(window.location.search)
    if (value.length > 0) {
      let selectedOwnerDeliveryIds = [];
      value.forEach((od) => {
        selectedOwnerDeliveryIds.push(od.id)
      })
      if (searchParams.has("selectedOwnerDelivery")) {
        searchParams.set("selectedOwnerDelivery", selectedOwnerDeliveryIds.join(","))
      } else {
        searchParams.append("selectedOwnerDelivery", selectedOwnerDeliveryIds.join(","))
      }

    } else if (value.length === 0) {
      searchParams.delete("selectedOwnerDelivery")

    }
    // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
    // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
    let hash = window.location.hash
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

    window.history.pushState(null, '', newRelativePathQuery);

    this.setState({ selectedOwnerDelivery: value })
  }

  filterCRM = () => {
    let region = this.state.selectedOwnerRegion.map((region) => region.id)
    let subSector = this.state.selectedSubSector.map((sector) => sector.id)
    let ownerDelivery = this.state.selectedOwnerDelivery.map((od) => od.id)
    let role = this.state.selectedRole.map((role) => role.id)
    let roleGroup = this.state.selectedRoleGroup.id
    let url = `/api/crm/get_unallocated_demand_crm/`

    if (region.length > 0) {
      url += `?region=${region.join(',')}`
    } else if (subSector.length > 0) {
      url += `?sub_sector=${subSector.join(',')}`
    } else if (ownerDelivery.length > 0) {
      url += `?owner_delivery=${ownerDelivery.join(',')}`
    } else if (role.length > 0) {
      url += `?role=${role.join(',')}`
    } else if (roleGroup) {
      url += `?role_group=${roleGroup}`
    }

    this.setState({
      isLoading: true
    })

    Service.get(url, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        let prptArray = res.data.crm_data;
        let newData = []
        this.prpData = prptArray;
        this.setColumns(res.data)
        prptArray.forEach(prptObj => {
          let dates = {}
          // let prptEmpArray = prptObj.prp_role
          // let allEmpRoleObj = {}
          // debugger
          // prptEmpArray.forEach(empEle => {
          //     allEmpRoleObj = { ...allEmpRoleObj, ...{ [empEle.id]: empEle.job_title } }
          // })

          let set = []
          for (const item in dates) {
            set.push(item)
          }
          let sorted = set.sort(function (a, b) {
            // sorts the dates array
            a = a.toString().split('/');
            b = b.toString().split('/');
            return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
          });

          let st_en_date = { start_date: sorted[0], end_date: sorted[sorted.length - 1] }
          dates = {
            ...dates,
            // total: total.toFixed(2),
            ...prptObj,
            ...st_en_date,
            // project_total: project_total.toFixed(2),
            // tender_total: tender_total.toFixed(2)
          }
          newData.push(dates)
        })
        let rolesForSort = [];

        let sorted = [];
        for (let i = 0; i < generalSortOrder.length; i++) {
          for (let j = 0; j < newData.length; j++) {
            if (this.state.rolesObj[newData[j].role] ? this.state.rolesObj[newData[j].role].includes(generalSortOrder[i]) : undefined) {
              sorted.push(newData[j])
            }
          }
        }

        for (let i = 0; i < newData.length; i++) {
          let matched = false
          for (let j = 0; j < generalSortOrder.length; j++) {
            if (this.state.rolesObj[newData[i].role] ? this.state.rolesObj[newData[i].role].includes(generalSortOrder[j]) : undefined) {
              matched = true;
            }
          }
          if (!matched) {
            sorted.push(newData[i])
          }
        }

        sorted.forEach((item) => item.isChecked = false)
        sorted.sort((a, b) => (!this.state.rolesObj[a.role] || !this.state.rolesObj[b.role] ? null : this.state.rolesObj[a.role] > this.state.rolesObj[b.role] ? 1 : -1))
        let keys = Object.keys(this.state)
        for (let i = 0; i < keys.length; i++) {
          const element = keys[i];
          if (element >= 0) {
            this.setState({ [element]: false }, () => {
              // this.setState({ [element]: true })
            })
          }
        }
        this.setState({ data: sorted, rolesForSort: rolesForSort, copyData: sorted }, () => {
          // if (localStorage.getItem("searchKey")) {
          //     this.handleSearchChange(localStorage.getItem("searchKey"))
          // }
          // if (localStorage.getItem("openedRows")) {
          //     let openedRows = JSON.parse(localStorage.getItem("openedRows"))
          //     for (let index = 0; index < openedRows.length; index++) {
          //         const element = openedRows[index];
          //         this.setState({ [element]: true })
          //     }
          // }
          this.setState({ isLoading: false })
        })
        this.copyOfData = newData
        // }, 1000);
      }).catch(error => {
        console.log(error)
        this.setState({ isLoading: false })
        this.props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        })
      })
  }

  handleRegionChange = (e, value) => {
    let searchParams = new URLSearchParams(window.location.search)
    if (value.length > 0) {
      let selectedRegionIds = [];
      value.forEach((region) => {
        selectedRegionIds.push(region.id)
      })
      if (searchParams.has("selectedOwnerRegion")) {
        searchParams.set("selectedOwnerRegion", selectedRegionIds.join(","))
      } else {
        searchParams.append("selectedOwnerRegion", selectedRegionIds.join(","))
      }

    } else if (value.length === 0) {
      searchParams.delete("selectedOwnerRegion")

    }

    // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
    // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())

    let hash = window.location.hash
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

    window.history.pushState(null, '', newRelativePathQuery);


    this.setState({ selectedOwnerRegion: value })
  }

  decreaseZoom = () => {
    let zoom = (Number(this.state.zoom) - 0.1).toFixed(1);
    if (zoom < 0.5) return;
    this.setState({ zoom: zoom })
  }

  increaseZoom = () => {
    let zoom = (Number(this.state.zoom) + 0.1).toFixed(1);
    if (zoom > 1.5) return;
    this.setState({ zoom: zoom })
  }


  handleTabChange = (value) => {
    console.log(value)
    this.setState({ tabValue: value })
  }

  changeView = (value) => {
    console.log(value)
    this.setState({ tabValue: value }, () => {
      if (value === 'role') {
        this.getAllRoles()
      } else {
        this.getAllCRM()
      }
    })
  }

  handleAllReports = (selectedStartDate, selectedEndDate) => {
    this.setState({ selectedStartDate, selectedEndDate }, () => {
      this.getAllRoles()
    })
  }

  filterRoles = () => {
    let region = this.state.selectedOwnerRegion.map((region) => region.id)
    let subSector = this.state.selectedSubSector.map((sector) => sector.id)
    let ownerDelivery = this.state.selectedOwnerDelivery.map((od) => od.id)
    let role = this.state.selectedRole.map((role) => role.id)
    let roleGroup = this.state.selectedRoleGroup ?  this.state.selectedRoleGroup.id : null
    let url = `/api/prp/template/get_role_unallocated_schedules/?archive_reason=${0}&from_date=${this.state.selectedStartDate}&to_date=${this.state.selectedEndDate}`
    if (region.length > 0) {
      url += `&region=${region.join(',')}`
    }
    if (subSector.length > 0) {
      url += `&sub_sector=${subSector.join(',')}`
    }
    if (ownerDelivery.length > 0) {
      url += `&owner_delivery=${ownerDelivery.join(',')}`
    }
    if (role.length > 0) {
      url += `&role=${role.join(',')}`
    }
    if (roleGroup) {
      url += `&role_group=${roleGroup}`
    }
    this.setState({
      isRoleLoading: true
    }, () => {
      Service.get(url, {
        headers: {
          Authorization: "Token " + token,
        },
      })
        .then(res => {
          this.makeReport(res.data.crm_data, res.data.all_dates, 'week')
        }
        )
        .catch(error => {
          console.log(error)
          this.setState({ isRoleLoading: false })
          this.props.enqueueSnackbar('Something went wrong!', {
            variant: 'error'
          })
        })
    })
  }




  render() {
    return (
      <div style={{ margin: '10px' }} zoom={this.state.zoom}>
        {this.state.isLoading ? <RotateCircleLoading color="#005D99" /> :
          <div>
            <Grid container spacing={1} style={{ marginBottom: '10px', width: '100%' }} display={'flex'} justifyContent='center' alignItems={'center'}>
              <Grid item xs={2}>
                <Autocomplete
                  multiple
                  size='small'
                  id="sub-sector"
                  limitTags={1}
                  options={this.state.allSubSectors}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => this.handleSubSectorChange(e, value)}
                  value={this.state.selectedSubSector}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Sector" size='small' placeholder="Sector" />
                  )}
                />
              </Grid>

              <Grid item xs={2}>
                <Autocomplete
                  multiple
                  size='small'
                  id="owner-region-sector"
                  limitTags={1}
                  options={this.state.allOwnerRegions}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => this.handleRegionChange('selectedOwnerRegion', value)}
                  value={this.state.selectedOwnerRegion}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size='small' label="Region" placeholder="Region" />
                  )}
                />
              </Grid>

              <Grid item xs={2}>
                <Autocomplete
                  multiple
                  id="owner-delivery-unit"
                  limitTags={1}
                  options={this.state.allOwnerDeliveryUnits}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => this.handleOwnerDeliveryChange(e, value)}
                  value={this.state.selectedOwnerDelivery}
                  size="small"
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size='small' label="Owner Delivery Unit" placeholder="Owner Delivery Unit" />
                  )}
                />
              </Grid>

              <Grid item xs={2}>
                <Autocomplete
                  multiple
                  id="roles"
                  limitTags={1}
                  options={this.state.allRoles}
                  getOptionLabel={(option) => option.job_roles}
                  onChange={(e, value) => this.handleRoleChange(e, value)}
                  disableCloseOnSelect
                  value={this.state.selectedRole}
                  size="small"
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.job_roles}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size='small' label="Role" placeholder="Role" />
                  )}
                />
              </Grid>

              <Grid item xs={2}>
                <Autocomplete
                  id="roleGroup"
                  limitTags={1}
                  options={this.state.roleGroup}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => { this.handleRoleGroupChange(e, value) }}
                  disableCloseOnSelect
                  value={this.state.selectedRoleGroup}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size='small' label="Role Group" placeholder="Role Group" />
                  )}
                />
              </Grid>

              <Grid item xs={2}>
                <Button variant="contained" size='small' color="primary" onClick={() => { if (this.state.tabValue === 'crm') { this.filterCRM() } else { this.filterRoles() } }}> Get Report</Button>
                <Button variant="contained" size='small' style={{ marginLeft: "2px" }} color="primary" onClick={() => { if (this.state.tabValue === 'crm') { this.getAllCRM() } else { this.getAllRoles() } }}>
                  {this.state.tabValue === 'crm' ? 'Show All CRM' : 'Show All Roles'}
                </Button>
              </Grid>
            </Grid>
            {this.state.tabValue === 'crm' ?
              <Box style={{ marginBottom: '10px', width: '100%' }} display='flex' justifyContent='flex-end' flexDirection='flex-end' alignItems='center'>
                <Box>
                  <ToggleButtonGroup
                    exclusive
                    aria-label="text alignment"
                    style={{ marginLeft: "10px", float: 'right' }}
                    size='small'
                    color='primary'
                  >
                    <ToggleButton value="weeks" aria-label="by week" onClick={this.decreaseZoom} >
                      <ZoomOut />
                    </ToggleButton>
                    <ToggleButton value="months" aria-label="by month" onClick={this.increaseZoom}>
                      <ZoomIn />
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <TextField
                    autoFocus
                    autoComplete='off'
                    size='small'
                    placeholder="Search"
                    id="search"
                    variant='outlined'
                    value={localStorage.getItem("searchKey") ? localStorage.getItem("searchKey") : ''}
                    onChange={(e) => {
                      if (this.state.tabValue === 'crm') {
                        this.handleSearchChange(e.target.value)
                      } else {
                        this.roleRef.current.handleSearchChange(e.target.value)
                      }
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                      endAdornment: <InputAdornment position="end">
                        <IconButton onClick={() => { localStorage.removeItem("searchKey"); this.handleSearchChange('') }}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      </InputAdornment>
                    }}
                  />
                </Box>
              </Box> : null}

            {this.state.tabValue === 'crm' ?
              <Paper elevation={3} variant={"outlined"}>
                <div onScroll={(e) => {
                  if (e.target.scrollTop > 50 && !this.state.scrollTop) {
                    this.setState({ scrollTop: true })
                  } else if (e.target.scrollTop < 50 && this.state.scrollTop) {
                    this.setState({ scrollTop: false })
                  }
                }}>
                  <TableContainer style={{ maxHeight: window.innerHeight - (220) }}>
                    <div ref={this.topRef}>
                      <Table stickyHeader size='small' style={{ zoom: this.state.zoom }} >
                        <TableHead>
                          <TableRow >
                            <TableCell style={{ minWidth: '400px', zIndex: 999, position: "sticky", left: 0, background: "#F8F8F8", }}>
                              <b>CRM</b>
                            </TableCell>
                            {this.state.columns.map((columnLabel, inde) => (
                              <TableCell
                                key={inde}
                                style={{
                                  ...columnLabel.headerStyle,
                                }}
                              >
                                <b>{columnLabel.title}</b>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.data.map((row, index) => (
                            <>
                              <TableRow onClick={() => this.setState({ selectedCRM: row, unallocatedRolesDialog: true })}>
                                <TableCell style={{ minWidth: '400px', cursor: 'pointer', zIndex: 99, position: "sticky", left: 0, background: "#F8F8F8", }}>
                                  {row.name}
                                </TableCell>
                                {this.state.columns.map((columnLabel, inde) => (
                                  <TableCell
                                    key={inde}
                                    style={{
                                      ...columnLabel.cellStyle,
                                    }}
                                  >
                                    {row.dates[columnLabel.field]}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </TableContainer>
                </div>
                {this.state.scrollTop ? <IconButton size='large' onClick={() => { this.scrolltoTop() }} variant='contained' style={{ zIndex: 999, position: 'absolute', right: 40, bottom: 40 }}><ArrowUpwardIcon style={{ fontSize: '40px' }} /></IconButton> : null}

              </Paper> :
              <div>
                {this.state.isRoleLoading ? <Box display='flex' justifyContent={'center'} alignItems={'center'} width={'100%'}><LoopCircleLoading /></Box> :
                  <StaffLocationView
                    splittedCRM={this.state.splittedCRM} allMonths={this.state.allMonths} finalColumns={this.state.finalColumns} ref={this.roleRef}
                    getData={this.handleAllReports}
                    selectedRoleGroup={this.state.selectedRoleGroup}
                  />}
              </div>}

            <Dialog
              fullScreen
              open={this.state.unallocatedRolesDialog}
              TransitionComponent={Transition}
              disableEnforceFocus >
              <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={() => this.setState({ unallocatedRolesDialog: false })} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6">
                    Unallocated Roles
                  </Typography>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <Box marginTop='65px'>
                  <UnallocatedRoles project={this.state.selectedCRM} selectedRoleGroup={this.state.selectedRoleGroup} id={this.state.selectedCRM.id} />
                </Box>
              </DialogContent>
            </Dialog>

          </div>
        }
      </div>
    )
  }

}

export default withSnackbar(withStyles(useStyle)(UnallocatedCRM))
