import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import MaterialTable from 'material-table'
import moment from 'moment'
import React, { Component } from 'react'

class OverwriteSchedules extends Component {
    constructor(props) {
        super()
        this.state = {
            columns: [],
            existingAllocationData: [],
            title: ''
        }
    }



    componentDidMount() {
        let columns = []
        let data = []
        // let title = this.props.firstname + ' ' + this.props.lastname      
        data.push(this.props.errorData)
        this.props.errorData.all_mondays_date.sort((a, b) => moment(a).valueOf() - moment(b).valueOf()).forEach(element => {
            if (element) {
                columns.push({ title: moment(element).format('DD MMM YYYY'), field: element, render: rowData => <Box style={{ color: rowData.notify_available[element]?.is_conflict ? 'red' : '' }}>{rowData.notify_available[element]?.required_qty + "/" + rowData.notify_available[element]?.standby}</Box> })
            }
        })
        console.log(data)
        this.setState({
            columns: columns,
            existingAllocationData: data
        })
    }

    render() {
        return (
            <div>
                <Alert style={{ width: "100%" }} severity="warning">Some of the weeks are exceeding the standby qty. Please check the below data</Alert>
                <MaterialTable
                    title={"(Required qty/Existing standby)"}
                    columns={this.state.columns}
                    data={this.state.existingAllocationData}
                    options={{
                        search: false,
                        paging: false,
                        sorting: true,
                        padding: 'dense',
                    }}
                /></div>
        )
    }
}

export default OverwriteSchedules
