import React from 'react'
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import Service from '../../../config/networkutils';
import { prp_template_api, roles_api, users_api } from '../../../config/apiList';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import ROAPGraph from './ROAPGraph';
import { getCookie } from '../../../utils/CookieHelper';
import { Button, Grid } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { RotateCircleLoading } from 'react-loadingg';

const token = getCookie("ptd_ts_token");

const useStyle = (theme) => ({
    customGridStyle: {
        margin: 0,
        paddingRight: '8px',
        paddingLeft: '16px',
        marginBottom: '16px'
    }
})

class ROAPReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [],
            data: [],
            roles: {},
            responseData: [],
            filter: "months",
            allEmp: [],
            zoom: 0.9,
            selectedStartDate: null,
            selectedEndDate: null,
            sortedDtaes: [],
            isLoading: true
        }
        // Date.prototype.addDays = function (days) {
        //     var date = new Date(this.valueOf());
        //     date.setDate(date.getDate() + days);
        //     return date;
        // }
    }

    componentDidMount() {
        this.setState({
            selectedStartDate: moment().format(),
            selectedEndDate: moment().add(6, 'M').format()
        })
        this.getRoles();
        // this.getEmployees()
    }


    getRoles = () => {
        // this.setState({ isLoading: true })
        // this.props.isLoading(true)
        Service.get(roles_api + '?is_active=true', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let data = []
                data = res.data;
                let obj = {}
                data.forEach(ele => {
                    let newData = { [ele.id]: ele.job_roles }
                    obj = { ...obj, ...newData }
                })
                this.setState({ roles: obj })
                this.fetchOps()
            })
            .catch(error => {

                // this.setState({ isLoading: false })
                this.props.isLoading(false)
            })
    }

    getEmployees = () => {
        // this.props.isLoading(true)
        Service.get(users_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ allEmp: res.data })
            })
            .catch(error => {

                this.props.isLoading(false)
            })
    }

    fetchOps() {
        let pr = [];
        let crmId = [];
        pr = this.props.projects
        pr.forEach(e => {
            if (e.id) crmId.push(e.id)
        })
        Service.patch(prp_template_api + "get_data/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: crmId
        })
            .then(res => {

                let data = res.data;
                this.setState({ responseData: data })
                this.calculateByMonths()
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                // this.setState({ isLoading: false })
                this.props.isLoading(false)
            })
    }

    calculateByMonths() {
        try {
            let all_months = {}
            let roleData = []
            this.state.responseData.forEach((role, idx) => {

                let dates = {}
                role.emp.forEach((emp, i) => {
                    let employeeDate = emp.dates;

                    for (const key in employeeDate) {
                        if (key && moment(key, "DD/MM/YYYY").isValid() && !moment(key, "DD/MM/YYYYY").isBefore(this.state.selectedStartDate) && !moment(key, "DD/MM/YYYYY").isAfter(this.state.selectedEndDate)) {
                            let value = 0;
                            value = employeeDate[key];
                            if (Object.keys(dates).length) {
                                if (Object.keys(dates).includes(key)) {
                                    value = Number(value) + Number(dates[key])
                                }
                            }
                            dates = { ...dates, [key]: value }
                        }
                    }
                    // ============================================================
                })
                let months = {}
                if (dates) {
                    for (const date in dates) {
                        let month_year = moment(date, "DD/MM/YYYY").format("MMM YYYY")
                        let qty = Number(dates[date])
                        if (month_year in months) {
                            qty += months[month_year]
                        }
                        months = { ...months, [month_year]: qty }
                    }
                }

                let roleDates = { ...months, ...{ 'role': role.role, 'gate_level_acheived': role.crm_gate_level_acheived } }
                all_months = { ...all_months, ...months }
                roleData.push(roleDates)
            })

            let combinedRoleData = []
            roleData.forEach(role => {
                let idx = combinedRoleData.findIndex(ele => (role.role === ele.role) && (role.gate_level_acheived === combinedRoleData.gate_level_acheived))
                let matched = combinedRoleData[idx]
                if (matched) {

                    for (const data in role) {
                        let qty = Number(role[data])

                        if (data in matched) {
                            if (moment(data).isValid()) {
                                qty = Number(qty) + Number(matched[data])
                            }
                        }
                        matched = { ...matched, [data]: qty }
                    }
                    // combinedRoleData[idx] = matched
                }
                else {
                    combinedRoleData.push(role)
                }
            })


            let set = []
            for (const item in all_months) {
                set.push(item)
            }
            let sorted = set.sort(function (a, b) {
                return moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD')
            });
            this.sortedDates = sorted;
            this.setState({ sortedDtaes: sorted })
            let columns = [{
                title: 'Roles', field: 'role', width: 150
            }]

            sorted.forEach(date => {
                let newObj = {
                    title: date,
                    field: date,
                    type: 'date',
                    width: 100,
                    sorting: false,
                    editable: 'never',
                    render: rowData => rowData[date]
                }
                columns.push(newObj)
            })

            let civils = [];
            let engineer = [];
            let linesmen = [];
            let pm = [];
            let site_manager = [];
            let supervisor = [];
            let other = [];
            for (let i = 0; i < combinedRoleData.length; i++) {
                let role = String(this.state.roles[combinedRoleData[i].role]).toLocaleLowerCase()
                if (combinedRoleData[i].gate_level_acheived === 5) {
                    if (role.includes('civil') || role.includes('civils')) {
                        civils.push(combinedRoleData[i])
                    }
                    else if (role.toLocaleLowerCase().includes('project manager')) {
                        pm.push(combinedRoleData[i])
                    }
                    else if (role.toLocaleLowerCase().includes('engineer')) {
                        engineer.push(combinedRoleData[i])
                    }
                    else if (role.toLocaleLowerCase().includes('linesmen')) {
                        linesmen.push(combinedRoleData[i])
                    }
                    else if (role.toLocaleLowerCase().includes('site manager')) {
                        site_manager.push(combinedRoleData[i])
                    }
                    else if (role.toLocaleLowerCase().includes('supervisor')) {
                        supervisor.push(combinedRoleData[i])
                    }
                    else {
                        other.push(combinedRoleData[i]);
                    }
                }
            }

            let civilsObj = { role: 'Civils' };
            for (let i = 0; i < civils.length; i++) {
                for (const key in civils[i]) {
                    if (moment(key).isValid()) {
                        let qty = Number(civils[i][key]);
                        if (!(key in civilsObj)) {
                            civilsObj = { ...civilsObj, [key]: qty.toFixed(1) }
                        }
                        else if (key in civilsObj) {
                            qty = qty + Number(civilsObj[key])
                            civilsObj = { ...civilsObj, [key]: qty.toFixed(1) }
                        }
                    }
                }
            }

            let engineerObj = { role: 'Engineer' };
            for (let i = 0; i < engineer.length; i++) {
                for (const key in engineer[i]) {
                    if (moment(key).isValid()) {
                        let qty = Number(engineer[i][key]);
                        if (!(key in engineerObj)) {
                            engineerObj = { ...engineerObj, [key]: qty.toFixed(1) }
                        }
                        else if ((key in engineerObj)) {
                            qty = qty + Number(engineerObj[key])
                            engineerObj = { ...engineerObj, [key]: qty.toFixed(1) }
                        }
                    }
                }
            }

            let linesmenObj = { role: 'Linesmen' };
            for (let i = 0; i < linesmen.length; i++) {
                for (const key in linesmen[i]) {
                    if (moment(key).isValid()) {
                        let qty = Number(linesmen[i][key]);
                        if (!(key in linesmenObj)) {
                            linesmenObj = { ...linesmenObj, [key]: qty.toFixed(1) }
                        }
                        else if ((key in linesmenObj)) {
                            qty = qty + Number(linesmenObj[key])
                            linesmenObj = { ...linesmenObj, [key]: qty.toFixed(1) }
                        }
                    }
                }
            }

            let pmObj = { role: 'Project Manager' };
            for (let i = 0; i < pm.length; i++) {
                for (const key in pm[i]) {
                    if (moment(key).isValid()) {
                        let qty = Number(pm[i][key]);
                        if (!(key in pmObj)) {
                            pmObj = { ...pmObj, [key]: qty.toFixed(1) }
                        }
                        else if ((key in pmObj)) {
                            qty = qty + Number(pmObj[key])
                            pmObj = { ...pmObj, [key]: qty.toFixed(1) }
                        }
                    }
                }
            }


            let siteManagerObj = { role: 'Site Manager' };
            for (let i = 0; i < site_manager.length; i++) {
                for (const key in site_manager[i]) {
                    if (moment(key).isValid()) {
                        let qty = Number(site_manager[i][key]);
                        if (!(key in siteManagerObj)) {
                            siteManagerObj = { ...siteManagerObj, [key]: qty.toFixed(1) }
                        }
                        else if ((key in siteManagerObj)) {
                            qty = qty + Number(siteManagerObj[key])
                            siteManagerObj = { ...siteManagerObj, [key]: qty.toFixed(1) }
                        }
                    }
                }
            }

            let supervisorObj = { role: 'Supervisor' };
            for (let i = 0; i < supervisor.length; i++) {
                for (const key in supervisor[i]) {
                    if (moment(key).isValid()) {
                        let qty = Number(supervisor[i][key]);
                        if (!(key in supervisorObj)) {
                            supervisorObj = { ...supervisorObj, [key]: qty.toFixed(1) }
                        }
                        else if ((key in supervisorObj)) {
                            qty = qty + Number(supervisorObj[key])
                            supervisorObj = { ...supervisorObj, [key]: qty.toFixed(1) }
                        }
                    }
                }
            }

            let othersObj = { role: 'Others' };
            for (let i = 0; i < other.length; i++) {
                for (const key in other[i]) {
                    if (moment(key).isValid()) {
                        let qty = Number(other[i][key]);
                        if (!(key in othersObj)) {
                            othersObj = { ...othersObj, [key]: qty.toFixed(1) }
                        }
                        else if ((key in othersObj)) {
                            qty = qty + Number(othersObj[key])
                            othersObj = { ...othersObj, [key]: qty.toFixed(1) }
                        }
                    }
                }
            }


            let roapData = [];

            roapData.push(civilsObj)
            roapData.push(engineerObj)
            roapData.push(linesmenObj)
            roapData.push(pmObj)
            roapData.push(siteManagerObj)
            roapData.push(supervisorObj)
            roapData.push(othersObj)

            let forecastObj = { role: 'Forecast' };
            for (let i = 0; i < combinedRoleData.length; i++) {
                for (const key in combinedRoleData[i]) {
                    if (moment(key).isValid()) {
                        let qty = Number(combinedRoleData[i][key]);
                        if (key in forecastObj) {
                            qty = qty + Number(forecastObj[key])
                            forecastObj = { ...forecastObj, [key]: qty.toFixed(1) }
                        }
                        else {
                            forecastObj = { ...forecastObj, [key]: qty.toFixed(1) }
                        }
                    }
                }
            }
            roapData.push(forecastObj)
            this.setState({ columns: columns, data: roapData }, () => {
                this.props.enqueueSnackbar('Report generated', {
                    variant: 'success'
                });
                // this.props.isLoading(false)
                this.setState({ isLoading: false })
            })
            return { columns: columns, data: roapData, demandMonths: sorted }
        }
        catch (e) {
            console.error(e)
        }
    }

    handleDateChange1 = (d) => {
        this.setState({ selectedStartDate: d })
    }
    handleDateChange2 = (d) => {
        this.setState({ selectedEndDate: d })
    }


    render() {
        return (

            <>
                {this.state.isLoading ? <RotateCircleLoading color="#005D99" /> :
                    <div>
                        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                            <Grid item xs={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        variant="inline"
                                        openTo="year"
                                        views={["month", "year"]}
                                        label="Start Month"
                                        // helperText="Start from year selection"
                                        // minDate={moment()}
                                        maxDate={this.state.selectedEndDate ? this.state.selectedEndDate : undefined}
                                        autoOk={true}
                                        value={this.state.selectedStartDate}
                                        onChange={this.handleDateChange1}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        variant="inline"
                                        openTo="year"
                                        views={["month", "year"]}
                                        label="End Month"
                                        minDate={this.state.selectedStartDate ? this.state.selectedStartDate : undefined}
                                        // maxDate={null}
                                        // helperText="Start from year selection"
                                        autoOk={true}
                                        value={this.state.selectedEndDate}
                                        onChange={this.handleDateChange2}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={1}>
                                <Button variant="outlined" color="primary" onClick={() => {
                                    this.setState({ isLoading: true })
                                    this.calculateByMonths()
                                }
                                }> Filter</Button>
                            </Grid>
                        </Grid>
                        <div style={{ zoom: this.state.zoom }}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.data}
                                title="ROAP Report"
                                options={{
                                    search: true,
                                    // showTitle: false,
                                    // doubleHorizontalScroll: true,
                                    // maxBodyHeight: window.screen.height - 250,
                                    paging: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    },
                                    fixedColumns: {
                                        left: 1,
                                        right: 0
                                    },
                                    exportButton: { csv: true, pdf: false }
                                }}
                            />
                        </div>

                        {this.state.isLoading === false ? <ROAPGraph
                            columns={this.state.sortedDtaes}
                            data={this.state.data}
                        /> : null}
                    </div>
                }
            </>
        )
    }

}

export default withSnackbar(withStyles(useStyle)(ROAPReport))