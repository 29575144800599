import React, { Component } from 'react'
import { getCookie } from '../../utils/CookieHelper';
import Service from '../../config/networkutils'
import { Box, CircularProgress } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { withSnackbar } from 'notistack';
import moment from 'moment';

const token = getCookie("ptd_ts_token");
class OverallDvsCGraph extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      series: [],
      options: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Overall Demand vs Capacity',
          align: 'center'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            formatter: function (val = new Date()) {
              let dateArray = String(val).split("-")
              return moment(val).format('YYYY') + " Q" + Math.floor(Number(dateArray[1]) / 3 + 1)
            }
          },
          title: {
            text: 'Year'
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    Service.get(`/api/subcontractor/other_report_overall_DvsC/?category=${this.props.gang.id}`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        let capacityObj = {}

        for (let index = 0; index < res.data.quater.length; index++) {
          const element = res.data.quater[index];
          let year = element.quarter_start.split('-')
          if (!(year[0] in capacityObj)) {
            capacityObj[year[0]] = 1
          } else {
            capacityObj[year[0]] += 1
          }
        }

        var groupObj = Object.entries(capacityObj).map(x => ({
          'title': x[0],
          'cols': x[1]
        }))

        let demand = Object.entries(res.data.quater).map(x => ({
          'x': x[1].quarter_start,
          'y': x[1].demand
        }))
        let capacity = Object.entries(res.data.quater).map(x => ({
          'x': x[1].quarter_start,
          'y': x[1].capacity
        }))

        let series = [
          { name: 'Demand', type: 'line', data: demand },
          { name: 'Capacity', type: 'line', data: capacity },
        ]
        this.setState({
          series: series,
          groupObj: groupObj,
          options: {
            ...this.state.options,
            xaxis: {
              ...this.state.options.xaxis,
              group: {
                ...this.state.options.xaxis.group,
                groups: groupObj
              }
            }
          }
        }, () => {
          this.setState({
            isLoading: false
          })
        })
      })
      .catch(error => {
        console.log(error);
        this.props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });
      })
  }

  render() {
    return (
      <div>{this.state.isLoading ?
        <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress /></Box> :
        <ReactApexChart options={this.state.options} series={this.state.series} width={'100%'} type="line" height={window.innerHeight / 2 - 50} />}
      </div>
    )
  }
}

export default withSnackbar(OverallDvsCGraph);
