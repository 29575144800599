import React from 'react'
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import Service from '../../config/networkutils';
import { IconButton } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUpload'
import XLSX from "xlsx";
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../shared/Modal/Modal';
import Dropzone from '../../shared/Dropzone/Dropzone';
import { getCookie } from '../../utils/CookieHelper';

const token = getCookie("ptd_ts_token");

const useStyles = (theme) => ({

});

class AccountTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                // { title: 'Id', field: 'id' },
                {
                    title: 'Name', field: 'name',
                },
            ],
            data: [],
            isLoading: false,
            dropZoneModal: false,
            reviewModal: false,
            dataToUpload: [],
            bulkUploadLoader: false
        }
    }

    componentDidMount() {
        if (this.props.api) { this.fetchOps() }
        // else { this.setState({ isLoading: false }) }
    }

    fetchOps() {
        this.setState({ isLoading: true })
        Service.get(this.props.api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let resData = res.data;
                this.setState({ data: resData })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Unable to fetch ' + this.props.tableName + " data", {
                    variant: 'error'
                });
            })
            .finally(() => { this.setState({ isLoading: false }) });
    }

    postOps(data) {
        if (!data.name) {
            this.setState({ isLoading: true, })
            setTimeout(() => {
                this.setState({ isLoading: false, })
                // this.fetchOps();
                this.props.enqueueSnackbar('Name field is empty!', {
                    variant: 'error'
                });
            }, 200)

        } else {
            let api=''
            if(this.props.api.includes('get_with_delete_status/')){
                api = this.props.api.replace('get_with_delete_status/', '')
            }else{
                api = this.props.api
            }
            Service.post(api, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data,
            })
                .then(res => {
                    // this.setState({ isLoading: true, })
                    this.fetchOps();
                    this.props.enqueueSnackbar('Field added successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: false })
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
            // .finally(() => { this.setState({ isLoading: false }) });;
        }
    }

    putOps(data) {
        if (!data.name) {
            this.setState({ isLoading: true, })
            setTimeout(() => {
                this.setState({ isLoading: false, })
                // this.fetchOps();
                this.props.enqueueSnackbar('Name field is empty!', {
                    variant: 'error'
                });
            }, 200)

        } else {
            let api=''
            if(this.props.api.includes('get_with_delete_status/')){
                api = this.props.api.replace('get_with_delete_status/', '')
            }else{
                api = this.props.api
            }
            Service.put(api + data.id +'/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    name: data.name,
                },
            })
                .then(res => {
                    this.fetchOps()
                    this.props.enqueueSnackbar('Field updated successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: false })
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    this.fetchOps();
                });
        }
    }

    deleteOps(data) {
        let api=''
        if(this.props.api.includes('get_with_delete_status/')){
            api = this.props.api.replace('get_with_delete_status/', '')
        }else{
            api = this.props.api
        }
        Service.delete(api + data.id+"/", {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                this.fetchOps()
                this.props.enqueueSnackbar('Deleted successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.fetchOps();
            });
    }

    bulkPost(data) {
        Service.post(this.props.api, {
            headers: {
                Authorization: "Token " + token,
            },
            data
        })
            .then(res => {

                this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? 'Row' : ' Rows') + ' added successfully', {
                    variant: 'success'
                })
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })

    }

    bulkUpdate(data) {
        Service.patch(this.props.bulk_update_api, {
            headers: {
                Authorization: "Token " + token,
            },
            data
        })
            .then(res => {

                this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? 'Row' : ' Rows') + ' updated successfully', {
                    variant: 'success'
                });

            })
            .catch(e => {

                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })

    }

    reviewModalOpen = () => { this.setState({ reviewModal: true }) }

    reviewModalClose = () => { this.setState({ reviewModal: false }) }

    fileToRead(files) {
        if (!files || !files[0]) return;
        let f = files[0];
        try {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;

            reader.onload = (e) => {
                /* read workbook */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });

                /* grab first sheet */

                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                /* save data */
                const data = XLSX.utils.sheet_to_json(ws)


                this.setState({ dataToUpload: data })
            };
            if (rABS) {
                reader.readAsBinaryString(f);
            } else {
                reader.readAsArrayBuffer(f);
            }
        }
        catch (e) {
            console.error(e)
        }
    }



    bulkUpload = () => {

        this.setState({ bulkUploadLoader: true })

        let dataToUpload = [...this.state.dataToUpload]
        let dataToUpdate = []
        dataToUpload.forEach((ele, i) => {
            this.state.data.forEach(ele2 => {
                if (ele.name.toLowerCase() === ele2.name.toLowerCase()) {
                    ele.id = ele2.id;
                    dataToUpdate.push(ele)
                }
            })
        })
        dataToUpdate.forEach((ele) => {
            dataToUpload.forEach((ele2, i) => {
                if (ele.name.toLowerCase() === ele2.name.toLowerCase()) {
                    dataToUpload.splice(i, 1)
                }
            })
        })


        if (dataToUpload.length) {

            let arr = [];
            for (let i = 1; i <= dataToUpload.length; i++) {
                arr.push(dataToUpload[i - 1])
                if (i === 1000 * Number((i / 1000).toFixed())) {
                    this.bulkPost(arr)
                    arr = []
                }
                if (i === dataToUpload.length) {
                    this.bulkPost(arr)
                }
            }


        }
        if (dataToUpdate.length) {

            let arr = [];
            for (let i = 1; i <= dataToUpdate.length; i++) {
                arr.push(dataToUpdate[i - 1])
                if (i === 1000 * Number((i / 1000).toFixed())) {
                    this.bulkUpdate(arr)
                    arr = []
                }
                if (i === dataToUpdate.length && arr.length) {
                    this.bulkUpdate(arr)
                }
            }
        }

        setTimeout(() => {
            this.setState({
                reviewModal: false,
                dataToUpload: [],
                dropZoneModal: false,
                bulkUploadLoader: false
            })
            this.fetchOps()
        }, 4000);

    }

    dropzoneClose = () => {
        this.setState({ dropZoneModal: false })
    }


    render() {
        const access = this.props.access.access
        return (<>
            <MaterialTable
                title={
                    <div>
                        <h2 style={{ float: 'left' }}>{this.props.tableName}</h2>
                        {this.props.upload === true ? <IconButton style={{ marginTop: '5px' }} onClick={() => this.setState({ dropZoneModal: true })}
                        ><UploadIcon /></IconButton> : null}
                    </div>
                }
                columns={this.state.columns}
                data={this.state.data}
                isLoading={this.state.isLoading}
                editable={{
                    isDeletable:  rowData => rowData.is_deletable ? true : false, 
                    onRowAdd: access.l2a && access.l2a.l2a_b ? newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                this.postOps(newData)
                                resolve();
                            }, 1000)
                        }) : undefined,
                    onRowUpdate: access.l2a && access.l2a.l2a_c ? (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                this.putOps(newData)
                                resolve();
                            }, 1000)
                        }) : undefined,
                    onRowDelete:  (access.l2a && access.l2a.l2a_b && this.props.deletable) || (access.l2a && access.l2a.l2a_c && this.props.deletable) ? oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                this.deleteOps(oldData)
                                resolve()
                            }, 1000)
                        }): undefined,
                }}
                
                options={{
                    paging: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    headerStyle: {
                        fontWeight: 'bold',
                        // color: '#005D99'
                    },
                    // exportButton: true,
                    addRowPosition: 'first',
                    padding: 'dense'
                }}
            />

            <Dropzone
                open={this.state.dropZoneModal}
                onClose={this.dropzoneClose}
                onChange={(e) => this.fileToRead(e)}
                preview={this.reviewModalOpen}
            />

            <Modal
                open={this.state.reviewModal}
                close={this.reviewModalClose}
                onUpload={this.bulkUpload}
                columns={this.state.columns}
                data={this.state.dataToUpload}
                loading={this.state.bulkUploadLoader}
                title={this.props.tableName}
            />
        </>)
    }
}

export default (withSnackbar(withStyles(useStyles)(AccountTable)))