import { Box, Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { CoffeeLoading } from 'react-loadingg';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment/moment';
import Service from '../../../config/networkutils';
import { getCookie } from '../../../utils/CookieHelper';
import { getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("ptd_ts_token")

class StandBy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            coffeeLoading: false,
            notiDialog: false,
        };
    }

    componentDidMount() {
        this.setState({ notiDialog: false });
    }

    standByReport = () => {

        if (!this.state.startDate || !this.state.endDate) {
            this.props.enqueueSnackbar('Please select the Dates!', {
                variant: 'warning'
            });
            return;
        }

        this.setState({ coffeeLoading: true });

        Service.get(`/api/ptd/employees/emp_standby/?start=${this.state.startDate}&end=${this.state.endDate}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ coffeeLoading: false });
                this.props.enqueueSnackbar('StandBy Report generated successfully!', {
                    variant: 'success'
                });
                this.setState({ notiDialog: true });
            })
            .catch(error => {
                console.log(error);
                this.setState({ coffeeLoading: false });
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    leaveReport = () => {

        if (!this.state.startDate || !this.state.endDate) {
            this.props.enqueueSnackbar('Please select the Dates!', {
                variant: 'warning'
            });
            return;
        }

        this.setState({ coffeeLoading: true });
        Service.get(`/api/ptd/employees/emp_leave_report/?start=${this.state.startDate}&end=${this.state.endDate}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ coffeeLoading: false });
                this.props.enqueueSnackbar('Leave Report generated successfully!', {
                    variant: 'success'
                });
                this.setState({ notiDialog: true });
            })
            .catch(error => {
                console.log(error);
                this.setState({ coffeeLoading: false });
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    render() {

        const handleStartDateChange = (date) => {
            this.setState({ startDate: moment(date).format("YYYY-MM-DD") });
        };
        const handleEndDateChange = (date) => {
            this.setState({ endDate: moment(date).format("YYYY-MM-DD") });
        };

        return (
            <div>
                {this.state.notiDialog ?
                    <Typography variant="subtitle1" >
                        Report will be sent to your email shortly!
                    </Typography> :
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Start Date"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    style={{ minWidth: '200px' }}
                                    value={this.state.startDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog2"
                                    label="End Date"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    minDate={new Date(this.state.startDate)}
                                    minDateMessage={"Start Date should be lesser than End Date"}
                                    style={{ marginLeft: '10px', minWidth: '200px' }}
                                    value={this.state.endDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} mt={'10px'}>
                            <Button color='primary' size='small' variant='contained' style={{ marginRight: "10px" }} onClick={() => this.standByReport()}>
                                StandBy
                            </Button>
                            <Button color='primary' size='small' variant='contained' style={{ marginRight: "10px" }} onClick={() => this.leaveReport()}>
                                other
                            </Button>
                        </Box>
                    </Box>
                }

                <Dialog open={this.state.coffeeLoading} maxWidth={"md"}>
                    <DialogContent style={{ padding: "70px" }}>
                        <CoffeeLoading color={getPrimaryColor()} />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withSnackbar(StandBy);