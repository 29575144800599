import findIndex from 'lodash/findIndex'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { weekStartDates, weekStartDatesObject } from './betweenDates'
import { months } from '../config/abstractedVariables'

export function sortNamebyorder(array) {
    return array.sort((a, b) => a.first_name.localeCompare(b.first_name))
}

export function updateFilters(name, fullArray) {
    let searchParams = new URLSearchParams(window.location.search)
    let returnArray = []
    if (searchParams.get(name)) {
        let ids = searchParams.get(name).split(',');
        for (let id = 0; id < ids.length; id++) {
            let filteredArray = fullArray.filter((obj) => obj.id === parseInt(ids[id]));
            returnArray.push(filteredArray[0]);
        }
    }
    return returnArray
}

export function updateURLParams(name, valueArray) {
    let searchParams = new URLSearchParams(window.location.search)
    if (valueArray.length > 0) {
        let valueArrayIds = [];
        valueArray.forEach((object) => {
            valueArrayIds.push(object.id)
        })
        if (searchParams.has(name)) {
            searchParams.set(name, valueArrayIds.join(","))
        } else {
            searchParams.append(name, valueArrayIds.join(","))
        }

    } else if (valueArray.length === 0) {
        searchParams.delete(name)

    }
    let hash = window.location.hash
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

    window.history.pushState(null, '', newRelativePathQuery);
}

export function filterOneObjArrayByAnoterObjArray(fullArray, filterInputArray, fullArrayObjField, filterInputArrayObjField) {
    let filteredArray = []
    filteredArray = fullArray.filter((item) => {
        let equal = false
        for (let filterIndex = 0; filterIndex < filterInputArray.length; filterIndex++) {
            const element = filterInputArray[filterIndex];

            if (item[fullArrayObjField] === element[filterInputArrayObjField]) {

                equal = true
                break
            }
        }
        return equal

    })

    return filteredArray
}

export function getfilteredByAnotherArrayContains(fullArray, filterInputArray) {
    let filteredArray = filterInputArray.filter((item) => {

        return fullArray.includes(item)
    })
    return filteredArray
}

export function getRolesLookupObj(array) {
    let obj = {}
    array.forEach(ele => {
        obj = { ...obj, ...{ [ele.id]: ele.job_roles } }
    })
    return obj
}

export function getLookupObj(array) {
    let obj = {}
    array.forEach(ele => {
        obj = { ...obj, ...{ [ele.id]: ele.name } }
    })
    return obj
}

export function getfilteredByAnotherArrayNotContains(fullArray, filterInputArray) {
    let filteredArray = filterInputArray.filter((item) => {

        return !fullArray.includes(item)
    })
    return filteredArray
}

export function removeAnElementFromTheArray(arr, value) {
    arr.splice(findIndex(arr, item => { let isEq = isEqual(item, value); return isEq }), 1)
    return arr
}

export function removeAnElementFromTheArrayAndAdd(arr, value, addObject) {
    arr.splice(findIndex(arr, item => { let isEq = isEqual(item, value); return isEq }), 1, addObject)
    return arr
}

export function findLowestFromDate(project) {
    let fromDate = [];
    for (let scdl = 0; scdl < project.shdl_data?.length; scdl++) {
        if (fromDate.length >= 1) {
            if (
                moment(project.shdl_data[scdl].from.slice(0, 10)).isSameOrBefore(
                    moment(fromDate[0].toISOString().slice(0, 10))
                )
            ) {
                // if the fromDate[0] date is higher than the iteration date remove the fromDate[0] and add the iterated date.
                fromDate.pop();
                fromDate.push(moment(project.shdl_data[scdl].from.slice(0, 10)));
            } else if (
                moment(project.shdl_data[scdl].from.slice(0, 10)).isSameOrAfter(
                    moment(fromDate[0].toISOString().slice(0, 10))
                )
            ) {
                continue;
            }
        } else {
            fromDate.push(moment(project.shdl_data[scdl].from.slice(0, 10)));
        }
    }

    return fromDate;
};

export function findHighestToDate(project) {
    let toDate = [];
    for (let scdl = 0; scdl < project.shdl_data?.length; scdl++) {
        if (toDate.length >= 1) {
            //   moment(toDate[0].toISOString().slice(0, 10))
            // ))
            if (
                moment(project.shdl_data[scdl].to.slice(0, 10)).isSameOrAfter(
                    moment(toDate[0].toISOString().slice(0, 10))
                )
            ) {
                // if the toDate[0] date is higher than the iteration date remove the toDate[0] and add the iterated date.
                toDate.pop();
                toDate.push(moment(project.shdl_data[scdl].to.slice(0, 10)));
            } else if (
                moment(project.shdl_data[scdl].to.slice(0, 10)).isSameOrBefore(
                    moment(toDate[0].toISOString().slice(0, 10))
                )
            ) {
                continue;
            }
        } else {
            toDate.push(moment(project.shdl_data[scdl].to.slice(0, 10)));
        }
    }

    return toDate;
};

export function applyQuantityToEveryWeek(rawEmployeeScheduless) {
    // let rawEmployeeScheduless = [...this.state.rawEmployeeSchedules];
    for (
        // Iterating all the projects
        let project = 0;
        project < rawEmployeeScheduless.length;
        project++
    ) {
        let stDat = findLowestFromDate(rawEmployeeScheduless[project]);
        let enDat = findHighestToDate(rawEmployeeScheduless[project]);

        let allMondayDates = weekStartDatesObject(
            stDat.toString(),
            enDat.toString()
        );

        for (
            let shdl = 0;
            shdl < rawEmployeeScheduless[project].shdl_data?.length;
            shdl++
        ) {
            // Iterating all the shdls

            for (let monday in allMondayDates) {
                // Iterating all the monday dates for one shdl

                if (
                    moment(monday, "DD/MM/YYYY").isSameOrAfter(
                        moment(
                            rawEmployeeScheduless[project].shdl_data[shdl].from.slice(0, 10)
                        )
                    ) &&
                    moment(monday, "DD/MM/YYYY").isSameOrBefore(
                        moment(
                            rawEmployeeScheduless[project].shdl_data[shdl].to.slice(0, 10)
                        )
                    )
                ) {
                    allMondayDates[monday] =
                        Number(allMondayDates[monday]) +
                        Number(rawEmployeeScheduless[project].shdl_data[shdl].qty);
                }
            }
        }
        rawEmployeeScheduless[project].dates = allMondayDates;
    }
    return rawEmployeeScheduless
};

export function getUniqueItemsArray(duplicateArray, field) {
    let uniqueArray = [];
    duplicateArray.forEach(element => {
        let found = false;
        for (let uniqueIndex = 0; uniqueIndex < uniqueArray.length; uniqueIndex++) {
            if (uniqueArray[uniqueIndex][field] === element[field]) {
                found = true;
                break;
            }
        }
        if (!found) {
            uniqueArray.push(element);
        }

    });
    return uniqueArray;
}

export function groupUniqueObjectsFromArray(duplicateArray) {
    let groupedObject = { empIDS: [] };
    duplicateArray.forEach((element, elementINd) => {
        if (groupedObject.hasOwnProperty(element.emp_id)) {
            groupedObject[element.emp_id] = [...groupedObject[element.emp_id], element]

            if (!groupedObject['empIDS'].includes(element.emp_id)) {
                groupedObject['empIDS'].push(element.emp_id)
            }
        } else {
            groupedObject = { ...groupedObject, [element.emp_id]: [element] }
            if (!groupedObject['empIDS'].includes(element.emp_id)) {
                groupedObject['empIDS'].push(element.emp_id)
            }
        }
    });
    return groupedObject;
}

export const sortByGivenOrder = (sortOrderArray, sortNeedArray, lookup, field) => {
    // eslint-disable-next-line array-callback-return
    sortNeedArray.sort(function (a, b) {
        if (a.first_name && b.first_name) {
            return a.first_name.localeCompare(b.first_name);
        }
        return null
    })
    let sorted = [];
    let unSorted = [];
    let ids = []
    for (let i = 0; i < sortOrderArray.length; i++) {
        for (let j = 0; j < sortNeedArray.length; j++) {
            if (lookup[sortNeedArray[j][field]] ? lookup[sortNeedArray[j][field]].includes(sortOrderArray[i]) : undefined) {
                if (!ids.includes(sortNeedArray[j].id)) {
                    ids.push(sortNeedArray[j].id)
                    sorted.push(sortNeedArray[j])
                }
            }
        }
    }
    for (let j = 0; j < sortNeedArray.length; j++) {
        let matched = false
        for (let i = 0; i < sortOrderArray.length; i++) {
            if (lookup[sortNeedArray[j][field]] ? lookup[sortNeedArray[j][field]].includes(sortOrderArray[i]) : undefined) {
                matched = true;
            }
        }
        if (!matched) {
            unSorted.push(sortNeedArray[j])
        }
    }
    let joinUnsortWithSort = sorted.concat(unSorted)
    let sortedObj = { sorted: joinUnsortWithSort, unSorted }
    return sortedObj
}

export const getIdFnameLnameObj = (arr) => {
    let obj = {}
    arr.forEach(ele => {
        obj = { ...obj, ...{ [ele.id]: `${ele.first_name} ${ele.last_name}` } }
    })
    return obj
}

export const getIdNameFomattedObj = (arr) => {
    let obj = {}
    arr.forEach(ele => {
        obj = { ...obj, ...{ [ele.id]: ele.name } }
    })
    return obj
}

export const getIdWiseObjectsFormattedObj = (arr) => {
    let obj = {}
    arr.forEach(ele => {
        obj = { ...obj, ...{ [ele.id]: ele } }
    })
    return obj
}

export const getFieldWiseObjectsFormattedObj = (arr, field) => {
    let obj = {}
    arr.forEach(ele => {
        obj = { ...obj, ...{ [ele[field]]: ele } }
    })
    return obj
}

export const groupByField = (inputArr, field) => {
    let groupObj = {}
    inputArr.forEach(ele => {

        if (groupObj.hasOwnProperty(`${ele[field]}`)) {
            groupObj[ele[field]].push(ele)
        } else {
            groupObj = { ...groupObj, [ele[field]]: [ele] }
        }
    })

    let stringifiedOBJ = JSON.parse(JSON.stringify(groupObj))
    return stringifiedOBJ

}

export const recursiveGroupByFields = (inputObj, fieldsArray, levelStart) => {
    let levelEnd = fieldsArray.length - 1

    let groupedObj = {}

    for (let inputObjArrayIndex = 0; inputObjArrayIndex < Object.keys(inputObj).length; inputObjArrayIndex++) {
        const key = Object.keys(inputObj)[inputObjArrayIndex];

        if (inputObj[key].length > 0) {

            if (levelStart < levelEnd) {
                groupedObj = { ...groupedObj, [key]: { ...recursiveGroupByFields(groupByField(inputObj[key], fieldsArray[levelStart]), fieldsArray, levelStart + 1) } }
            } else if (levelStart === levelEnd) {
                groupedObj = { ...groupedObj, [key]: { ...groupByField(inputObj[key], fieldsArray[levelStart]) } }
            }

        }
    }

    let stringifiedGroupedOBJ = JSON.parse(JSON.stringify(groupedObj))

    return stringifiedGroupedOBJ
}

export const groupByNestedField = (inputArr, fieldOne, fieldTwo) => {
    let groupObj = {}
    inputArr.forEach(ele => {
        if (groupObj.hasOwnProperty(ele[fieldOne][fieldTwo])) {
            groupObj[ele[fieldOne][fieldTwo]].push(ele)
        } else {
            groupObj = { ...groupObj, [ele[fieldOne][fieldTwo]]: [ele] }
        }
    })
    return groupObj
}

export const subGroupGroupedObj = (groupedObj, subGroupField) => {
    let subGroupedObj = {}
    for (let groupedObjIndex = 0; groupedObjIndex < Object.keys(groupedObj).length; groupedObjIndex++) {
        const groupArray = groupedObj[Object.keys(groupedObj)[groupedObjIndex]];
        let role_GroupedObj = groupByField(groupArray, subGroupField)
        subGroupedObj = { ...subGroupedObj, [Object.keys(groupedObj)[groupedObjIndex]]: role_GroupedObj }
    }
    return subGroupedObj
}

export const monthHeadersObj = (weekStartDatesArray) => {
    let monthObj = {}
    for (let i = 0; i < weekStartDatesArray.length; i++) {
        let dateLabel = weekStartDatesArray[i];
        let date = moment(dateLabel, 'DD/MM/YYYY');
        let monthYear = months[date.month()] + ' ' + date.year();
        if (monthObj.hasOwnProperty(monthYear)) {
            monthObj[monthYear].push(date.format('DD/MM/YYYY'))
        } else {
            monthObj = { ...monthObj, [monthYear]: [date.format('DD/MM/YYYY')] }
        }
    }
    return monthObj
}

export const monthWiseMap = (weekStartDatesArray) => {
    let monthMap = new Map()
    for (let i = 0; i < weekStartDatesArray.length; i++) {
        let dateLabel = weekStartDatesArray[i];
        let date = moment(dateLabel, 'DD/MM/YYYY');
        let monthYear = months[date.month()] + ' ' + date.year();
        if (monthMap.has(monthYear)) {
            monthMap.set(monthYear, [...monthMap.get(monthYear), { [dateLabel]: dateLabel }]);
        } else {
            monthMap.set(monthYear, [{ [dateLabel]: dateLabel }]);
        }
    }
    return monthMap
}

export const filterStandByEmps = (crmArray, fromDate, toDate) => {
    let notScheduledCRMemp = [];
    let scheduledCRMemp = [];

    crmArray.forEach(crm => {
        let scheduled = false
        if (crm.shdl_data?.length > 0) {
            for (let shdlIndex = 0; shdlIndex < crm.shdl_data.length; shdlIndex++) {
                const shdl = crm.shdl_data[shdlIndex];
                if (shdl.from !== null && shdl.to !== null && shdl.qty !== null) {
                    if (moment(shdl.from.slice(0, 10)).isBetween(moment(fromDate), moment(toDate), "week", []) || moment(shdl.to.slice(0, 10)).isBetween(moment(fromDate), moment(toDate), "week", [])) {
                        scheduled = true;
                        break;
                    }
                }
            }
        }
        if (scheduled) { scheduledCRMemp.push(crm.emp_id) } else { notScheduledCRMemp.push(crm.emp_id) }
    })

    return scheduledCRMemp
}

export const removePrprSchedulesforGivenDateRange = (prprArray, fromDate, toDate) => {

    let updatedPRPRObj = {}
    prprArray.forEach(prpr => {

        let newScheduleData = []

        if (prpr.shdl_data?.length > 0) {
            for (let shdlIndex = 0; shdlIndex < prpr.shdl_data.length; shdlIndex++) {

                const shdl = prpr.shdl_data[shdlIndex];


                let shdlFromDate = moment(shdl.from.slice(0, 10), 'YYYY-MM-DD')
                let shdlToDate = moment(shdl.to.slice(0, 10), 'YYYY-MM-DD')
                let selectedFromDate = moment(fromDate.slice(0, 10), 'YYYY-MM-DD')
                let selectedToDate = moment(toDate.slice(0, 10), 'YYYY-MM-DD')


                if (shdlToDate.isBefore(selectedFromDate)) {
                    newScheduleData.push(shdl)

                } else if (shdlFromDate.isAfter(selectedToDate)) {
                    newScheduleData.push(shdl)

                } else if (shdlFromDate.isBefore(selectedFromDate) &&
                    shdlToDate.isAfter(selectedToDate)) {

                    newScheduleData.push({ from: shdl.from, to: selectedFromDate.subtract(7, "days").format('YYYY-MM-DD') + "T00:00:00.000Z", qty: shdl.qty, })
                    newScheduleData.push({ from: selectedToDate.add(7, "days").format('YYYY-MM-DD') + "T00:00:00.000Z", to: shdl.to, qty: shdl.qty, })

                } else if (shdlFromDate.isBefore(selectedFromDate) &&
                    shdlToDate.isBefore(selectedToDate)) {
                    newScheduleData.push({ from: shdl.from, to: selectedFromDate.subtract(7, "days").format('YYYY-MM-DD') + "T00:00:00.000Z", qty: shdl.qty, })

                } else if (shdlFromDate.isBefore(selectedFromDate) &&
                    shdlToDate.isSame(selectedToDate)) {
                    newScheduleData.push({ from: shdl.from, to: selectedFromDate.subtract(7, "days").format('YYYY-MM-DD') + "T00:00:00.000Z", qty: shdl.qty, })

                } else if (shdlFromDate.isSame(selectedFromDate) &&
                    shdlToDate.isAfter(selectedToDate)) {
                    newScheduleData.push({ from: selectedToDate.add(7, "days").format('YYYY-MM-DD') + "T00:00:00.000Z", to: shdl.to, qty: shdl.qty, })

                } else if (shdlFromDate.isAfter(selectedFromDate) &&
                    shdlToDate.isAfter(selectedToDate)) {
                    newScheduleData.push({ from: selectedToDate.add(7, "days").format('YYYY-MM-DD') + "T00:00:00.000Z", to: shdl.to, qty: shdl.qty, })
                }
                // recalculating dates object

            }
        }
        updatedPRPRObj = { ...updatedPRPRObj, [prpr.id]: { shdl_data: newScheduleData, dates: updatesDatesQuantity(newScheduleData) } }


    })
    return updatedPRPRObj
}

export const updatesDatesQuantity = (shdl_dataArray) => {
    let newDates = {}
    if (shdl_dataArray?.length > 0) {
        for (let shdlIndex = 0; shdlIndex < shdl_dataArray.length; shdlIndex++) {
            const shdl = shdl_dataArray[shdlIndex];
            let shdlFromDate = moment(shdl.from.slice(0, 10), 'YYYY-MM-DD')
            let shdlToDate = moment(shdl.to.slice(0, 10), 'YYYY-MM-DD')

            let wd = weekStartDates(shdlFromDate, shdlToDate)
            for (let wkIndex = 0; wkIndex < wd.length; wkIndex++) {
                const monDate = wd[wkIndex]
                if (newDates.hasOwnProperty(monDate)) {
                    newDates[monDate] += parseFloat(shdl.qty)
                } else {
                    newDates = { ...newDates, [monDate]: parseFloat(shdl.qty) }
                }
            }
        }
    }
    return newDates
}

export function customFilter(term, rowData, field) {
    let search_words = term.toLowerCase().split(" ")
    let search_match = search_words.filter(item => rowData[field].toLowerCase().includes(item))
    return search_match.length > 0
}

export function getDifferenceInWeeks(fromDate, toDate) {
    let from = moment(fromDate, 'YYYY-MM-DD')
    let to = moment(toDate, 'YYYY-MM-DD')
    let diff = to.diff(from, 'weeks')
    return diff
}

export function getDifferenceInDays(fromDate, toDate) {
    let from = moment(fromDate, 'YYYY-MM-DD')
    let to = moment(toDate, 'YYYY-MM-DD')
    let diff = to.diff(from, 'days')
    return diff
}

export function getEmployeeID(employee) {
    if (employee.hasOwnProperty('employee_id')) {
        return employee.employee_id
    }
    if (employee.hasOwnProperty('id')) {
        return employee.id
    }

}

