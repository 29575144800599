import { Tab } from '@material-ui/core'
import React, { Component } from 'react'
import { crm_api } from '../../config/apiList';
import Service from '../../config/networkutils';
import Auth from '../../utils/authenticate';
import {TabContext, TabList, TabPanel } from '@material-ui/lab';
// import CloseIcon from '@material-ui/icons/Close';
import { withSnackbar } from 'notistack';
// import RolesTable from './RolesTable';
import { LoopCircleLoading } from 'react-loadingg';
import FilterbyRoleGroup from '../ReportFilters/FilterbyRoleGroup';
import FilterbyRole from '../ReportFilters/FilterbyRole';
import FilterbyProject from '../ReportFilters/FilterbyProject';
import FilterbyAllPowerData from '../ReportFilters/FilterbyAllPowerData';

const token = Auth.token()

class DvsCReports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project: 0,
            selectedProject: [],
            data: [],
            liveProjects: [],
            archiveProjects: [],
            graphDialog: false,
            graphData: [],
            isLoading: true,
            value: '1'
        }
    }

    showProject() {
        Service.get(crm_api + '?is_trashed=false', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                let data = res.data;
                let archive = []; let live = [];
                data.forEach(opportunity => {
                    if (opportunity.archive_reason > 0) {
                        archive.push(opportunity)
                    }
                    else live.push(opportunity)
                })
                this.setState({ liveProjects: live, archiveProjects: archive }, () => {
                    this.setState({
                        data: this.state.liveProjects
                    }, () => {
                        this.setState({ isLoading: false })
                    })
                })
            }).catch(error => {
                console.log(error)
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    handleProjectChange(event) {
        if (event.target.value === 0) {
            this.setState({
                data: this.state.liveProjects
            })
        }
        if (event.target.value === 1) {
            this.setState({
                data: this.state.archiveProjects
            })
        }
        this.setState({
            project: event.target.value,
            loading: true
        }, () => {
            this.setState({
                selectedProject: []
            })
        })

    }

    showGraph() {
        if (this.state.selectedProject.length === 0) {
            this.props.enqueueSnackbar('Please select the Project', {
                variant: 'warning'
            });
        } else {
            this.setState({ graphDialog: true })
        }
    }

    componentDidMount() {
        this.showProject()
    }

    render() {
        const handleChange = (event, newValue) => {
            this.setState({
                value: newValue
            })
          };

        return (
            <div>
                {this.state.isLoading ? <LoopCircleLoading /> :
                    <div>
                        <TabContext value={this.state.value}>
                                <TabList onChange={handleChange} textColor="primary" aria-label="simple tabs example">
                                    <Tab textColor="primary" label="Filter by Role Group" value="1" />
                                    <Tab textColor="primary" label="Filter by Role" value="2" />
                                    <Tab textColor="primary" label="Filter by Project" value="3" />
                                    <Tab textColor="primary" label="Filter by All Power Data" value="4" />
                                </TabList>
                            <TabPanel value="1"><FilterbyRoleGroup/></TabPanel>
                            <TabPanel value="2"><FilterbyRole/></TabPanel>
                            <TabPanel value="3"><FilterbyProject/></TabPanel>
                            <TabPanel value="4"><FilterbyAllPowerData/></TabPanel>
                        </TabContext>

                        {/* <Box display="flex" flexDirection='row'>
                            <Grid container spacing={3}>
                                <Grid item xs={2}>
                                <TextField select 
                                value={this.state.project} 
                                onChange={(event) => this.handleProjectChange(event)} variant='outlined' size='small' label="Project Type" placeholder='Project Type' fullWidth>
                                    <MenuItem value={0}>
                                        Live
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        Archive
                                    </MenuItem>
                                </TextField>
                                </Grid>
                                <Grid item xs={10}>
                                <Autocomplete
                                    multiple
                                    options={this.state.data}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, value) => this.setState({
                                        selectedProject: value
                                    })}
                                    value={this.state.selectedProject}
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Projects" />
                                    )}
                                    fullWidth />
                                    </Grid>
                                </Grid>
                        </Box>
                        <Box marginTop={2}>
                            <Button variant='contained' color='primary' onClick={() => { this.showGraph() }}>
                                Get Report
                            </Button>
                        </Box>
                        <Dialog fullScreen open={this.state.graphDialog}>
                            <AppBar>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={() => { this.setState({ graphDialog: false }) }} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" >
                                        <Box display={'flex'} flexDirection={'row'}>
                                        {this.state?.selectedProject?.map((data,index) =>{
                                            return <Box marginRight={2}>{data.name}</Box>
                                        })}
                                        </Box>
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <Box marginTop={10}>
                                    <RolesTable selectedProject={this.state.selectedProject} />
                                </Box>
                            </DialogContent>
                        </Dialog> */}
                    </div>
                }
            </div>
        )
    }
}

export default withSnackbar(DvsCReports)
