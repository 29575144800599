import React from 'react'
import MaterialTable from 'material-table';
import { Button, IconButton } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUpload'
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import RotateCircleLoading from 'react-loadingg/lib/RotateCircleLoading';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = (theme) => ({
    appBar: {
        position: 'fixed',
        top: 0,
    },
    formContainer: {
        marginTop: '64px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Modal(props) {
    const { classes, title, columns, data, onUpload, open, close, loading } = props
    return (
        <div>
            <Dialog fullScreen open={open} onClose={close} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {title ? title : "Review Data"}
                        </Typography>
                        <Button color="inherit" onClick={onUpload}>
                            Upload &ensp; <UploadIcon />
                        </Button>
                    </Toolbar>
                </AppBar>
                {
                    loading ? <RotateCircleLoading color="#005D99" /> :
                        <div className={classes.formContainer}>
                            <MaterialTable
                                title={title ? title : "Review Data"}
                                columns={columns}
                                data={data}
                                options={{
                                    paging: false,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    doubleHorizontalScroll: true,
                                    search: true,
                                    showTitle: true,
                                    headerStyle: {
                                        fontWeight: 'bold',
                                    }
                                }}
                            />
                        </div>}
            </Dialog>
        </div>
    )
}

export default withStyles(useStyles)(Modal)