import { AppBar, Box, Button, CircularProgress, Dialog, Divider, Grid, IconButton, InputAdornment, MenuItem, Paper, Popover, Slide, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React, { Component } from 'react'
import { getPrimaryColor, getSecondaryColor } from '../../Helper/CommonThings';
import Service from '../../../config/networkutils';
import { getCookie } from '../../../utils/CookieHelper';
import { withSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import Secured from '../../Secured/Secured';
import UnsecuredProjects from '../../UnsecuredProjects/UnsecuredProjects';
import ProjectList from './ProjectList';
import SearchIcon from '@material-ui/icons/Search';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { Link } from '@mui/material';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';


const token = getCookie("ptd_ts_token");

const secured_api = '/api/crm/user/pref/crm_user_favourite_project/?stage=5&is_trashed=False'
const unsecured_api = '/api/crm/user/pref/crm_user_favourite_project/?stage=3&is_trashed=False'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class Projects extends Component {


    constructor(props) {
        super(props);

        this.state = {
            tab: 0,
            projectTab: 0,
            liveSecured: [],
            completed: [],
            liveSecuredCopy: [],
            completedCopy: [],
            liveUnsecured: [],
            liveUnsecuredCopy: [],
            isLoading: true,
            open: false,
            selectedOppurtunity: {},
            liveOrCompleted: 0,
            searchCopy: [],
            showSecuredAll: false,
            showUnsecuredAll: false,
            projectCompleteLoadingFlag: true,
            projectType: 0

        }
        this.handlegetData = this.handlegetData.bind(this)
        this.handleGraph = this.handleGraph.bind(this)
    }

    handleClose = () => {
        // if (this.state.tab === 0) {
        //     this.getSecuredProjects(secured_api)
        // } else {
        //     this.getSecuredProjects(unsecured_api)
        // }
        this.getSecuredProjects(secured_api)

        this.setState({ open: false })
    }

    handleChangeTab(e, newValue) {
        this.setState({
            tab: newValue,
            isLoading: true
        }, () => {
            if (this.state.tab === 0) {
                this.getSecuredProjects(secured_api)
            } else {
                this.getSecuredProjects(unsecured_api)
            }
        })
    }

    getSecuredProjects(api) {
        Service.get(api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({
                    liveSecured: res.data.live,
                    // completed: res.data.completed,
                    liveSecuredCopy: res.data.live,
                    // completedCopy: res.data.completed
                }
                    , () => {
                        //     if(this.state.projectCompleteLoadingFlag){
                        //     this.props.projectCompleteLoading()
                        // this.setState({
                        // isLoading: false,

                        //         projectCompleteLoadingFlag: false
                        // })
                        //     }
                        this.getUnsecuredProjects(unsecured_api)

                    }
                )

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })



    }

    getUnsecuredProjects(api2) {
        Service.get(api2, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({
                    liveUnsecured: res.data.live,
                    // completed: res.data.completed,
                    liveUnsecuredCopy: res.data.live,
                    // completedCopy: res.data.completed
                }
                    ,
                    () => {
                        if (this.state.projectCompleteLoadingFlag) {
                            this.props.projectCompleteLoading()
                            this.setState({
                                isLoading: false,
                                projectCompleteLoadingFlag: false
                            })
                        }
                    }
                )

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
    }

    handleChangeProjectTab(e, newValue) {
        this.setState({
            projectTab: newValue
        })
    }

    screenSizeCheck() {
        if (window.innerWidth > 1600) {
            return true
        } else {
            return false
        }
    }

    componentDidMount() {
        const height = this.divElement.clientHeight;
        const projectHeight = this.projectElement.clientHeight
        this.props.projectHeight(projectHeight)
        if (!this.state.isLoading) {
            this.setState({ height: height }, () => {
                this.props.handleHeight(this.state.height)
            });
        }
        this.getSecuredProjects(secured_api)
    }

    setData(data) {

        let obj = {
            id: data.id,
            contractor_start_date: data.contractor_start_date,
            contractor_end_date: data.contractor_end_date,
            name: data.opportunity_name,
            opportunity_id_id: data.opportunity_id
        }
        this.setState({ selectedOppurtunity: obj }, () => { this.setState({ open: true }) })
    }

    handlegetData() {
        this.setState({
            showSecuredAll: false,
            showUnsecuredAll: false
        }, () => {
            this.getSecuredProjects(secured_api)

        })
        // if (this.state.tab === 0) {
        //     this.getSecuredProjects(secured_api)
        // } else {
        //     this.getSecuredProjects(unsecured_api)
        // }
    }

    securedSearch(data) {
        const data_lowercase = data.toLowerCase()
        var search_words = data_lowercase.split(" ");
        search_words = search_words.filter(n => n)
        var filtered_data = []
        if (search_words.length === 0) {
            filtered_data = this.state.liveSecuredCopy
        }

        for (let i = 0; i < this.state.liveSecuredCopy.length; i++) {
            const element = this.state.liveSecuredCopy[i];
            var num_of_passed = false
            for (let j = 0; j < search_words.length; j++) {
                const el = search_words[j];
                num_of_passed = element.name.toLowerCase().includes(el)
                if (!num_of_passed) {
                    break
                }
            }
            if (num_of_passed) {
                filtered_data.push(element)
            }
        }
        this.setState({ liveSecured: filtered_data })
        if (data.length > 0) {
            this.setState({
                showSecuredAll: true
            })
        } else {
            this.setState({
                showSecuredAll: false
            })
        }
    }

    unSecuredSearch(data) {
        const data_lowercase = data.toLowerCase()
        var search_words = data_lowercase.split(" ");
        search_words = search_words.filter(n => n)
        var filtered_data = []
        if (search_words.length === 0) {
            filtered_data = this.state.liveUnsecuredCopy
        }

        for (let i = 0; i < this.state.liveUnsecuredCopy.length; i++) {
            const element = this.state.liveUnsecuredCopy[i];
            var num_of_passed = false
            for (let j = 0; j < search_words.length; j++) {
                const el = search_words[j];
                num_of_passed = element.name.toLowerCase().includes(el)
                if (!num_of_passed) {
                    break
                }
            }
            if (num_of_passed) {
                filtered_data.push(element)
            }
        }
        this.setState({ liveUnsecured: filtered_data })
        if (data.length > 0) {
            this.setState({
                showUnsecuredAll: true
            })
        } else {
            this.setState({
                showUnsecuredAll: false
            })
        }
    }

    showAllSecuredData(popupState) {
        this.setState({
            liveSecured: this.state.liveSecuredCopy,
            showSecuredAll: false
        })
        popupState.close()
    }

    showAllUnsecuredData(popupState) {
        this.setState({
            liveUnsecured: this.state.liveUnsecuredCopy,
            showUnsecuredAll: false
        })
        popupState.close()
    }

    handleShowAll() {
        this.setState({
            showSecuredAll: false
        })
    }

    handleGraph(childData) {
        this.props.getGraph(childData)
    }


    render() {
        return (
            <div
                ref={(divElement) => { this.divElement = divElement }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={6} >
                        {/* <Paper style={{ border: "2px solid " + getPrimaryColor(), borderRadius: '10px' }} elevation={3}>
                            <Box p={2} style={{ fontWeight: 'bold', color: getSecondaryColor() }}>CRM</Box>
                            <Box>
                                <Divider orientation='horizontal' style={{ backgroundColor: getSecondaryColor(), borderBottomWidth: "20px" }} />
                            </Box>
                            {/* <Crm access={this.props.access} /> */}
                        {/* </Paper>
                        <CombinedResources access={this.props.access} />  */}

                    </Grid>
                    <Grid item xs={6} >
                        {/* <Paper style={{ border: "2px solid " + getPrimaryColor(), borderRadius: '10px' }} elevation={3}>
                            <Box p={2} style={{ fontWeight: 'bold', color: getSecondaryColor() }}>REPORTS</Box>
                            <Box>
                                <Divider orientation='horizontal' style={{ backgroundColor: getSecondaryColor() }} />
                            </Box>
                            {/* <Reports /> */}
                        {/* </Paper> */}
                    </Grid>
                </Grid>
                <Box style={{ border: "2px solid " + getPrimaryColor(), borderRadius: '10px' }}>
                    <Paper style={{ borderRadius: "10px 10px  0 0" }}>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            {/* <Tabs
                                value={this.state.tab}
                                onChange={(e, newValue) => this.handleChangeTab(e, newValue)}
                                style={{ color: getSecondaryColor() }}
                            >
                                <Tab label='SECURED' style={{ display: 'flex', flexGrow: 1, marginLeft: this.screenSizeCheck() ? '40px' : '10px' }} />
                                <Tab label='UNSECURED' style={{ display: 'flex', flexGrow: 1, marginLeft: this.screenSizeCheck() ? '100px' : '20px' }} />
                            </Tabs> */}
                            {this.screenSizeCheck() ?
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Box display="flex" justifyContent={'space-between'} alignItems="center">
                                            <Box p={this.screenSizeCheck() ? 2 : 1} style={{ color: getSecondaryColor(), fontSize: this.screenSizeCheck() ? 'medium' : 'small' }}>
                                                <Link component="button" onClick={() => { this.setState({ tab: 0, liveOrCompleted: 0, open: true }) }} style={{ cursor: 'pointer' }} color={getSecondaryColor()}>SECURED</Link>
                                            </Box>
                                            <Box>
                                                <PopupState variant="popover" popupId="demo-popup-popover" >
                                                    {(popupState) => (
                                                        <Box>
                                                            <Box marginTop={0.8}>
                                                                <Tooltip title="Search Secured Projects">
                                                                    <SearchIcon {...bindTrigger(popupState)}
                                                                        fontSize="small" color="secondary" style={{ cursor: 'pointer', marginLeft: "3px", mt: 0.5 }} />
                                                                </Tooltip>
                                                                {this.state.showSecuredAll ?
                                                                    <Button size="small" color='primary' onClick={() => this.showAllSecuredData(popupState)}>Show All</Button> : null}
                                                            </Box>
                                                            <Popover
                                                                {...bindPopover(popupState)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', m: 2 }}>
                                                                    <TextField
                                                                        variant='outlined'
                                                                        placeholder='Search'
                                                                        onChange={(event) => this.securedSearch(event.target.value)}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position='end'>
                                                                                    {this.state.showSecuredAll ?
                                                                                        <IconButton
                                                                                            onClick={() => this.showAllSecuredData(popupState)}
                                                                                        >
                                                                                            <CloseIcon fontSize="small" />
                                                                                        </IconButton> : null}
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Popover>
                                                        </Box>
                                                    )}
                                                </PopupState>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display="flex" justifyContent={'space-between'} alignItems="center" >
                                            <Box p={this.screenSizeCheck() ? 2 : 1} style={{ color: getSecondaryColor(), fontSize: this.screenSizeCheck() ? 'medium' : 'small' }}>
                                                <Link component="button" varian onClick={() => { this.setState({ tab: 1, liveOrCompleted: 0, open: true }) }} style={{ cursor: 'pointer' }} color={getSecondaryColor()}>UNSECURED</Link>
                                            </Box>
                                            <Box>
                                                <PopupState variant="popover" popupId="demo-popup-popover" >
                                                    {(popupState) => (
                                                        <Box>
                                                            <Box marginTop={0.8}>
                                                                <Tooltip title="Search Unsecured Projects">
                                                                    <SearchIcon {...bindTrigger(popupState)}
                                                                        fontSize="small" color="secondary" style={{ cursor: 'pointer', marginLeft: "3px", mt: 0.5 }} />
                                                                </Tooltip>
                                                                {this.state.showUnsecuredAll ?
                                                                    <Button size="small" color='primary' onClick={() => this.showAllUnsecuredData(popupState)}>Show All</Button> : null}
                                                            </Box>
                                                            <Popover
                                                                {...bindPopover(popupState)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', m: 2 }}>
                                                                    <TextField
                                                                        variant='outlined'
                                                                        placeholder='Search'
                                                                        onChange={(event) => this.unSecuredSearch(event.target.value)}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position='end'>
                                                                                    {this.state.showUnsecuredAll ?
                                                                                        <IconButton
                                                                                            onClick={() => this.showAllUnsecuredData(popupState)}
                                                                                        >
                                                                                            <CloseIcon fontSize="small" />
                                                                                        </IconButton> : null}
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Popover>
                                                        </Box>
                                                    )}
                                                </PopupState>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid> :
                                <Box display='flex' flexDirection={'row'} alignItems={'center'} width={'100%'}>
                                    <Box margin={1} display={'flex'} justifyContent={'flex-start'} alignItems={'center'} flexGrow={1}>
                                        <TextField select
                                            value={this.state.projectType}
                                            onChange={(event) => this.setState({ projectType: event.target.value })}
                                            variant='outlined' size='small' label="Project Type" placeholder='Project Type'
                                        >
                                            <MenuItem value={0}>
                                                Secured
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                Unsecured
                                            </MenuItem>
                                        </TextField>
                                    </Box>
                                    <Box>
                                        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                                            <Box m={1}>
                                                <Tooltip title={this.state.projectType === 0 ? "Open Secured Projects" : "Open Unsecured Projects"}>
                                                    <OpenInBrowserIcon fontSize="medium" color="primary" style={{ cursor: 'pointer', marginLeft: "3.5px", marginTop: 2 }} onClick={() => { this.setState({ open: true, tab: this.state.projectType }) }} />
                                                </Tooltip>
                                            </Box>
                                            <PopupState variant="popover" popupId="demo-popup-popover" >
                                                {(popupState) => (
                                                    <Box>
                                                        <Box m={1}>
                                                            <Tooltip title={this.state.projectType === 0 ? "Search Secured Projects" : "Search Unsecured Projects"}>
                                                                <SearchIcon {...bindTrigger(popupState)} fontSize="medium"
                                                                    color="primary" style={{ cursor: 'pointer', marginLeft: "3px" }} />
                                                            </Tooltip>
                                                            {this.state.projectType === 0 ?
                                                                this.state.showSecuredAll ? <Button size="small" color='primary' onClick={() => this.showAllSecuredData(popupState)}>Show All</Button> : null
                                                                :
                                                                this.state.showUnsecuredAll ? <Button size="small" color='primary' onClick={() => this.showAllUnsecuredData(popupState)}>Show All</Button> : null}
                                                        </Box>
                                                        <Popover
                                                            {...bindPopover(popupState)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}>
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', m: 2 }}>
                                                                <TextField
                                                                    variant='outlined'
                                                                    placeholder='Search'
                                                                    onChange={(event) => { this.state.projectType === 0 ? this.securedSearch(event.target.value) : this.unSecuredSearch(event.target.value) }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position='end'>
                                                                                {this.state.showUnsecuredAll ?
                                                                                    <IconButton
                                                                                        onClick={() => this.state.projectType === 0 ? this.showAllSecuredData(popupState) : this.showAllUnsecuredData(popupState)}
                                                                                    >
                                                                                        <CloseIcon fontSize="small" />
                                                                                    </IconButton> : null}
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Popover>
                                                    </Box>
                                                )}
                                            </PopupState>
                                        </Box>
                                    </Box>

                                </Box>}
                        </Box>
                    </Paper>
                    <div
                        ref={(projectElement) => { this.projectElement = projectElement }}
                    >
                        <Box >
                            <Paper style={{ borderRadius: "0 0 10px 10px" }} >
                                {/* <Box p={1} style={{ color: 'inherit', display: 'flex', justifyContent: 'flex-start' }}>
                                    <Grid container spacing={3} style={{ marginTop: '2px' }}>
                                        <Grid item xs={6} >
                                            <Box display="flex" flexDirection='row'>
                                                <Box flexGrow={1}>
                                                    <Button color="primary" size="small"
                                                        onClick={() => { this.setState({ liveOrCompleted: 0, open: true }) }}
                                                    >Live</Button>
                                                </Box>
                                                <Box>
                                                    <PopupState variant="popover" popupId="demo-popup-popover" >
                                                        {(popupState) => (
                                                            <Box>
                                                                <Box marginTop={0.8}>
                                                                    <Tooltip title="Search Secured Projects">
                                                                        <SearchIcon {...bindTrigger(popupState)} fontSize="small"
                                                                            color="secondary" style={{ cursor: 'pointer', marginLeft: "3px", mt: 0.5 }} />
                                                                    </Tooltip>
                                                                    {this.state.showLiveAll ?
                                                                        <Button size="small" color='primary' onClick={() => this.showAllLiveData(popupState)}>Show All</Button>
                                                                        : null}
                                                                </Box>
                                                                <Popover
                                                                    {...bindPopover(popupState)}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    }}>
                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', m: 2 }}>
                                                                        <TextField
                                                                            variant='outlined'
                                                                            placeholder='Search'
                                                                            onChange={(event) => { this.liveSearch(event.target.value) }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position='end'>
                                                                                        {this.state.showLiveAll ?
                                                                                            <IconButton
                                                                                                onClick={() => this.showAllLiveData(popupState)}
                                                                                            >
                                                                                                <CloseIcon fontSize="small" />
                                                                                            </IconButton> : null}
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}

                                                                        />
                                                                    </Box>
                                                                </Popover>
                                                            </Box>
                                                        )}
                                                    </PopupState>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" flexDirection='row'>
                                                <Box flexGrow={1}>
                                                    <Button color="primary" size="small"
                                                        onClick={() => { this.setState({ liveOrCompleted: 0, tab: 1, open: true }) }}
                                                    >Live </Button>
                                                </Box>
                                                <Box>
                                                    <PopupState variant="popover" popupId="demo-popup-popover" >
                                                        {(popupState) => (
                                                            <Box>
                                                                <Box marginTop={0.8}>
                                                                    <Tooltip title="Search Unsecured Projects">
                                                                        <SearchIcon {...bindTrigger(popupState)}
                                                                            fontSize="small" color="secondary" style={{ cursor: 'pointer', marginLeft: "3px", mt: 0.5 }} />
                                                                    </Tooltip>
                                                                    {this.state.showCompletedAll ?
                                                                        <Button size="small" color='primary' onClick={() => this.showAllCompletedData(popupState)}>Show All</Button> : null}
                                                                </Box>
                                                                <Popover
                                                                    {...bindPopover(popupState)}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    }}>
                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', m: 2 }}>
                                                                        <TextField
                                                                            variant='outlined'
                                                                            placeholder='Search'
                                                                            onChange={(event) => this.completedSearch(event.target.value)}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position='end'>
                                                                                        {this.state.showCompletedAll ?
                                                                                            <IconButton
                                                                                                onClick={() => this.showAllCompletedData(popupState)}
                                                                                            >
                                                                                                <CloseIcon fontSize="small" />
                                                                                            </IconButton> : null}
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </Popover>
                                                            </Box>
                                                        )}
                                                    </PopupState>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box> */}
                                <Divider style={{ backgroundColor: getSecondaryColor() }} />
                                <Box marginLeft={1} style={{ height: window.innerHeight - 165 }} >
                                    {this.state.isLoading ?
                                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                            <CircularProgress color="secondary" />
                                        </Box> :
                                        this.screenSizeCheck() ?
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <ProjectList data={this.state.liveSecured} getGraph={this.handleGraph} access={this.props.access} getData={this.handlegetData} disableShowAll={this.handleShowAll} />
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <ProjectList data={this.state.liveUnsecured} getGraph={this.handleGraph} access={this.props.access} getData={this.handlegetData} disableShowAll={this.handleShowAll} />
                                                </Grid>
                                            </Grid> :
                                            <Grid container spacing={2}>
                                                {this.state.projectType === 0 ?
                                                    <Grid item xs={12}>
                                                        <ProjectList data={this.state.liveSecured} getGraph={this.handleGraph} access={this.props.access} getData={this.handlegetData} disableShowAll={this.handleShowAll} />
                                                    </Grid> :
                                                    <Grid item xs={12} >
                                                        <ProjectList data={this.state.liveUnsecured} getGraph={this.handleGraph} access={this.props.access} getData={this.handlegetData} disableShowAll={this.handleShowAll} />
                                                    </Grid>}
                                            </Grid>
                                    }
                                </Box>
                            </Paper>

                        </Box>
                    </div>
                </Box>
                <Dialog fullScreen open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {this.state.tab === 0 ? "Secured" : "Unsecured"}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box margin={2} marginTop={10}>
                        {this.state.tab === 0 ?
                            <Secured access={this.props.access} tabValue={this.state.liveOrCompleted} disableTab={true} /> :
                            <UnsecuredProjects access={this.props.access} tabValue={this.state.liveOrCompleted} disableTab={true} />
                        }
                    </Box>
                </Dialog>
            </div>

        )
    }
}

export default withSnackbar(Projects);
