import { AppBar, Box, Dialog, Divider, IconButton, ListItem, Paper, Toolbar, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import {  getSecondaryColor } from '../../Helper/CommonThings';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import CRMTable from '../../CRM/CRMTable';
import { getCookie } from '../../../utils/CookieHelper';
import Service from '../../../config/networkutils';
import { withSnackbar } from 'notistack';


const token = getCookie("ptd_ts_token");



 class Crm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            archive: [],
            live: [],
            data:[],
            title:''
        }
    }

    handleClose = () =>{
        this.setState({
            open: false
        })
    }

    crmData(){
        Service.get('/api/crm/live_and_archive_projects_list/?is_trashed=False', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    live: res.data.live,
                    archive: res.data.archive,
                    isLoading: true
                })

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })

    }

    componentDidMount(){
        this.crmData()
    }


    render() {
        return (
            <div>
                <Paper style={{  borderRadius: '0 0 10px 10px' }} >
                    <ListItem style={{ padding: "10px" }}
                        onClick={() => this.setState({ open: true, data: this.state.live, title:'Live'})} button>
                        Live
                    </ListItem>
                    <Box>
                        <Divider variant="middle" style={{ backgroundColor: getSecondaryColor() }} />
                    </Box>
                    <ListItem style={{ padding: "10px" }}
                        onClick={() => this.setState({open: true, data: this.state.archive,title:'Archive'})}button>
                            Archive
                    </ListItem>
                </Paper>
                <Dialog fullScreen open={this.state.open} onClose={this.handleClose}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6">
                               {this.state.title}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box marginTop={10} margin={2}>
                        <CRMTable title={this.state.title} access={this.props.access} uiRefresh={true} data={this.state.data} />
                    </Box>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(Crm);
