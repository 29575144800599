
import moment from 'moment';

export function weekDates(startDate, endDate) {

    //  1 for Monday
    //  2 for Tuesday
    //  3 for Wedensday
    //  4 for Thursday
    //  5 for Friday
    const dayINeed = [1, 2, 3, 4, 5];

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');
    let dates = []
    while (currDate.diff(lastDate) <= 0) {
        if (dayINeed.includes(currDate.isoWeekday())) {
            dates.push(currDate.day(1).format('DD/MM/YYYY'));
        }
        currDate.add(1, 'days')
    }
    return dates
}

export function weekStartDates(startDate, endDate) {
    const dayINeed = 1; // for Monday

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');
    let dates = []
    while (currDate.diff(lastDate) <= 0) {
        if (
            currDate.isoWeekday() === dayINeed
            // || currDate.format('DD/MM/YYYY') == moment(startDate).startOf('day').format('DD/MM/YYYY')
        ) {
            dates.push(currDate.day(1).format('DD/MM/YYYY'));
        }
        currDate.add(1, 'days')
    }
    return dates
}
export function weekStartDatesObject(startDate, endDate) {
    const dayINeed = 1; // for Monday

    var currDate = moment(startDate).startOf("day");
    var lastDate = moment(endDate).startOf("day");

    let dates = {};
    while (currDate.diff(lastDate) <= 0) {
        if (currDate.isoWeekday() === dayINeed) {
            // Adding all monday dates to objects  
            dates = { ...dates, [currDate.day(1).format("DD/MM/YYYY")]: 0 };
        }
        currDate.add(1, "days");
    }
    return dates;
}

export function changeItToMonday(dateString) {
    // return monday date of the week
    var currDate = moment(dateString).startOf("week").add(1, 'days');

    // To stop converting to UTC added true argument'toISOString(true)'
    return currDate.toISOString(true);
}