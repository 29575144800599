import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { getCookie } from '../../utils/CookieHelper';
import Service from '../../config/networkutils'
import MaterialTable from 'material-table';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@material-ui/core';

const token = getCookie("ptd_ts_token");

class PromotionHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promotions: [],
            isLoading: true,
            actionDialog: false,
            selectedItem: null,
            buttonDisabled: false,
        }
    }

    componentDidMount() {
        this.fetchPromotions();
    }

    fetchPromotions = () => {
        Service.get(`/api/ptd/employee/promotion/employee_promotion_limited_data/?emp=${this.props.emp.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ promotions: res.data, isLoading: false })
            })
            .catch(error => {
                console.log(error);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    actionApi() {
        this.setState({ buttonDisabled: true });
        Service.patch(`/api/role/schedule/promoted_role_in_projects/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                promotion_id: this.state.selectedItem.id,
            }
        })
            .then(res => {
                this.setState({ actionDialog: false, buttonDisabled: false, selectedItem: null });
                this.props.enqueueSnackbar('Promotion action completed!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ buttonDisabled: false });
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title="Promotion History"
                    columns={[
                        { title: 'Promotion Date', field: 'promotion_date' },
                        { title: 'Promotion From', field: 'role_name' },
                        { title: 'Promotion To', field: 'previous_role_name' },
                        { title: 'Promotion Reason', field: 'description' },
                    ]}
                    data={this.state.promotions}
                    isLoading={this.state.isLoading}
                    options={{
                        search: false,
                        paging: false,
                        actionsColumnIndex: -1,
                    }}
                    // actions={[
                    //     {
                    //         icon: 'edit',
                    //         tooltip: 'Edit Data',
                    //         onClick: (e, rowData) => this.setState({ selectedItem: rowData }, () => this.setState({ actionDialog: true }))
                    //     }
                    // ]}
                />

                <Dialog open={this.state.actionDialog} onClose={() => this.setState({ actionDialog: false, selectedItem: null })}>
                    <DialogContent>
                        This will remove all the schedules after this promotion date for this employee. Are you sure you want to proceed?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ actionDialog: false, selectedItem: null })} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={this.state.buttonDisabled} onClick={() => this.actionApi()} color="primary">
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withSnackbar(PromotionHistory);