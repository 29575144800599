import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { RotateCircleLoading } from 'react-loadingg';
import ReactDOM from 'react-dom';
const useStyles = (theme) => ({
    loaderWrapper: {
        background: '#fff',
        height: '100%',
        width: '100%',
        position: 'fixed',
        top: 0,
        zIndex: 999
    }
});

class Loader extends React.Component {
   
    render() {
        return (
            ReactDOM.createPortal(<div className={this.props.classes.loaderWrapper}>
                <RotateCircleLoading color="#005D99" />
                {/* <p>Please Wait ...</p> */}
            </div>, document.getElementById('loader'))

        )
    }
}

export default withStyles(useStyles)(Loader)