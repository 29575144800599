import { getCookie, deleteCookie } from '../utils/CookieHelper';



const Auth = {
    token() {
        return getCookie("ptd_ts_token");
    },
    isAuthenticated() {
        const token = getCookie("ptd_ts_token");
        if (token) return true
        else return false
    },
    removeToken(cb) {
        deleteCookie('ptd_ts_token')
        deleteCookie('ptd_ts_user')
        setTimeout(cb, 1000)
    },
    user() {
        return JSON.parse(getCookie("ptd_ts_user"));
    }
}

export default Auth;