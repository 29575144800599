import { Button } from '@material-ui/core';
import React, { Component } from 'react'
import { getCookie } from '../../utils/CookieHelper';
import Service from '../../config/networkutils';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import moment from 'moment';
import { withSnackbar } from 'notistack';

const token = getCookie("ptd_ts_token");

class Export extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }
    getData = () => {
        let api = '';
        const csvConfig = mkConfig({
            fieldSeparator: ',',
            decimalSeparator: '.',
            filename: this.props.allData ? 'Employees Promotion  Data' : this.props.selectedEmp.first_name + ' ' + this.props.selectedEmp.last_name + "'s Promotion Data",
            columnHeaders: [{key: 'id', displayLabel: 'Emp ID'},{ key: 'first_name', displayLabel: 'First Name' }, { key: 'last_name', displayLabel: 'Last Name' },
            { key: 'previous_role', displayLabel: 'Previous Role' }, { key: 'current_role', displayLabel: 'Current Role' },{key:'promotion_date',displayLabel:'Promotion Date'}, { key: 'description', displayLabel: 'Description' }],
        });
        if (this.props.allData) {
            api = '/api/ptd/employee/promotion/employee_promotion_data/';
        } else {
            api = '/api/ptd/employee/promotion/employee_promotion_data/?emp=' + this.props.selectedEmp.id;
        }
        Service.get(api, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                if(res.data.length === 0){
                    this.props.enqueueSnackbar('No data to export!', {
                        variant: 'warning'
                    });
                    return;
                }else{
                let csvData = res.data.map((row) => {       
                    return {
                        id: row.migration_taken.previousRole.schdl_data[0].emp_id,
                        first_name: row.first_name,
                        last_name: row.last_name,
                        current_role: row.role_name,
                        previous_role: row.migration_taken.previousRole.schdl_data[0].role_name,
                        promotion_date: moment( row.migration_taken.promotionDate).format("DD-MM-YYYY"),
                        description: row.migration_taken.Description,
                    }
                })
                const csv = generateCsv(csvConfig)(csvData);
                download(csvConfig)(csv);
            }
            })
            .catch(error => {
                console.log(error)
                this.setState({ isLoading: false, })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })

    }

    render() {
        return (
            <div><Button color='primary' size="small" variant="outlined" onClick={() => { this.getData() }}>Export Promotion Data</Button></div>
        )
    }
}

export default withSnackbar(Export);