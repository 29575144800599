import React, { Component } from 'react'
import { getCookie } from '../../utils/CookieHelper';
import Service from '../../config/networkutils'
import { withSnackbar } from 'notistack';
import { Box, CircularProgress } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';

const token = getCookie("ptd_ts_token");
class WeeklyProjectResourceGraph extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            series: [],
            options: {
                chart: {
                    stacked: true,
                    type: 'bar',
                    parentHeightOffset: 0,
                },
                grid: {
                    padding: {
                        bottom: 0
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                    }
                },
                legend: {
                    show: true
                },
                title: {
                    text: 'Weekly Project Resource Build',
                    align: 'center'
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    title: 'None Working Daily Update',
                    minHeight: 10,
                    categories: [],
                    labels: {
                        style: {
                            fontSize: '12px'
                        }
                    }
                }
            }
        }
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        Service.get(`/api/subcontractor/other_reports_resource/?category=${this.props.gang.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let demand = []
                let internal = []
                let external = []
                let projectCode = []
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    demand.push(element.demand.toFixed(2))
                    internal.push(element.internal.toFixed(2))
                    external.push(element.external.toFixed(2))
                    projectCode.push(element.project_code ? element.project_code : element.project_name)
                }
                let series = [{
                    name: 'Demand',
                    data: demand,
                    color: '#3399ff',
                    group: 'demand'
                }, {
                    name: 'Internal',
                    data: internal,
                    color: '#ff9933',
                    group: 'capacity'
                }, {
                    name: 'External',
                    data: external,
                    color: '#808080',
                    group: 'capacity'
                }]
                this.setState({ series: series, options: { ...this.state.options, xaxis: { ...this.state.options.xaxis, categories: projectCode }, }, isLoading: false })
            })
            .catch(error => {
                console.log(error);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    render() {
        return (
            <div>{this.state.isLoading ?
                <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress /></Box> :
                <ReactApexChart options={this.state.options} series={this.state.series} width={'100%'} type="bar" height={window.innerHeight / 2 - 50} />}
            </div>
        )
    }
}

export default withSnackbar(WeeklyProjectResourceGraph);
