import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = (theme) => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class AutocompleteInput extends React.Component {
   
    render() {
        const props = this.props
        return (
            <Autocomplete
                multiple={props.multiple ? props.multiple : false}
                id={props.id}
                selectOnFocus
                options={props.options}
                disabled={props.disabled}
                disableCloseOnSelect={props.disableCloseOnSelect}
                getOptionLabel={(option) => props.optionLabel(option)}
                onChange={(e, value) => props.onChange(e, value)}
                value={props.value}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        {props.multiple ? <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        /> : null}
                        {props.optionLabel(option)}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant={props.variant === 'standard' ? 'standard' : 'outlined'}
                        label={props.label}
                        placeholder={props.placeholder ? props.placeholder : props.label}
                    />
                )}
            />
        )
    }
}

export default withStyles(useStyles)(AutocompleteInput);