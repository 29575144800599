import { AppBar, Box, Dialog, IconButton, ListItem, Paper, Toolbar, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { getPrimaryColor, getSecondaryColor } from '../../Helper/CommonThings';
import CloseIcon from '@material-ui/icons/Close';
import PRPLiveChange from '../../PRPLiveChange/PRPLiveChange';


export default class CombinedResources extends Component {

    constructor(){
        super()
        this.state = {
            open: false
        }
    }

    screenSizeCheck() {
        if (window.innerWidth > 1600) {
            return true
        } else {
            return false
        }
    }

    handleClose = () => { this.setState({ open: false }) }

    render() {
        return (
            <div>
                <Box marginTop={4}>
                    <Paper style={{ border:"2px solid "+getPrimaryColor() , borderRadius:'10px',color: getSecondaryColor(),fontSize: this.screenSizeCheck() ? 'medium': 'small'}} elevation={3}>
                        <ListItem button style={{padding:'15px'}}
                        onClick={() => this.setState({open:true})}
                        >COMBINED RESOURCES</ListItem>
                    </Paper>
                </Box>
                <Dialog fullScreen open={this.state.open} onClose={this.handleClose}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6">
                                COMBINED RESOURCES
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box margin={2} marginTop={10}>
                        <PRPLiveChange access={this.props.access}/>
                    </Box>
                </Dialog>
            </div>
        )
    }
}
