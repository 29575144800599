import React from 'react'
import Grid from '@material-ui/core/Grid';
import InputTable from './Table';
import { sales_status_api } from '../../config/apiList';
import Leaves from '../OtherTasks/Leaves';
import WorkType from '../OtherTasks/WorkType';

// /api/ptd/account/

const account_name_api = "/api/ptd/account/get_with_delete_status/";
const account_name_api_bulk_update = "/api/ptd/account/bulk_update";
const sales_stage_api = "/api/salesstage/";
const gate_stage_api = "/api/gatestages/";
const sub_sector_api = "/api/subsector/get_with_delete_status/";
const sub_sector_api_bulk_update = "/api/subsector/bulk_update";
const market_sub_vertical_api = "/api/marketsubvertical/";
const owner_delivery_api = "/api/ownerdelivery/get_with_delete_status/";
const owner_delivery_api_bulk_update = "/api/ownerdelivery/bulk_update";
const owner_region_sector = "/api/ownerregionsector/get_with_delete_status/";
const owner_region_sector_bulk_update = "/api/ownerregionsector/bulk_update";
const opportunity_type_api = "/api/opportunitytype/";



function TableContainer(props) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <div style={{ height: '70vh', overflowY: 'auto' }}>
                    <InputTable
                        tableName="Account Name"
                        api={account_name_api}
                        bulk_update_api={account_name_api_bulk_update}
                        upload={false}
                        access={props.access}
                        deletable={true}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div style={{ height: '70vh', overflowY: 'auto' }}>

                    <InputTable
                        tableName="Sales Stage"
                        api={sales_stage_api}
                        upload={false}
                        access={props.access}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div style={{ height: '70vh', overflowY: 'auto' }}>

                    <InputTable
                        tableName="Gate Stages"
                        api={gate_stage_api}
                        upload={false}
                        access={props.access}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div style={{ height: '70vh', overflowY: 'auto' }}>

                    <InputTable
                        // tableName="Sub Sector"
                        tableName="Discipline"
                        api={sub_sector_api}
                        upload={false}
                        bulk_update_api={sub_sector_api_bulk_update}
                        access={props.access}
                        deletable={true}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div style={{ height: '70vh', overflowY: 'auto' }}>

                    <InputTable
                        tableName="Market Sub Verticle"
                        api={market_sub_vertical_api}
                        upload={false}
                        access={props.access}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div style={{ height: '70vh', overflowY: 'auto' }}>

                    <InputTable
                        tableName="Owner Delivery Unit"
                        api={owner_delivery_api}
                        bulk_update_api={owner_delivery_api_bulk_update}
                        upload={false} access={props.access}
                        deletable={true}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div style={{ height: '70vh', overflowY: 'auto' }}>

                    <InputTable
                        tableName="Owner Region/Sector"
                        api={owner_region_sector}
                        bulk_update_api={owner_region_sector_bulk_update}
                        upload={false}
                        access={props.access}
                        deletable={true}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div style={{ height: '70vh', overflowY: 'auto' }}>

                    <InputTable
                        tableName="Opportunity Type"
                        api={opportunity_type_api}
                        upload={false}
                        access={props.access}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div style={{ height: '50vh', overflowY: 'auto' }}>

                    <InputTable
                        tableName="Sales Status"
                        api={sales_status_api}
                        upload={false}
                        access={props.access}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div style={{ height: '50vh', overflowY: 'auto' }}>
                    <Leaves />
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div style={{ height: '50vh', overflowY: 'auto' }}>
                    <WorkType />
                </div>
            </Grid>
        </Grid>
    )
}

export default TableContainer