import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Slide, TextField } from '@material-ui/core';
import React, { Component } from 'react'
import MaterialTable from 'material-table';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Auth from '../../utils/authenticate';
import Service from '../../config/networkutils';
import { getCookie } from '../../utils/CookieHelper';
import { withSnackbar } from 'notistack';

const token = Auth.token();

const user = JSON.parse(getCookie("ptd_ts_user"))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


class RoleWorkTypeLink extends Component {

    constructor(props) {
        super(props);
        this.state = {
            roles: {},
            open: false,
            selectedRoles: [],
            disabled: false
        }
    }

    getRoles() {
        let roles = []
        roles = this.props.roles.filter(ar => !this.props.rowData.linked_role_ids.find(rm => (rm.role_id === ar.id)))
        this.setState({ roles: roles })
    }

    componentDidMount() {
        this.getRoles()
    }

    addNewRoles() {
        this.setState({
            disabled: true
        })
        if (this.state.selectedRoles.length === 0) {
            this.setState({
                disabled: false
            })
            this.props.enqueueSnackbar('Select atleast one Role', {
                variant: 'warning'
            });
            return
        } else {
            let data = []
            data = this.state.selectedRoles.map(role => {
                return {
                    role: role.id,
                    work_type: this.props.rowData.id,
                    created_by: user.id
                }
            })
            Service.post('/api/role/worktype/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            }).then(res => {
                // this.getData()
                this.props.refresh()
                this.props.enqueueSnackbar('Roles Added Successfully', {
                    variant: 'success'
                });
                this.setState({
                    open: false,
                    disabled: false
                })
            }).catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        }
    }

    deleteRole(role) { 
        Service.delete(`/api/role/worktype/delete_role_work_type/?role=${Number(role.role_id)}&work_type=${Number(this.props.rowData.id)}`, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            // this.getData()
            this.props.refresh()
            this.props.enqueueSnackbar('Role Deleted Successfully', {
                variant: 'success'
            });
        }).catch(error => {
            console.log(error)
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }


    render() {

        const handleClose = () => {
            this.setState({ open: false })
        }

        return (
            <div>
                <Box margin={2}>
                    <MaterialTable
                        title={'Linked Roles'}
                        columns={[{ title: 'Id', field: 'role_id' }, { title: 'Name', field: 'role_name' }]}
                        data={this.props.rowData.linked_role_ids}
                        options={{
                            padding: 'dense',
                            actionsColumnIndex: -1
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add Roles',
                                isFreeAction: true,
                                onClick: (event, rowData) => this.setState({ open: true })
                            }
                        ]}
                        editable={{
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        // this.deleteOps(oldData.id)
                                        this.deleteRole(oldData)
                                        resolve();
                                    }, 1000);
                                })
                        }}
                    />
                </Box>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                >
                    <DialogTitle>Select Roles to link to {this.props.rowData.name}</DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            options={this.state.roles}
                            style={{ width: 300 }}
                            getOptionLabel={(option) => option.job_roles}
                            onChange={(e, value) => {
                                this.setState({ selectedRoles: value })
                            }}
                            value={this.state.selectedRoles}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Roles"
                                />
                            )}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.job_roles}
                                </React.Fragment>
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.addNewRoles()} color="primary" disabled={this.state.disabled}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(RoleWorkTypeLink);
