import dayjs from 'dayjs';
import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'
// import Service from '../../config/networkutils';
import { withSnackbar } from 'notistack';
import { CircularProgress } from '@material-ui/core';

// const token = Auth.token()

// function isInteger(x) { return typeof x === "number" && isFinite(x) && Math.floor(x) === x; }
function isFloat(x) { return !!(x % 1); }

class GraphComponent extends Component {


  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      height: this.props.dashboard ? this.screenSizeCheck() ? 220 : 180 : this.screenSizeCheck() ? 550 : 250,
      value: '1',
      series: null,
      groupObj: null,
      attrition: null,
      thCapacity: null,
      requiredDemand: null,
      options: {
        noData: {
          text: 'No Data'
        },
        chart: {
          type: "bar",
          height: 550,
          stacked: false,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        legend: {
          show: this.props.dashboard ? false : true
        },
        colors: ['#25e6a5', '#26a0fc', '#febc3b'],
        fill: {
          opacity: [0.45, 1, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        xaxis: {
          type: 'category',
          labels: {
            show: true,
            formatter: function (val = new Date()) {
              let dateArray = String(val).split("-")
              return "Q" + Math.floor(Number(dateArray[1]) / 3 + 1)
            }
          },
          tickPlacement: 'between',
          group: {
            style: {
              fontSize: '10px',
              fontWeight: 700
            },
            groups: []
          }
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return isFloat(val) ? val.toFixed(2) : val
            }
          }
        },
        dataLabels: {
          formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
            if (seriesIndex === 1) {
              // return (w.config.series[1].data[dataPointIndex]['y'] - w.config.series[2].data[dataPointIndex]['y'])
              return isFloat((w.config.series[1].data[dataPointIndex]['y'] - w.config.series[2].data[dataPointIndex]['y'])) ? (w.config.series[1].data[dataPointIndex]['y'] - w.config.series[2].data[dataPointIndex]['y']).toFixed(2) : (w.config.series[1].data[dataPointIndex]['y'] - w.config.series[2].data[dataPointIndex]['y'])
            } else {
              return val
            }
          },
          style: {
            colors: ["#707070"]
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            formatter: function (val) {
              let dateArray = String(val).split("-")
              return "Q" + Math.floor(Number(dateArray[1]) / 3 + 1) + " " + dayjs(val).format("YYYY")
            }
          },
          y: {
            formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
              // let dateArray = String(val).split("-")
              if (seriesIndex === 1) {
                let tooltipValue = series[1][dataPointIndex] - series[2][dataPointIndex];
                if (tooltipValue >= 0) {
                  // return tooltipValue;
                  return isFloat(tooltipValue) ? tooltipValue.toFixed(2) : tooltipValue;
                } else {
                  return val;
                }
              } else {
                return val;
              }
            }
          }
        },
      },


    };
  }
  screenSizeCheck() {
    if (window.innerWidth > 1600) {
      return true
    } else {
      return false
    }
  }

  getGraphData() {

    // Service.get(`/api/roles/demand_vs_capacity/?project=${this.props.selectedProject.id}&start=${'2023-09-09'}&end=${'2028-09-09'}`, {
    //   headers: {
    //     Authorization: "Token " + token,
    //   },
    // })
    //   .then(res => {

    // let roleObj = {}
    // for (let index = 0; index < this.props.rowData.length; index++) {
    //   const element = this.props.rowData[index];
    //   if (element.id === this.props.rowData.id) {
    //     roleObj = element
    //     break
    //   }
    // }
    let capacityObj = {}

    for (let index = 0; index < this.props.rowData.quater.length; index++) {
      // let capacityObj =   Object.entries(this.props.rowData.quater).map( x => ({
      const element = this.props.rowData.quater[index];
      let year = element.quarter_start.split('-')
      if (!(year[0] in capacityObj)) {
        capacityObj[year[0]] = 1
      } else {
        capacityObj[year[0]] += 1
      }

    }

    var groupObj = Object.entries(capacityObj).map(x => ({
      'title': x[0],
      'cols': x[1]
    }))

    let demand = Object.entries(this.props.rowData.quater).map(x => ({
      'x': x[1].quarter_start,
      'y': x[1].demand + x[1].other_demand
    }))
    let capacity = Object.entries(this.props.rowData.quater).map(x => ({
      'x': x[1].quarter_start,
      'y': x[1].capacity
    }))
    let otherDemand = Object.entries(this.props.rowData.quater).map(x => ({
      'x': x[1].quarter_start,
      'y': x[1].other_demand
    }))
    let attrition = Object.entries(this.props.rowData.quater).map(x => ({
      'x': x[1].quarter_start,
      'y': x[1].attrition
    }))
    let thCapacity = Object.entries(this.props.rowData.quater).map(x => ({
      'x': x[1].quarter_start,
      'y': x[1].th_capacity
    }))
    let requiredDemand = Object.entries(this.props.rowData.quater).map(x => ({
      'x': x[1].quarter_start,
      'y': x[1].required_demand
    }))


    let series = [
      { name: 'Capacity', type: 'column', data: capacity },
      { name: 'Demand', type: 'area', data: demand },
      { name: 'Other Demand', type: 'area', data: otherDemand },
    ]
    this.setState({
      series: series,
      groupObj: groupObj,
      attrition: attrition,
      thCapacity: thCapacity,
      requiredDemand: requiredDemand,
      options: {
        ...this.state.options,
        xaxis: {
          ...this.state.options.xaxis,
          group: {
            ...this.state.options.xaxis.group,
            groups: groupObj
          }
        }
      }
    }, () => {
      // this.props.loading(false)
      this.setState({
        isLoading: false
      })
      //   })
      // }).catch(error => {
      //   console.log(error)
      //   this.props.enqueueSnackbar('Something went wrong!', {
      //     variant: 'error'
      //   });
    })
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue })
  };


  componentDidMount() {
    this.getGraphData()
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? <CircularProgress /> :
          <div>
            {/* <TabContext value={this.state.value}>
                <TabList onChange={this.handleChange} aria-label="simple tabs example" textColor='primary' indicatorColor='secondary'>
                  <Tab label="DvsC Graph" value="1" />
                  <Tab label="Demand Attrition Graph" value="2" />
                </TabList>
              <TabPanel value="1"> */}

            <ReactApexChart options={this.state.options} series={this.state.series} width={'100%'} type="bar" height={this.state.height} />
            {/* </TabPanel> */}
            {/* <TabPanel value="2">  */}
            {/* <BarGraphComponent groupObj={this.state.groupObj} attrition={this.state.attrition} 
              thCapacity={this.state.thCapacity} requiredDemand={this.state.requiredDemand} /> */}
            {/* </TabPanel>
            </TabContext> */}
          </div>
        }
      </div>
    )
  }
}

export default withSnackbar(GraphComponent);


