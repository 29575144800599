import React from 'react'
import Button from '@material-ui/core/Button';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import moment from 'moment';
import { DatePicker } from "@material-ui/pickers";
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import Service from '../../../../config/networkutils';
import {
    prp_roles_api,
    prp_template_api,
    prptandPrprDeleteByPRPtid,
    prp_roles_api_bulk_update,
    bulkPRPTandPRPRcreate,

} from '../../../../config/apiList';
import RotateCircleLoading from 'react-loadingg/lib/RotateCircleLoading';
import { withSnackbar } from 'notistack';
import UploadIcon from '@material-ui/icons/CloudUpload'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import XLSX from "xlsx";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import Modal from '../../../../shared/Modal/Modal';
import Dropzone from '../../../../shared/Dropzone/Dropzone';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { getCookie } from '../../../../utils/CookieHelper';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { filterOneObjArrayByAnoterObjArray, sortByGivenOrder } from '../../../../utils/reuseFunctions';
import { ohlOldSortOrder, ohlroles } from '../../../../config/abstractedVariables';
import PRPEmpTable from './OHLPRPEmpTable';
import TextField from "@material-ui/core/TextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { weekStartDates } from '../../../../utils/betweenDates';
import MaterialTable from 'material-table';


const token = getCookie("ptd_ts_token");

const useStyle = (theme) => ({
    customGridStyle: {
        margin: 0,
        paddingRight: '8px',
        paddingLeft: '16px',
        marginBottom: '16px'
    },
    appBar: {
        position: 'fixed',
        top: 0,
    },
    formContainer: {
        marginTop: '64px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
        width: "calc(100% - 20px)"
    },
    rows: {
        backgroundColor: "#EDFAFF",
    },
    leftSticky: {
        zIndex: 99, position: "sticky",
        left: 0,
        background: "#F8F8F8",
        boxShadow: "1px 2px 1px grey"
    }
})

// const validQty = [0.2, 0.4, 0.5, 0.6, 0.8, 1]

class OHLPRPTemplate extends React.Component {
    constructor() {
        super();
        this.state = {
            columns: [],
            unSortedData: [],
            sortedData: [],
            zoom: 0.9,
            weekDateRange: [],
            start_date: '',
            subTableCol: [],
            subTabledata: [],
            showDialog: false,
            customQtyValues: [{ from: '', to: '', qty: '' }],
            selectedRole: {},
            selectedEmployee: {},
            modelOpen: false,
            allActiveRoles: [],
            rolesObj: {},
            rolesToPost: "",
            isLoading: true,
            allEmp: [],
            allEmpObj: {},
            reviewModal: false,
            dataToUpload: [],
            dropZoneModal: false,
            reviewTableCol: [],
            reviewTableData: [],
            newRoles: [],
            disciplineArr: [],
            disciplineObj: {},
            discipline: '',
            owner_delivery_unit: {},
            owner_region: {},
            sector: {},
            rolesForSort: [],
            sortByRoles: [
                // { id: 117 },
                // { id: 3 },
                // { id: 2 },
            ],
            totalCountType: false,
            rowDelete: false,
            addGang: false,
            assignFromDate: '',
            assignToDate: '',
            assignQty: '',
            assignDates: '',
            selectedGangMembers: [],
            gangAdd: false,
            scheduleTotal: 0,
            data: []
        }

    }
    postGangOps(data) {
        return new Promise((resolve, reject) => {
            Service.post(prp_roles_api, {
                headers: {
                    Authorization: "Token " + token,
                },
                data,
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => reject(error))
        })
    }
    postGangsOps(data) {
        return new Promise((resolve, reject) => {
            Service.post(bulkPRPTandPRPRcreate, {
                headers: {
                    Authorization: "Token " + token,
                },
                data,
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => reject(error))
        })
    }

    filterExistingPrpt() {
        let sortedDataCopy = [...this.state.sortedData]
        let descipline = "Electricity OHLT"
        let prpCreate = []
        let prpExist = []

        this.state.selectedGangMembers.forEach(gangMem => {
            let equal = false;
            let existRole = {};
            for (let PrpTemplateIndex = 0; PrpTemplateIndex < sortedDataCopy.length; PrpTemplateIndex++) {
                const prpt = sortedDataCopy[PrpTemplateIndex];

                if ((prpt.role === gangMem.ptd_employee_data.job_title) && (this.props.allSubSectorsLupObj[prpt.descipline]) === descipline) {
                    equal = true
                    existRole = prpt
                    break
                }
            }

            if (equal) {
                prpExist.push({ gangMem, existRole, })

            } else {

                prpCreate.push(gangMem)
            }

        }
        )
        let filterExistingPrptObj = { prpExist, prpCreate, sortedDataCopy }
        return filterExistingPrptObj
    }


    filterscheduledEmpsWithInputDate = (crmArray, fromDate, toDate) => {

        let notScheduledCRMemp = [];
        let scheduledCRMemp = [];

        crmArray.forEach(crm => {
            let scheduled = false
            if (crm.shdl_data.length > 0) {
                for (let shdlIndex = 0; shdlIndex < crm.shdl_data.length; shdlIndex++) {
                    const shdl = crm.shdl_data[shdlIndex];
                    if (moment(shdl.from.slice(0, 10)).isBetween(fromDate, toDate, undefined, []) && moment(shdl.to.slice(0, 10)).isBetween(fromDate, toDate, undefined, [])) {
                        scheduled = true;
                        break;
                    }
                }
            }
            if (scheduled) { scheduledCRMemp.push(crm.emp_id) } else { notScheduledCRMemp.push(crm.emp_id) }
        })
        return scheduledCRMemp
    }
    assignEmploeesToPRP = () => {

        if (!this.state.assignToDate && !this.state.assignQty && !this.state.assignFromDate) {
            this.props.enqueueSnackbar('Fill date range and qty', {
                variant: 'info'
            });
            return
        }
        let putPRPTArray = []
        let gangs = []

        let { prpExist, prpCreate } = this.filterExistingPrpt()

        if (prpExist.length > 0) {
            prpExist.forEach(RleMem => putPRPTArray.push({
                crm: this.props.project.crm.crm,
                prp: RleMem['existRole'].id,
                emp_id: RleMem['gangMem'].ptd_employee,
                dates: this.state.assignDates,
                descipline: 2,
                shdl_data: [
                    {
                        to: this.state.assignToDate,
                        qty: this.state.assignQty,
                        from: this.state.assignFromDate
                    }]

            }))

        }

        if (prpCreate.length > 0) {
            prpCreate.forEach(RleMem => gangs.push({
                emp_id: RleMem.ptd_employee,
                role_id: RleMem.ptd_employee_data.job_title,
                descipline: 2,
                dates: this.state.assignDates,
                shdl_data: [
                    {
                        to: this.state.assignToDate,
                        qty: this.state.assignQty,
                        from: this.state.assignFromDate
                    }]
            }))
        }
        let postPRP = { crm: this.props.project.crm.crm, gangs }

        let conditionalPromise = () => {
            if (prpCreate.length > 0) {
                return [this.postGangsOps(postPRP),
                this.postGangOps(putPRPTArray)]
            } else {
                return [this.postGangOps(putPRPTArray)]

            }
        }
        Promise.all(
            conditionalPromise()
        ).then(res => {
            this.getPRPtemplates(this.props.project['crm']['crm'])
            this.setState({ isLoading: false, gangAdd: false, selectedGangMembers: [], assignToDate: '', assignFromDate: '', assignQty: '', assignDates: '', assignEmployees: [] })
            this.props.enqueueSnackbar('Selected gangmen added to PRP successfully', {
                variant: 'success'
            });
        }).catch(error => {
            console.log(error)
            this.setState({ isLoading: false, gangAdd: false })
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })


    }

    handleCustomFromDate = (date) => {
        if (date.toString().slice(0, 3) === "Mon") {
            this.setState({
                assignFromDate: date.toISOString().slice(0, 10) + "T00:00:00.000Z",
            });
        } else {
            this.props.enqueueSnackbar("You are allowed to select only Mondays!!!", {
                variant: "error",
            });
        }
    };
    handleCustomToDate = (date) => {
        if (moment(this.state.assignFromDate).isAfter(moment(date))) {
            this.props.enqueueSnackbar(
                "From date is higher than To date!!!", {
                variant: "error",
            }
            )
            return
        }
        if (date.toString().slice(0, 3) === "Mon") {
            this.setState({
                assignToDate: date.toISOString().slice(0, 10) + "T00:00:00.000Z",
            });
        } else {
            this.props.enqueueSnackbar("You are allowed to select only Mondays!!!", {
                variant: "error",
            });
        }
    };

    handleCustomQty = (value) => {
        let dates = {};
        if (value) {
            value = parseFloat(value)
            if (this.state.assignFromDate && this.state.assignToDate) {
                let allMondayDates = weekStartDates(this.state.assignFromDate, this.state.assignToDate)
                let scheduleTotal = allMondayDates.length * value;
                for (let dateIndex = 0; dateIndex < allMondayDates.length; dateIndex++) {
                    const element = allMondayDates[dateIndex];
                    dates = { ...dates, [element]: value }
                }

                this.setState({
                    assignDates: dates, assignQty: value, scheduleTotal
                });

            } else {
                this.props.enqueueSnackbar("Select From and To dates", {
                    variant: "error",
                });

            }
        } else {

            this.setState({
                assignDates: dates, assignQty: value
            });
        }
    };

    getPRPtemplates = (crmID) => {

        this.setState({ isLoading: true })
        let crmDetails = this.props.allCRMS.filter(crm => crm.id === crmID)
        Service.get(prp_template_api + "?crm=" + crmID, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let newData = []
                let contract_start_date = crmDetails[0].contractor_start_date
                let contract_end_date = crmDetails[0].contractor_end_date
                this.setState({ contract_start_date, contract_end_date, prpData: res.data })
                this.setColumns(contract_start_date, contract_end_date, res.data)
                let allprpRoleLupObj = {}
                res.data.forEach(prpt => {
                    let dates = {}
                    let prproles = prpt.emp
                    prproles.forEach(ele => {
                        allprpRoleLupObj = { ...allprpRoleLupObj, ...{ [ele.id]: ele.job_title } }
                    })
                    let total = 0;
                    let project_total = 0;
                    let tender_total = 0;
                    // if (prproles.length) {
                    let gangData = filterOneObjArrayByAnoterObjArray(prproles, this.props.gang, 'emp_id', 'ptd_employee')
                    if (gangData) {
                        prproles = gangData
                    } else {
                        prproles = []
                    }
                    prproles.forEach(emp => {
                        for (const date in emp.dates) {
                            let qty = Number(emp.dates[date])
                            if (date in dates) {
                                qty += Number(dates[date])
                            }
                            if (emp.type === 1) {
                                project_total += qty
                            }
                            if (emp.type === 2) {
                                tender_total += qty
                            }
                            total = total + qty;
                            dates = { ...dates, [date]: qty.toFixed(1) }
                        }

                    })
                    // }
                    let set = []
                    for (const item in dates) {
                        set.push(item)
                    }
                    let sorted = set.sort(function (a, b) {
                        a = a.toString().split('/');
                        b = b.toString().split('/');
                        return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
                    });

                    let st_en_date = { start_date: sorted[0], end_date: sorted[sorted.length - 1] }
                    dates = {
                        ...dates,
                        total: total.toFixed(2),
                        ...prpt,
                        ...st_en_date,
                        project_total: project_total.toFixed(2),
                        tender_total: tender_total.toFixed(2)
                    }
                    newData.push(dates)
                })
                let sortedObj = sortByGivenOrder(ohlOldSortOrder, newData, this.state.rolesObj, "role")

                this.setState({ sortedData: sortedObj['sorted'], unSortedData: sortedObj['unSorted'], allprpRoleLupObj, isLoading: false })

            }).catch(error => {
                console.log(error);
                this.props.enqueueSnackbar('Something wne wrong!', {
                    variant: 'error',
                });
            })
    }





    componentDidMount() {

        this.getPRPtemplates(this.props.project['crm']['crm'])

        let oHLRoles = filterOneObjArrayByAnoterObjArray(this.props.allActiveRoles, ohlroles, 'id', 'id')
        this.setState({
            allOwner_delivery_unit: this.props.allOwner_delivery_unitsLupObj,
            allOwner_region: this.props.allOwner_regionsLupObj,
            disciplineArr: this.props.allSubSectors,
            disciplineObj: this.props.allSubSectorsLupObj,
            allEmp: this.props.allOHLEmps,
            allEmpObj: this.props.allOHLEmpsLupObj,
            getActiveRoles: this.props.getActiveRoles,
            rolesObj: this.props.allActiveRolesLupObj,
            allActiveRoles: this.props.allActiveRoles,
            oHLRoles,
            isLoading: false
        },

        )
    }

    addCustomField = () => {
        let fileds = [...this.state.customQtyValues]
        let obj = {
            from: '',
            to: '',
            qty: ''
        }
        fileds.push(obj)
        this.setState({ customQtyValues: fileds })
    }

    openDialog = () => {
        this.setState({ showDialog: true })
    }

    closeDialog = () => {
        this.setState({ showDialog: false })
    }

    setColumns(startDate, endDate, prpData) {
        let column = [

            {
                title: "Role",
                field: "role",

                lookup: this.state.rolesObj,
                headerStyle: {
                    width: "100px", zIndex: 99, position: "sticky",
                    left: '70px',
                    background: "#F8F8F8",
                    boxShadow: "1px 2px 1px grey"
                },
                cellStyle: { minWidth: "200px" },
            },
            {
                title: "Discipline",
                field: "descipline",
                width: 100,
                lookup: this.state.disciplineObj,
                headerStyle: { width: "100px" },
            },
            // {
            //   title: "",
            //   field: "",
            //   width: 100,
            //   filtering: false,
            // headerStyle: { width: "100px", },
            // },
            {
                title: "Total",
                field: "total",
                type: "numeric",
                width: 80,
                filtering: false,
                headerStyle: { width: "100px" },
            },
            {
                title: "Project Total",
                field: "project_total",
                type: "numeric",
                width: 80,
                filtering: false,
                headerStyle: { width: "100px" },
            },
            {
                title: "Tender Total",
                field: "tender_total",
                type: "numeric",
                width: 80,
                filtering: false,
                headerStyle: { width: "100px" },
            },

            {
                title: "Start Date",
                field: "start_date",
                type: "date",
                width: 100,
                filtering: false,
                headerStyle: { width: "100px" },
            },
            {
                title: "End Date",
                field: "end_date",
                type: "date",
                width: 100,
                filtering: false,
                headerStyle: { width: "100px" },
            },
        ];

        let reviewTableColumn = [
            {
                title: "Role",
                field: "role",
                width: 100,
                lookup: this.state.rolesObj,
                headerStyle: { minWidth: "200px" },
                cellStyle: { minWidth: "200px" },
            },
            {
                title: "Discipline",
                field: "descipline",
                width: 100,
                lookup: this.state.disciplineObj,
            },
            {
                title: "Employee",
                field: "emp_id",
                width: 100,
                lookup: this.state.allEmpObj,
            },
            {
                title: "Type",
                field: "type",
                width: 100,
                lookup: { 1: "Project", 2: "Tender" },
            },
        ];

        let subTableCol = [
            // { title: "", field: "" },
            // { title: "", field: "" },
            {
                title: "Employee",
                field: "emp_id",
                width: 100,
                lookup: this.state.allEmpObj,
                headerStyle: {
                    width: "100px", zIndex: 99, position: "sticky",
                    left: '150px',
                    background: "#F8F8F8",
                    boxShadow: "1px 2px 1px blue"
                },
            },
            {
                title: "Type",
                field: "type",
                width: 100,
                lookup: { 1: "Project", 2: "Tender" },
                filtering: false,
                headerStyle: { width: "100px" },
            },
            {
                title: "Total weeks",
                field: "total_weeks",
                width: 400,
                type: "numeric",
                filtering: false,
                headerStyle: { width: "100px" },
            },
            {
                title: "Start Date",
                field: "start_date",
                type: "date",
                width: 100,
                editable: "never",
                filtering: false,
                headerStyle: { width: "100px" },
                editComponent: (props) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            // autoOk
                            clearable
                            format="dd.MM.yyyy"
                            minDate={startDate}
                            maxDate={endDate}
                            emptyLabel=""
                            value={props.value ? props.value : startDate}
                            onChange={(e) => props.onChange(e)}
                        />
                    </MuiPickersUtilsProvider>
                ),
            },
            {
                title: "End Date",
                field: "end_date",
                type: "date",
                width: 100,
                editable: "never",
                filtering: false,
                headerStyle: { width: "100px" },
                editComponent: (props) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            // autoOk
                            clearable
                            format="dd.MM.yyyy"
                            minDate={startDate}
                            maxDate={endDate}
                            emptyLabel=""
                            value={props.value ? props.value : startDate}
                            onChange={(e) => props.onChange(e)}
                        />
                    </MuiPickersUtilsProvider>
                ),
            },
        ];

        for (let i = 0; i < prpData.length; i++) {
            let prpEmp = [...prpData[i].emp];
            for (let j = 0; j < prpEmp.length; j++) {
                let prpEmpDates = prpData[i]["emp"][j]["dates"];
                if (prpEmpDates) {
                    for (const date in prpEmpDates) {
                        if (moment(date, "DD/MM/YYYY").isBefore(moment(startDate))) {
                            startDate = moment(date, "DD/MM/YYYY").format();
                        } else if (moment(date, "DD/MM/YYYY").isAfter(moment(endDate))) {
                            endDate = moment(date, "DD/MM/YYYY").format();
                        }
                    }
                }
            }
        }

        let dates = this.getWeekOfDates(startDate, endDate);

        // this.setState({ weekDateRange: dates })

        dates.map((date, i) => {
            let newObj = {
                title: date,
                field: date,
                type: "numeric",

                sorting: false,
                headerStyle: {
                    transform: "rotate(270deg)",
                    width: "100px",
                    textAlign: "center",
                },
                cellStyle: {
                    textAlign: "center",
                },
                editable: "never",
                filtering: false,
            };
            column.push(newObj);
            subTableCol.push(newObj);
            reviewTableColumn.push(newObj);
            return null;
        });
        this.setState({
            columns: column,
            subTableCol: subTableCol,
            reviewTableCol: reviewTableColumn,
            weekDateRange: dates,
        });
    }

    getWeekOfDates = (startDate, endDate) => {

        const dayINeed = 1; // for Monday

        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');
        let dates = []
        while (currDate.diff(lastDate) <= 0) {
            if (
                currDate.isoWeekday() === dayINeed ||
                currDate.format('DD/MM/YYYY') === moment(startDate).startOf('day').format('DD/MM/YYYY')
            ) {
                dates.push(currDate.format('DD/MM/YYYY'));
            }
            currDate.add(1, 'days')
        }
        return dates
    }

    fillDatesWithQty = (dates, qty) => {
        let obj = {};
        dates.forEach(date => {
            obj = { ...obj, [date]: qty }
        })
        return obj;
    }

    handleZoom = (e, v) => {
        this.setState({ zoom: v })
    }
    decreaseZoom = () => {
        let zoom = (Number(this.state.zoom) - 0.1).toFixed(1);
        if (zoom < 0.5) return;
        this.setState({ zoom: zoom })

    }

    increaseZoom = () => {
        let zoom = (Number(this.state.zoom) + 0.1).toFixed(1);
        if (zoom > 1.0) return;
        this.setState({ zoom: zoom })

    }

    handleChange = (e) => {
        let { name, value } = e.target
        this.setState({ [name]: value })
    }

    addRole = (e, rowdata) => {
        this.handleClickOpen()
    }
    deleteAlert = (rowdata, rowIndex) => {
        this.setState({ deleteRowData: rowdata, deleteRowIndex: rowIndex }, () => this.setState({ rowDelete: true, }))

    }
    deleteRole = () => {
        this.setState({ isLoading: true })
        Service.get(`${prptandPrprDeleteByPRPtid}${this.state.deleteRowData?.id}`, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                let dupData = [...this.state.sortedData]
                dupData.splice(this.state.deleteRowIndex, 1)

                this.setState({ sortedData: dupData, rowDelete: false, isLoading: false, deleteRowData: {} }, () => {
                    this.props.enqueueSnackbar('Role deleted successfully.', {
                        variant: 'success'
                    })
                })
            }).catch(error => {
                console.log(error)
                this.setState({ rowDelete: false, isLoading: false, deleteRowData: {} }, () =>
                    this.props.enqueueSnackbar('Role deletion failed.', {
                        variant: 'error'
                    }))
            })
    }

    addRoleOps = () => {
        for (let i = 0; i < this.state.sortedData.length; i++) {
            if (Number(this.state.sortedData[i].role) === Number(this.state.rolesToPost)
                && Number(this.state.sortedData[i].descipline) === Number(this.state.discipline)) {
                this.props.enqueueSnackbar('Role and Discipline is already added.', {
                    variant: 'error'
                });
                return;
            }
        }
        Service.post(prp_template_api, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                role: this.state.rolesToPost,
                descipline: this.state.discipline,
                crm: this.props.project['crm']['crm']
            }
        })
            .then(res => {
                this.handleClose();
                this.getPRPtemplates(this.props.project['crm']['crm'])
                this.props.enqueueSnackbar('Role and Discipline is successfully added.', {
                    variant: 'success'
                });
            }).catch(error => {
                this.props.enqueueSnackbar('Something went wrong.', {
                    variant: 'error'
                });
            })
    }

    handleClickOpen = () => { this.setState({ modelOpen: true }) }

    handleClose = () => { this.setState({ modelOpen: false }) }

    reviewModalOpen = () => { this.setState({ reviewModal: true }) }

    reviewModalClose = () => { this.setState({ reviewModal: false }) }

    fileToRead(files) {
        if (!files || !files[0]) return;
        let f = files[0];
        try {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;

            reader.onload = (e) => {
                /* read workbook */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });

                /* grab first sheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                /* save data */
                const data = XLSX.utils.sheet_to_json(ws)

                data.forEach(ele => {
                    if (ele.role) {
                        for (let k in this.state.rolesObj) {
                            if (this.state.rolesObj[k] === ele.role) {
                                ele.role = k
                            }
                        }
                    }
                    if (ele.discipline) {
                        for (let k in this.state.disciplineObj) {
                            if (this.state.disciplineObj[k] === ele.discipline) {
                                ele.discipline = k
                            }
                        }
                    }
                    if (ele.employee) {
                        for (let i = 0; i < this.state.allEmp.length; i++) {
                            if (this.state.allEmp[i].emp_no === ele.employee) {
                                ele.employee = this.state.allEmp[i].id
                            }
                        }
                    }
                    if (ele.type) {
                        if (String(ele.type).trim() === 'Project') {
                            ele.type = 1
                        }
                        if (String(ele.type).trim() === 'Tender') {
                            ele.type = 2
                        }
                    }
                })

                this.makeUplodFormat(data)
            };
            if (rABS) {
                reader.readAsBinaryString(f);
            } else {
                reader.readAsArrayBuffer(f);
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    makeUplodFormat(arr) {
        let data = arr;
        let newarr = [];
        data.forEach(emp => {
            let obj = {};
            obj.role = emp.role;
            obj.emp_id = emp.employee
            obj.descipline = emp.discipline
            obj.type = emp.type
            obj.role_key = emp.role_key
            let dates = []
            for (const ele in emp) {
                if (moment(ele, "DD/MM/YYYY").isValid()) {
                    let date = moment(ele, 'DD/MM/YYYY').format('DD/MM/YYYY')
                    dates.push({ date: date, qty: emp[ele] })
                }
            }
            let sorted = dates.sort(function (a, b) {
                a = a.date.toString().split('/');
                b = b.date.toString().split('/');
                return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
            });
            let shdlData = [];
            let fromDate = null;
            let rangeQty = null;
            sorted.forEach((ele, i) => {
                if (fromDate === null) fromDate = ele.date;
                if (rangeQty === null) rangeQty = ele.qty;
                obj.dates = { ...obj.dates, ...{ [ele.date]: ele.qty } }
                if (ele.qty !== rangeQty || i === sorted.length - 1) {
                    let daterange = {
                        from: moment(fromDate, "DD/MM/YYYY").toDate(),
                        to: i + 1 === sorted.length && sorted.length !== 1 ? (moment(sorted[i - 1].date, "DD/MM/YYYY").toDate()) : (moment(ele.date, "DD/MM/YYYY").toDate()),
                        qty: rangeQty
                    }
                    if (sorted.length === 1) {
                    }
                    shdlData.push(daterange)
                    fromDate = ele.date;
                    rangeQty = ele.qty;
                }
            })
            obj.shdl_data = shdlData;
            obj = { ...obj, ...obj.dates }
            newarr.push(obj)
        });
        this.setState({ reviewTableData: newarr })

        let addedRoles = new Set()
        for (let j = 0; j < this.state.data.length; j++) {
            addedRoles.add(Number(this.state.data[j].role))
        }

        let newRoles = new Set();
        for (let i = 0; i < newarr.length; i++) {
            if (!addedRoles.has(Number(newarr[i].role))) {
                newRoles.add(newarr[i].role)
            }
        }

        let rolesBulk = []
        newRoles.forEach(newrole => {
            let obj = {
                role: newrole,
                descipline: '',
                crm: this.props.project.id,
            }
            newarr.forEach(emp => {
                if (newrole === emp.role) {
                    obj.descipline = emp.descipline;
                    obj.role_key = emp.role_key
                }
            })
            rolesBulk.push(obj)
        });
        this.setState({ newRoles: rolesBulk })
    }


    bulkUpload = () => {

        if (this.state.newRoles.length === 0) {
            this.filterExcelDataEmployee();
            return;
        }

        this.setState({ bulkUploadLoader: true })

        Service.post(prp_template_api, {
            headers: {
                Authorization: "Token " + token,
            },
            data: this.state.newRoles
        })
            .then(res => {
                let roles = [...this.state.data, ...res.data];
                this.setState({ data: roles }, () => {
                    this.filterExcelDataEmployee()
                })

            })
            .catch(error => {

                this.setState({ bulkUploadLoader: false })
                this.props.enqueueSnackbar('Something went wrong.', {
                    variant: 'error'
                });
            })
    }

    async filterExcelDataEmployee() {

        this.setState({ bulkUploadLoader: true })
        let roles = [...this.state.data];
        let empExcelData = [...this.state.reviewTableData];
        let dataToUpdate = [];

        /* Iteration of Employee from review tbale data array */
        for (let i = 0; i < empExcelData.length; i++) {
            /*setting CRM id for employee as project id is CRM id for employee in PRP */
            empExcelData[i]['crm'] = this.props.project.id

            let foundInRoles = false;
            let id;
            //  Checking from roles(or PRP table data existed)
            roles.forEach(role => {
                if (role.role === empExcelData[i].role) {
                    // setting employee PRP id as role id is PRP id for employee in PRP table
                    empExcelData[i]['prp'] = role.id;
                }


                let emp = role['emp'];
                for (let k = 0; k < emp.length; k++) {
                    if (empExcelData[i]['emp_id'] && emp[k]['emp_id'] && (empExcelData[i]['emp_id'] === emp[k]['emp_id'])) {
                        foundInRoles = true;
                        id = emp[k]['id']
                        // break;
                    }
                }

            })


            if (foundInRoles) {
                empExcelData[i]['id'] = id
                dataToUpdate.push(empExcelData[i])
            }
        }



        let dataToUpload = []

        for (let i = 0; i < empExcelData.length; i++) {
            let foundInUpdate = false
            for (let j = 0; j < dataToUpdate.length; j++) {
                if (empExcelData[i].role && dataToUpdate[j].role && empExcelData[i].role === dataToUpdate[j].role) {
                    foundInUpdate = true;
                    break;
                }
            }
            if (!foundInUpdate) dataToUpload.push(empExcelData[i])
        }

        if (dataToUpload.length === 0) {
            this.props.enqueueSnackbar('No new data found!', {
                variant: 'error'
            });
            return;
        }
        /* +++++++++++ Start Upload in DB  ++++++++++++++*/
        if (dataToUpload.length > 0) {
            let added = await this.bulkEmployeeAdd(dataToUpload)
            if (added.success && dataToUpdate.length > 0) {
                await this.bulkEmployeeUpdate(dataToUpdate)
            }

            this.setState({ bulkUploadLoader: false, reviewModal: false, dropZoneModal: false });
            this.props.getActiveRoles();
        }
        else {
            if (dataToUpdate.length > 0) {
                await this.bulkEmployeeUpdate(dataToUpdate)
                this.setState({ bulkUploadLoader: false, reviewModal: false, dropZoneModal: false });
                this.props.getActiveRoles()
            }
        }
        /* ============================== */

    }

    bulkEmployeeAdd(empData) {
        return new Promise((resolve, reject) => {
            Service.post(prp_roles_api, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: empData
            })
                .then(res => {

                    this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? ' Employee' : ' Employees') + ' successfully added', {
                        variant: 'success'
                    });

                    resolve({ success: true })
                })
                .catch(error => {
                    console.error(error)
                    if (!error || error.status !== 400) {
                        this.props.enqueueSnackbar('Something went wrong!', {
                            variant: 'error'
                        });
                    }
                    if (error.status === 400) {
                        if (error.data.length) {
                            for (let i = 0; i < error.data.length; i++) {
                                const err = error.data[i]
                                let hasError = Object.keys(err).length !== 0 && err.constructor === Object;
                                if (hasError) {
                                    for (const e in err) {
                                        this.props.enqueueSnackbar("Upload error : " + (empData[i]['emp_id'] ? +'emp_id - ' + empData[i]['emp_id'] : '') + " - " + e + " (" + err[e] + ")", {
                                            variant: 'error'
                                        });
                                        return;
                                    }
                                }
                            }

                        }
                    }
                    reject({ error: false })
                })
        })
    }

    bulkEmployeeUpdate(data) {
        return new Promise((resolve, reject) => {
            Service.patch(prp_roles_api_bulk_update, {
                headers: {
                    Authorization: "Token " + token,
                },
                data
            })
                .then(res => {

                    this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? ' Employee' : ' Employees') + ' successfully updated', {
                        variant: 'success'
                    });

                    resolve({ success: true })
                })
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    reject({ success: false })
                })
        })
    }

    dropzoneClose = () => {
        this.setState({ dropZoneModal: false })
    }

    handleTotalType = (e) => {
        let { name, checked } = e.target
        this.setState({ [name]: checked })
        let data = [...this.state.data]
        if (checked === true) {
            for (let i = 0; i < data.length; i++) {
                if (data[i]['total']) {
                    data[i]['total'] = (Number(data[i].total) * 8).toFixed(2)
                }
            }
            this.setState({ data: data })
        }
        if (checked === false) {
            for (let i = 0; i < data.length; i++) {
                if (data[i]['total']) {
                    data[i]['total'] = (Number(data[i].total) / 8).toFixed(2)
                }
            }
            this.setState({ data: data })
        }
    }

    tableCellRender(role, columnLabel) {
        // returns a lookup names
        if (columnLabel.field === "role") {
            return columnLabel.lookup[role[columnLabel.field]];
        } else if (columnLabel.field === "descipline") {
            return columnLabel.lookup[role[columnLabel.field]];
        }
        return role[columnLabel.field];
    }



    render() {

        const { classes } = this.props;
        return (
            <>
                {this.state.isLoading ? <RotateCircleLoading color="#005D99" /> :
                    <div>
                        <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                            {this.props.uploadPRPAccess ?
                                <Grid item sm={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginBottom: '15px' }}
                                        onClick={() => this.setState({ dropZoneModal: true })}
                                        size='small'
                                    >
                                        Upload PRP Data Excel&ensp;<UploadIcon />
                                    </Button>
                                </Grid> : null}
                            <Grid item sm={4}>
                                {/* {this.props.addPRPAccess ? */}
                                <Button size="small" variant="outlined" onClick={this.addRole}>Add Role&ensp;<PersonAddIcon /></Button>
                                {/* : null} */}
                            </Grid>
                            <Grid item sm={4}>
                                <Button size="small" variant="outlined" color="primary" onClick={() => this.setState({ gangAdd: true })}>{'+ Add Gang'}</Button>
                            </Grid>


                            <Grid item sm={2}>
                                <FormControlLabel
                                    control={<Switch
                                        checked={this.state.totalCountType}
                                        onChange={this.handleTotalType}
                                        name="totalCountType" />}
                                    label="week/hours"
                                />
                            </Grid>
                            <Grid item sm={3}>
                                <ToggleButtonGroup
                                    exclusive
                                    aria-label="text alignment"
                                    style={{ float: 'right' }}
                                    size='small'
                                >
                                    <ToggleButton value="weeks" aria-label="by week" onClick={this.decreaseZoom} >
                                        <ZoomOut />
                                    </ToggleButton>
                                    <ToggleButton value="months" aria-label="by month" onClick={this.increaseZoom}>
                                        <ZoomIn />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>

                        </Grid>
                        {/* <Grid container spacing={2} className={this.props.classes.customGridStyle}>
                            <Grid item xs={12} sm={3}><b>Project Name :</b> {this.state.project_name}</Grid>
                            <Grid item xs={12} sm={3}><b>Project Number :</b> {this.state.project_no}</Grid>
                            <Grid item xs={12} sm={3}><b>Contract Award Date :</b> {moment(this.state.contract_start_date).startOf('day').format('DD/MM/YYYY')}</Grid>
                            <Grid item xs={12} sm={3}><b>Contract End Date :</b> {moment(this.state.contract_end_date).startOf('day').format('DD/MM/YYYY')}</Grid>
                            </Grid> */}
                        {/* {this.props.addPRPAccess ? <Grid item xs={12} sm={12}>
                                                    <Button size="small" variant="outlined" onClick={this.addRole}>Add Role&ensp;<PersonAddIcon /></Button>
                                                </Grid> : null} */}
                        <div style={{ zoom: this.state.zoom, overflow: 'scroll', width: '100vw' }} key="prpTemplateZoom">
                            <Paper elevation={3} variant={"outlined"}>

                                <Table>
                                    <TableHead></TableHead>
                                    <TableBody>
                                        <TableRow className={classes.tableRow}>
                                            <TableCell className={classes.leftSticky}>{'Actions'}</TableCell>
                                            {this.state.columns.map((columnLabel, inde) => (
                                                <TableCell
                                                    key={inde}
                                                    style={{
                                                        ...columnLabel.headerStyle,
                                                    }}
                                                >
                                                    {columnLabel.title}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        {this.state.sortedData.map((row, index) => (
                                            <>
                                                <TableRow
                                                    key={index}
                                                    className={
                                                        this.state[index] ? classes.rows : null
                                                    }
                                                    onClick={(e) => {
                                                        if (this.state[index]) {
                                                            this.setState({ row: {}, [index]: false });
                                                        } else {
                                                            this.setState({ row, [index]: true });
                                                        }
                                                    }}
                                                >
                                                    <TableCell className={classes.leftSticky}>
                                                        {/* {this.props.deletePRPAccess ? */}
                                                        <IconButton size="small" onClick={() => this.deleteAlert(row, index)}><Tooltip title={'Remove Role'}><DeleteForeverIcon /></Tooltip> </IconButton>
                                                        {/* : null} */}
                                                    </TableCell>
                                                    {this.state.columns.map((columnLabel, i) => (
                                                        <TableCell
                                                            key={i}
                                                            style={columnLabel.headerStyle}
                                                        >
                                                            {this.tableCellRender(row, columnLabel)}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                                {this.state[index] ? (
                                                    < PRPEmpTable
                                                        gang={this.props.gang}
                                                        roleData={this.state.row}
                                                        columns={this.state.subTableCol}
                                                        project={this.props.project}
                                                        allRoles={this.state.rolesObj}
                                                        roles={this.state.allActiveRoles}
                                                        employees={this.state.allEmp}
                                                        // isLoading={this.props.isLoading}
                                                        // refreshRoles={this.props.getActiveRoles}
                                                        prpData={this.state.prpData}
                                                        owner_delivery_unit={
                                                            this.state.owner_delivery_unit
                                                        }
                                                        owner_region={this.state.owner_region}
                                                        sector={this.state.disciplineObj}
                                                        getPRPtemplates={this.getPRPtemplates}
                                                    // editPRPAccess={this.props.editPRPAccess}
                                                    // deletePRPAccess={this.props.deletePRPAccess}
                                                    />
                                                )
                                                    :
                                                    null}
                                            </>
                                        ))}

                                    </TableBody>
                                </Table>

                            </Paper>

                        </div>

                        <Dialog open={this.state.modelOpen} onClose={this.handleClose}>
                            <DialogTitle id="form-dialog-title">
                                Add Role
                                <IconButton
                                    aria-label="close"
                                    onClick={this.handleClose}
                                    style={{ position: 'absolute', top: '5px', right: '20px' }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel>Select Role</InputLabel>
                                    <Select
                                        id="rolesToPost"
                                        name="rolesToPost"
                                        value={Number(this.state.rolesToPost)}
                                        onChange={(e) => this.handleChange(e)}
                                        label="Select Role"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {this.state.oHLRoles.map(role => (
                                            <MenuItem value={role.id}>{role.job_roles}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel>Select Discipline</InputLabel>
                                    <Select
                                        id="discipline"
                                        name="discipline"
                                        value={Number(this.state.discipline)}
                                        onChange={(e) => this.handleChange(e)}
                                        label="Select Discipline"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {this.state.disciplineArr.map(disc => (
                                            <MenuItem value={disc.id}>{disc.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={this.handleClose}
                                    color="secondary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={this.addRoleOps}
                                    disabled={!this.state.rolesToPost || !this.state.discipline}
                                >
                                    Add
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={this.state.rowDelete} onClose={() => this.setState({ rowDelete: false })}>
                            <DialogTitle id="form-dialog-title">
                                Delete Role
                            </DialogTitle>
                            <DialogContent>
                                <Typography>Are you sure to delete -- {this.state.deleteRowData?.role ? this.state.rolesObj[this.state.deleteRowData.role] : "Role"}</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => this.setState({ rowDelete: false })}
                                    color="secondary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="red"
                                    onClick={this.deleteRole}
                                >
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={this.state.addGang} onClose={() => this.setState({ addGang: false })}>
                            <DialogTitle id="form-dialog-title">
                                Add Gang
                            </DialogTitle>
                            <DialogContent>

                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => this.setState({ addGang: false })}
                                    color="secondary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="red"
                                    onClick={this.addGangToProject}
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.gangAdd} onClose={() => { this.props.assignEmployeesUpdate([]); this.setState({ gangAdd: false, assignEmployeesUpdate: [] }) }} fullWidth={true} maxWidth={"lg"}>
                            <DialogTitle id="form-dialog-title">
                                Add Gang to PRP
                                <IconButton aria-label="close" onClick={() => { this.props.assignEmployeesUpdate([]); this.setState({ gangAdd: false, assignEmployeesUpdate: [] }) }} style={{ position: 'absolute', top: '5px', right: '20px' }}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent style={{ padding: 15 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id={"from"}
                                                // label="From"
                                                value={this.state.assignFromDate ? this.state.assignFromDate : ""}
                                                onChange={(date) =>
                                                    this.handleCustomFromDate(date)
                                                }
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                className={this.props.classes.date}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id={"to"}
                                                // label="To"
                                                value={this.state.assignToDate ? this.state.assignToDate : ""}
                                                onChange={(date) =>
                                                    this.handleCustomToDate(date)
                                                }
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                className={this.props.classes.date}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <TextField
                                            id={"qty"}
                                            label="Qty/Week"
                                            type="number"
                                            fullWidth
                                            margin="dense"
                                            value={this.state.assignQty ? this.state.assignQty : ""}
                                            onChange={(e) =>
                                                this.handleCustomQty(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Button style={{ color: 'primary' }} onClick={() => this.props.filterscheduledEmpsWithInputDate(this.state.assignFromDate, this.state.assignToDate)}>Get Available Employees</Button>
                                    </Grid>
                                </Grid>

                                <MaterialTable
                                    title="Employees"
                                    columns={[
                                        { title: 'Employee No', field: 'ptd_employee_data.emp_no', },
                                        { title: 'First Name', field: 'ptd_employee_data.first_name', },
                                        { title: 'Last Name', field: 'ptd_employee_data.last_name', },
                                        { title: 'Role', field: 'ptd_employee_data.job_title', lookup: this.props.allActiveRolesLupObj },
                                    ]}
                                    data={this.props.assignEmployees}
                                    isLoading={this.state.isLoading}
                                    options={{
                                        headerStyle: {
                                            fontWeight: 'bold',
                                        },
                                        paging: false,
                                        padding: 'dense',
                                        showTitle: false,
                                        searchFieldAlignment: 'left',
                                        pageSize: 20,
                                        selection: true
                                    }}
                                    onSelectionChange={(rows) => this.setState({ selectedGangMembers: rows })}
                                // onRowClick={(event, rowData, togglePanel) => {
                                //     this.postOps({
                                //         ptd_employee: rowData.id,
                                //         general_foreman: this.props.gf.id,
                                //     })
                                // }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button color="secondary" onClick={() => { this.props.assignEmployeesUpdate([]); this.setState({ gangAdd: false }) }}>Cancel</Button>
                                <Button color="primary" onClick={() => this.assignEmploeesToPRP()}>Assign Selected gang</Button>
                            </DialogActions>
                        </Dialog>
                        <Dropzone
                            open={this.state.dropZoneModal}
                            onClose={this.dropzoneClose}
                            onChange={(e) => this.fileToRead(e)}
                            preview={this.reviewModalOpen}
                        />

                        <Modal
                            open={this.state.reviewModal}
                            close={this.reviewModalClose}
                            onUpload={this.bulkUpload}
                            columns={this.state.reviewTableCol}
                            data={this.state.reviewTableData}
                            loading={this.state.bulkUploadLoader}
                            title={"PRP excel data review"}

                        />

                    </div>
                }
            </>
        )
    }

}

export default withSnackbar(withStyles(useStyle)(OHLPRPTemplate))