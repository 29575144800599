import React, { Component } from 'react'
import { getActiveRoles } from '../../config/PromisesHandler';
import { AppBar, Box, Button, Checkbox, Dialog, DialogContent, IconButton, TextField, Toolbar, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { LoopCircleLoading } from 'react-loadingg';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import RolesTable from '../DvsCReports.js/RolesTable';
import { withSnackbar } from 'notistack';


const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

class FilterbyRole extends Component {

  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      checkedIcon: false,
      loading: true,
      selectedRoleGroups: []
    }
  }

  componentDidMount() {

    Promise.all([getActiveRoles()])
      .then(res => {
        this.setState({
          roles: res[0]
        }, () => {
          this.setState({
            loading: false
          })
        })
      })
      .catch(e => {
        this.props.enqueueSnackbar('Something went wrong. Please try again later.', {
          variant: 'error'
        });
      })
  }
  submit() {
    if (this.state.selectedRoleGroups.length === 0) {
      this.props.enqueueSnackbar('Please Select a Role', {
        variant: 'warning'
      });
    } else {
      this.setState({
        graphDialog: true
      })
    }
  }


  render() {
    return (
      <div>
        {this.state.loading ? <LoopCircleLoading /> :
          <Box>
            <Autocomplete
              multiple
              id="roles"
              options={this.state.roles}
              disableCloseOnSelect
              getOptionLabel={(option) => option.job_roles}
              onChange={(e, value) => this.setState({
                selectedRoleGroups: value
              })}
              value={this.state.selectedRoleGroups}
              size="small"
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.job_roles}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Roles" />
              )}
            />
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginTop={2}>
              <Button color='primary' variant='contained' onClick={() => this.submit()}>Submit</Button>
            </Box>
            <Dialog fullScreen open={this.state.graphDialog}>
              <AppBar>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={() => { this.setState({ graphDialog: false }) }} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" >
                    {/* <Box display={'flex'} flexDirection={'row'}>
                      {this.state?.selectedProject?.map((data, index) => {
                        return <Box marginRight={2}>{data.name}</Box>
                      })}
                    </Box> */}
                    Filter by Role
                  </Typography>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <Box marginTop={10}>
                  <RolesTable selectedProject={this.state.selectedRoleGroups} type={'role'} />
                </Box>
              </DialogContent>
            </Dialog>
          </Box>}
      </div>
    )
  }
}

export default withSnackbar(FilterbyRole);
