import React from 'react'
import Service from '../../../config/networkutils';
import { withStyles } from '@material-ui/core/styles';
import ROAPReport from './ROAPReport';
import { Button, Grid } from '@material-ui/core';
import { crm_api, owner_delivery_api, owner_region_sector_api, sales_stage_api, sales_status_api } from '../../../config/apiList';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withSnackbar } from 'notistack';
import { RotateCircleLoading } from 'react-loadingg';
import { getCookie } from '../../../utils/CookieHelper';
import { updateFilters, updateURLParams } from '../../../utils/reuseFunctions';

const token = getCookie("ptd_ts_token");


const useStyles = (theme) => ({
    appBar: {
        position: 'fixed',
        top: 0,
    },
    formContainer: {
        marginTop: '64px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class ROAPReportHome extends React.Component {
    constructor() {
        super()
        this.state = {
            modelOpen: false,
            selectedOpportunity: {},
            selectedOpportunities: [],
            reportModal: false,
            opportunities: [],
            loading: false,
            ownerRegions: [],
            selectedOwnerRegions: [],
            ownerDeliveries: [],
            selectedOwnerDeliveries: [],
            salesStages: [],
            selectedSalesStages: [{
                id: 4,
                name: "Closed/Won"
            }],
            salesStatuses: [],
            selectedSalesStatuses: []
        }
    }

    getOwnerDelivery() {
        return new Promise((resolve, reject) => {
            Service.get(owner_delivery_api, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => reject(error))
        })
    }

    getSalsStatus() {
        return new Promise((resolve, reject) => {
            Service.get(sales_status_api, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => reject(error))
        })
    }

    getRegion() {
        return new Promise((resolve, reject) => {
            Service.get(owner_region_sector_api, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => reject(error))
        })
    }

    getSalesStage() {
        return new Promise((resolve, reject) => {
            Service.get(sales_stage_api, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => reject(error))
        })
    }

    getCRM() {
        return new Promise((resolve, reject) => {
            Service.get(crm_api + '?is_trashed=false', {
                headers: {
                    Authorization: "Token " + token,
                }
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }


    componentDidMount() {
        this.setState({ loading: true })

        Promise.all([
            this.getRegion(),
            this.getOwnerDelivery(),
            this.getSalesStage(),
            this.getSalsStatus(),
            this.getCRM()
        ])
            .then(res => {
                
                let filtered = res[4].filter(ele => ele.archive_reason === 0)
                let live = [];
                filtered.forEach(opportunity => {
                    if (opportunity.bid_resource) {
                        live.push(opportunity)
                        }
                })
                this.setState({ data: filtered })
                filtered.unshift({ name: 'Select All' });
                this.opportunity = filtered;
                this.allOpportunity = live;

                this.setState({
                    ownerRegions: res[0],
                    ownerDeliveries: res[1],
                    salesStages: res[2],
                    salesStatuses: res[3],
                    opportunities: filtered,
                    loading: false
                }, () => {
                    let selectedOwnerRegions = updateFilters('selectedOwnerRegions', res[0]);
                    let selectedOwnerDeliveries = updateFilters('selectedOwnerDeliveries', res[1]);
                    let selectedSalesStages = updateFilters('selectedSalesStages', res[2]);
                    let selectedSalesStatuses = updateFilters('selectedSalesStatuses', res[3]);

                    if (
                        selectedOwnerRegions.length > 0 ||
                        selectedOwnerDeliveries.length > 0 ||
                        selectedSalesStages.length > 0 ||
                        selectedSalesStatuses.length > 0
                    ) {


                        this.setState({
                            selectedOwnerRegions,
                            selectedOwnerDeliveries,
                            selectedSalesStages,
                            selectedSalesStatuses,
                        }, () => {
                            this.filterProjectsOnChange('selectedOwnerRegions', selectedOwnerRegions)
                            this.setState({ reportModal: true })
                        })

                    }
                })
            }).catch(err => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ loading: false })
            })
    }

    rowClicked = (e, rowdata) => {
        this.setState({ selectedOpportunity: rowdata })
        this.handleClickOpen()
    }

    handleClickOpen = () => { this.setState({ modelOpen: true }) }

    handleClose = () => { this.setState({ modelOpen: false }) }

    handleChangeMultiple = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({ selectedFilter: value });
    };

    handleChange = (event) => {

        this.setState({ selectedFilter: event.target.value });
    };

    viewReport = () => {
        this.setState({ reportModal: false }, () => {
            this.setState({ reportModal: true })
        })
    }

    closeReport = () => {
        this.setState({ reportModal: false })
    }

    setValue(e, value) {
        value.forEach(ele => {
            if (ele.name === "Select All") {
                // this.setState({selectedFilter : this.state.opportunity})
                value = this.state.opportunity
            }
        })
        let searchParams = new URLSearchParams(window.location.search)
        let selectedOpportunityIds = [];
        if (value.length > 0) {

            value.forEach((region) => {
                selectedOpportunityIds.push(region.id)
            })

            if (searchParams.has("selectedOpportunity")) {
                searchParams.set("selectedOpportunity", selectedOpportunityIds.join(","))
            } else {
                searchParams.append("selectedOpportunity", selectedOpportunityIds.join(","))
            }

        } else if (value.length === 0) {

            searchParams.delete("selectedOpportunity")

        }
        if (!searchParams.get("selectedOpportunity")) {

            searchParams.delete("selectedOpportunity")
        }

        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash

        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);


        this.setState({ selectedFilter: value });
    }

    setLoading = (flag) => {
        this.setState({ loading: flag })
    }

    regionChange(e, value) {

        if (!value) {
            this.setState({ opportunity: this.opportunity, selectedRegion: value })
            return;
        }
        let filtered = this.opportunity.filter(ele => ele.owner_region_sector === value.id && (this.state.selectedSalesStage.id && ele.sales_stage === this.state.selectedSalesStage.id))
        this.setState({ selectedRegion: value, opportunity: filtered })
    }

    stageChange(e, value) {
        if (!value) {
            value = {
                id: 4,
                name: "Closed/Won"
            }
        }
        if (!this.state.selectedRegion || !this.state.selectedRegion.id) {
            let filtered = this.opportunity.filter(ele => (value.id && ele.sales_stage === value.id))
            this.setState({ selectedSalesStage: value, opportunity: filtered })
            return;
        }
        let filtered = this.opportunity.filter(ele => (this.state.selectedRegion.id && ele.owner_region_sector === this.state.selectedRegion.id) && (value.id && ele.sales_stage === value.id))
        this.setState({ selectedSalesStage: value, opportunity: filtered })
    }
    
    filterOpportunity(value, projects, nameField) {
        if (value.length > 0 && nameField) {
            let filteredOpportunities = []
            for (let valueIndex = 0; valueIndex < value.length; valueIndex++) {
                const eachValue = value[valueIndex];
                for (let opportunityIndex = 0; opportunityIndex < projects.length; opportunityIndex++) {
                    const opportunity = projects[opportunityIndex];
                    if (opportunity[nameField] === eachValue['id']) {
                        filteredOpportunities.push(opportunity)
                    }
                }
            }
            if (filteredOpportunities.length <= 0) {
                this.props.enqueueSnackbar('No projects for the filter', {
                    variant: 'info'
                });
                return []
            }
            return filteredOpportunities
        }
    }

    filterProjectsOnChange(name, value) {

        updateURLParams(name, value)

        let filteredOpportunities = [...this.state.opportunities]
        this.setState({ [name]: value }, () => {
            if (filteredOpportunities.length > 0 && this.state.selectedOwnerRegions.length > 0) {
                filteredOpportunities = this.filterOpportunity(this.state.selectedOwnerRegions, filteredOpportunities, 'owner_region_sector')
            }

            if (filteredOpportunities.length > 0 && this.state.selectedOwnerDeliveries.length > 0) {
                filteredOpportunities = this.filterOpportunity(this.state.selectedOwnerDeliveries, filteredOpportunities, 'owner_delivery')
            }

            if (filteredOpportunities.length > 0 && this.state.selectedSalesStages.length > 0) {
                filteredOpportunities = this.filterOpportunity(this.state.selectedSalesStages, filteredOpportunities, 'sales_stage')
            }

            if (filteredOpportunities.length > 0 && this.state.selectedSalesStatuses.length > 0) {
                filteredOpportunities = this.filterOpportunity(this.state.selectedSalesStatuses, filteredOpportunities, 'sales_status')
            }
            this.setState({ selectedOpportunities: filteredOpportunities })
        })


    }

    render() {
        return (<>
            {this.state.loading ? <RotateCircleLoading color="#005D99" /> :
                <Grid container style={{ marginBottom: '20px' }} spacing={2}>
                    <Grid item xs={3}>
                        <Autocomplete
                            multiple
                            id="OwnerRegions"
                            options={this.state.ownerRegions}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => this.filterProjectsOnChange('selectedOwnerRegions', value)}
                            value={this.state.selectedOwnerRegions}
                            size="small"
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="OwnerRegion" placeholder="OwnerRegions" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            multiple
                            id="ownerDeliveries"
                            options={this.state.ownerDeliveries}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => this.filterProjectsOnChange('selectedOwnerDeliveries', value)}
                            value={this.state.selectedOwnerDeliveries}
                            size="small"
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Owner Delivery Unit" placeholder="Owner Delivery Unit" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            multiple
                            id="sales-stage"
                            options={this.state.salesStages}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => this.filterProjectsOnChange('selectedSalesStages', value)}
                            value={this.state.selectedSalesStages}
                            size="small"
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Sales Stages" placeholder="sales Stages" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            multiple
                            id="sales-status"
                            options={this.state.salesStatuses}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => this.filterProjectsOnChange('selectedSalesStatuses', value)}
                            value={this.state.selectedSalesStatuses}
                            size="small"
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Sales Statuses" placeholder="Sales Statuses" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <Autocomplete
                            multiple
                            id="resource-report"
                            options={this.state.opportunities}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => this.setState({ selectedOpportunities: value })}
                            value={this.state.selectedOpportunities}
                            size="small"
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Opportunity" placeholder="Opportunity" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ position: 'relative' }}>
                        <Button
                            variant="contained"
                            // size="medium"
                            color="primary"
                            onClick={this.viewReport}
                            style={{ position: 'absolute', bottom: '4px', marginLeft: '20px' }}
                            disabled={this.state.selectedOpportunities.length === 0}>Get Report</Button>
                    </Grid>
                </Grid>
            }

            {this.state.reportModal ? <ROAPReport projects={this.state.selectedOpportunities} isLoading={this.props.isLoading} /> : null}

        </>)
    }
}

export default withSnackbar(withStyles(useStyles)(ROAPReportHome))