import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import Service from '../../config/networkutils';
import Auth from '../../utils/authenticate';
import GraphComponent from '../GraphComponent/GraphComponent';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box, Button } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment/moment';
import { CoffeeLoading, LoopCircleLoading } from 'react-loadingg';
import BarGraphComponent from '../BarGraphComponent/BarGraphComponent';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Dialog, DialogContent } from '@material-ui/core';


const token = Auth.token()
// const date = moment()

class RolesTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            data: [],
            graphData: [],
            startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
            endDate: moment().add(5, "years").format("YYYY-MM-DD"),
            disabled: true,
            value: '1',
            reportButton: false,
            reportLoading: false,
        }
    }

    getGraphData() {
        if (this.props.type !== 'all_power_data') {
            var id = ""
            for (let index = 0; index < this.props.selectedProject.length; index++) {
                const element = this.props.selectedProject[index];
                if (element.id !== undefined) {
                    if (index === this.props.selectedProject.length - 1) {
                        id += element.id
                    } else {
                        id += element.id + ","
                    }
                }
            }
        }
        let url = ''
        if (this.props.type === 'role_grp') {
            url = `/api/roles/dvsc_report/?role_group=${id}&start=${this.state.startDate}&end=${this.state.endDate}`
        } else if (this.props.type === 'role') {
            url = `/api/roles/dvsc_report/?role=${id}&start=${this.state.startDate}&end=${this.state.endDate}`
        } else if (this.props.type === 'all_power_data') {
            url = `/api/roles/dvsc_report/?all_power_data=${1}&start=${this.state.startDate}&end=${this.state.endDate}`
        }
        else {
            if (this.props.selectedRole.length > 0) {
                let rid = ''

                for (let index = 0; index < this.props.selectedRole.length; index++) {
                    const element = this.props.selectedRole[index];
                    if (element.id !== undefined) {
                        if (index === this.props.selectedRole.length - 1) {
                            rid += element.id
                        } else {
                            rid += element.id + ","
                        }
                    }
                }
                url = `/api/roles/dvsc_report/?project=${id}&role=${rid}&start=${this.state.startDate}&end=${this.state.endDate}`
            } else if (this.props.selectedRoleGroups.length > 0) {

                let rid = ''

                for (let index = 0; index < this.props.selectedRoleGroups.length; index++) {
                    const element = this.props.selectedRoleGroups[index];
                    if (element.id !== undefined) {
                        if (index === this.props.selectedRoleGroups.length - 1) {
                            rid += element.id
                        } else {
                            rid += element.id + ","
                        }
                    }
                }
                url = `/api/roles/dvsc_report/?project=${id}&role_group=${rid}&start=${this.state.startDate}&end=${this.state.endDate}`
            } else {

                url = `/api/roles/dvsc_report/?project=${id}&start=${this.state.startDate}&end=${this.state.endDate}`
            }
        }
        if (this.props.type === 'project') {
            url += `&with_region=${this.props.withRegion}&sectorized=${this.props.withSector}`
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data
                }, () => {
                    this.setState({
                        isLoading: false,
                        disabled: false
                    })
                })

            }).catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    getReport() {
        if (this.props.type !== 'all_power_data') {
            var id = ""
            for (let index = 0; index < this.props.selectedProject.length; index++) {
                const element = this.props.selectedProject[index];
                if (element.id !== undefined) {
                    if (index === this.props.selectedProject.length - 1) {
                        id += element.id
                    } else {
                        id += element.id + ","
                    }
                }
            }
        }
        let url = ''
        if (this.props.type === 'role_grp') {
            url = `/api/roles/dvsc_report/?role_group=${id}&start=${this.state.startDate}&end=${this.state.endDate}&get_report=true`
        } else if (this.props.type === 'role') {
            url = `/api/roles/dvsc_report/?role=${id}&start=${this.state.startDate}&end=${this.state.endDate}&get_report=true`
        } else if (this.props.type === 'all_power_data') {
            url = `/api/roles/dvsc_report/?all_power_data=${1}&start=${this.state.startDate}&end=${this.state.endDate}&get_report=true`
        }
        else {
            if (this.props.selectedRole.length > 0) {
                let rid = ''

                for (let index = 0; index < this.props.selectedRole.length; index++) {
                    const element = this.props.selectedRole[index];
                    if (element.id !== undefined) {
                        if (index === this.props.selectedRole.length - 1) {
                            rid += element.id
                        } else {
                            rid += element.id + ","
                        }
                    }
                }
                url = `/api/roles/dvsc_report/?project=${id}&role=${rid}&start=${this.state.startDate}&end=${this.state.endDate}&get_report=true`
            } else if (this.props.selectedRoleGroups.length > 0) {

                let rid = ''

                for (let index = 0; index < this.props.selectedRoleGroups.length; index++) {
                    const element = this.props.selectedRoleGroups[index];
                    if (element.id !== undefined) {
                        if (index === this.props.selectedRoleGroups.length - 1) {
                            rid += element.id
                        } else {
                            rid += element.id + ","
                        }
                    }
                }
                url = `/api/roles/dvsc_report/?project=${id}&role_group=${rid}&start=${this.state.startDate}&end=${this.state.endDate}&get_report=true`
            } else {

                url = `/api/roles/dvsc_report/?project=${id}&start=${this.state.startDate}&end=${this.state.endDate}&get_report=true`
            }
        }
        if (this.props.type === 'project') {
            url += `&with_region=${this.props.withRegion}&sectorized=${this.props.withSector}`
        }
        this.setState({ reportButton: true, reportLoading: true })
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ reportButton: false, reportLoading: false })
                this.props.enqueueSnackbar('Report has been sent to your email', {
                    variant: 'info'
                });
            }).catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    dateChange() {
        if (moment(this.state.startDate).isAfter(this.state.endDate)) {
            this.props.enqueueSnackbar('Start Date should be lesser than End Date', {
                variant: 'warning'
            });
        } else {
            this.setState({
                isLoading: true,
                disabled: true
            }, () => {
                // this.getData()
                this.getGraphData()
            })
        }
    }

    componentDidMount() {
        // this.getData()
        this.getGraphData()
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue, }, () => {
            // this.setState({ projectLoading: false, loading: false })
        })
    };

    exportData() {
        this.setState({ reportLoading: true })
        Service.get(`/api/roles/demand_report/?start=${this.state.startDate}&end=${this.state.endDate}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ reportLoading: false })
                this.props.enqueueSnackbar('Report has been sent to your email', {
                    variant: 'info'
                });
            }).catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }    

    render() {
        const handleStartDateChange = (date) => {
            this.setState({ startDate: moment(date).format("YYYY-MM-DD") });
        };
        const handleEndDateChange = (date) => {
            this.setState({ endDate: moment(date).format("YYYY-MM-DD") });
        };

        return (
            <div>
                {this.state.isLoading ? <LoopCircleLoading /> :
                    <div>
                        <Box display='flex' justifyContent='center' alignItems='center'>
                            <Box>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Start Date"
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        style={{ marginLeft: '10px', minWidth: '200px' }}
                                        value={this.state.startDate}
                                        onChange={handleStartDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog2"
                                        label="End Date"
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        minDate={new Date(this.state.startDate)}
                                        minDateMessage={"Start Date should be lesser than End Date"}
                                        style={{ marginLeft: '10px', minWidth: '200px' }}
                                        value={this.state.endDate}
                                        onChange={handleEndDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                            <Box marginLeft={2}>
                                <Button onClick={() => { this.dateChange() }} color='primary' variant='contained' disabled={this.state.disabled}>
                                    submit
                                </Button>
                                <Button style={{ marginLeft: '10px' }} disabled={this.state.reportButton} color='primary' variant='contained' onClick={() => this.getReport()}>Get report</Button>
                                {this.props.type === 'all_power_data' ?
                                    <Button style={{ marginLeft: '10px' }} color='primary' variant='contained' onClick={() =>{this.exportData()}}>Export All Demand</Button> : null}
                            </Box>
                        </Box>
                        <Box>
                            <MaterialTable
                                data={this.state.data}
                                columns={[{ title: 'Roles', field: 'name' }]}
                                // isLoading={this.state.isLoading}
                                options={{ paging: false, showTitle: false }}
                                detailPanel={rowData => {
                                    return (
                                        <Box>
                                            <ToggleButtonGroup
                                                value={this.state.value}
                                                exclusive
                                                size="small"
                                                style={{ padding: '10px' }}
                                                onChange={this.handleChange}
                                            >
                                                <ToggleButton value="1" aria-label="left aligned">
                                                    DvsC Graph
                                                </ToggleButton>
                                                <ToggleButton value="2" aria-label="centered">
                                                    Demand Attrition Graph
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                            {this.state.value === '1' ?
                                                <GraphComponent rowData={rowData} graphData={this.state.data} isLoading={this.state.isLoading} />
                                                : <BarGraphComponent rowData={rowData} />}
                                        </Box>

                                    )
                                }}
                                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                            />
                        </Box>
                    </div>
                }
                <Dialog maxWidth="sm" open={this.state.reportLoading} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <Box m={5} p={2}>
                            <CoffeeLoading />
                        </Box>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(RolesTable);
