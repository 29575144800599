import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { Component } from 'react'
// import ReactApexChart from 'react-apexcharts';
import Chart from "react-apexcharts";
import UnAssignedSchedules from '../UserAllocation/UnAssignedSchedules';
import WorkIcon from '@material-ui/icons/Work';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import AllocateSchedules from '../UserAllocation/AllocateSchedules';
import Service from '../../config/networkutils'
import { withSnackbar } from 'notistack';
import { getCookie } from '../../utils/CookieHelper';

const token = getCookie("ptd_ts_token");

// const token = getCookie("ptd_ts_token");
class PromotionUnassignedSchedules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crm: {},
            unassignedSchedules: [],
            modifiedData: [],
            options: {
                chart: {
                    stacked: false,
                    height: 350,
                    zoom: {
                        enabled: true,
                        type: "x",
                        autoScaleYaxis: true,
                        zoomedArea: {
                            fill: {
                                color: "#90CAF9",
                                opacity: 0.4,
                            },
                            stroke: {
                                color: "#0D47A1",
                                opacity: 0.4,
                                width: 1,
                            },
                        },
                    },
                },
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.55,
                        opacityTo: 0,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: [],
                    labels: {
                        show: true,
                        rotate: -45,
                        rotateAlways: false,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: false,
                        minHeight: undefined,
                        maxHeight: 120,
                        offsetY: 10,
                        style: {
                            colors: [],
                            fontSize: "12px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 400,
                            cssClass: "apexcharts-xaxis-label",
                        },
                    },
                    title: {
                        text: "Weeks",

                        style: {
                            color: undefined,
                            fontSize: "12px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 600,
                            cssClass: "apexcharts-xaxis-title",
                        },
                    },
                },
                yaxis: {
                    show: true,
                    showAlways: true,
                    showForNullSeries: true,

                    labels: {
                        show: true,
                        align: "left",
                        minWidth: 0,
                        maxWidth: 160,
                        offsetX: -10,
                        style: {
                            colors: [],
                            fontSize: "12px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 400,
                            cssClass: "apexcharts-yaxis-label",
                        },
                    },
                },
                title: {
                    text: "Schedules",
                    rotate: -90,

                    style: {
                        color: undefined,
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 600,
                        cssClass: "apexcharts-yaxis-title",
                    },
                },

                stroke: {
                    show: true,
                    curve: "straight",
                    // lineCap: "butt",
                    colors: undefined,
                    width: 3,
                    dashArray: 0,
                },
                grid: {
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                },
            },
            // series: series,
            allWeekDates: [],
            dateLabels: [],
            series: [],
            rows: [],
            dates: {},
            createNew: false,
            unassigned: false,
            data: [],
            isLoading: true,
            selectedRow: [],
            continueDisabled: false,
            alertDialog: false,
            schedules: []
        }
        this.closeDialog = this.closeDialog.bind(this)
        this.closeCreateNewDialog = this.closeCreateNewDialog.bind(this)
        this.getNewSchedule = this.getNewSchedule.bind(this)
        this.getMenu = this.getMenu.bind(this)
    }

    getWeekOfDates = (startDate, endDate) => {
        const dayINeed = 1; // for Monday

        let currDate = moment(startDate).startOf("day");
        let lastDate = moment(endDate).startOf("day");
        let dates = [["Weeks"]];
        while (currDate.diff(lastDate) <= 0) {
            if (currDate.isoWeekday() === dayINeed) {
                dates.push([currDate.format("DD/MM/YYYY")]);
            }
            currDate.add(1, "days");
        }
        return dates;
    };

    graphData() {
        let rows = this.state.rows
        let lowestDate = rows[0].from
        let highestDate = rows[0].to
        for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            if (element.from < lowestDate) {
                lowestDate = element.from
            }
            if (element.to > highestDate) {
                highestDate = element.to
            }
        }
        let allWeekDates = this.getDatesArray(this.getWeekOfDates(lowestDate, highestDate))

        let dates = {}
        for (let i = 0; i < allWeekDates.length; i++) {
            const element = allWeekDates[i];
            dates[element] = 0
        }

        for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            let allWeekDatess = this.getDatesArray(this.getWeekOfDates(element.from, element.to));
            for (let i = 0; i < allWeekDatess.length; i++) {
                const element1 = allWeekDatess[i];
                if (dates.hasOwnProperty(element1)) {
                    dates[element1] = parseFloat(dates[element1]) + (element.hasOwnProperty('updated_qty') ? parseFloat(element.updated_qty) : parseFloat(element.qty))
                }
            }
        }

        let series = []
        let data = []
        let dataLables = []
        for (const [key, value] of Object.entries(dates)) {
            data.push(value)
            dataLables.push(key)
        }
        series.push({
            name: "Total",
            data: data
        })

        this.setState({
            allWeekDates: allWeekDates,
            dateLabels: dataLables,
            series: series,
            options: {
                ...this.state.options,
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: dataLables
                }
            }
        })
    }

    componentDidMount() {
        this.setState({
            data: this.props.unassignedSchedules,
            isLoading: false
        })
    }

    closeDialog() {
        this.setState({
            unassigned: false
        })
    }

    closeCreateNewDialog() {
        this.setState({
            createNew: false
        })
    }

    getNewSchedule(newData) {
        this.setState({ isLoading: true })
        let data = this.state.data
        let index = data.findIndex((element) => element.crm_id === this.state.crm.crm_id)
        let new_data = !data[index].future_demand_selected_data ? [] : data[index].future_demand_selected_data
        data[index].future_demand_selected_data = new_data.concat(newData)
        this.setState({ data: data, createNew: false, isLoading: false, unassigned: false }, () => {
            this.props.enqueueSnackbar('Schedules added successfully.', {
                variant: 'success'
            });
            let rows = []
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if (element?.future_demand_selected_data) {
                    for (let j = 0; j < element.future_demand_selected_data.length; j++) {
                        const element1 = element.future_demand_selected_data[j];
                        element1.crm_id = element.crm_id
                        rows.push(element1)
                    }
                }
            }
            if (rows.length > 0) {
                this.setState({ rows: rows }, () => {
                    this.graphData()
                })
            }
        })
    }

    getMenu() {
        this.setState({
            createNew: true,
            unassigned: false
        })
    }

    continueAction(go) {
        let rows = this.state.rows
        if (this.state.data.length > 0 && rows.length === 0 && go) {
            this.setState({ alertDialog: true })
        } else {
            this.setState({ continueDisabled: true })
            Service.patch(`/api/ptd/employee/promotion/split_shdl_for_future_roles/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    emp_id: this.props.selectedEmp.id,
                    promotion_date: this.props.promotionDate,
                    shdl_data: rows,
                    promote_role: this.props.selectedRole.id,
                }
            })
                .then(res => {
                    this.props.enqueueSnackbar('Promoted successfully!.', {
                        variant: 'success'
                    });
                    if (this.props.closeDialog) {
                        this.props.closeDialog(true)
                    }
                    this.setState({ continueDisabled: false })
                })
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    this.setState({ continueDisabled: false })
                })
        }
    }

    getDatesArray(data) {
        let datesArray = []
        for (let i = 1; i < data.length; i++) {
            const element = data[i];
            datesArray.push(element[0])
        }
        return datesArray
    }

    removeSchedule(index) {
        if (index !== undefined) {
            let data = this.state.data
            let i = data.findIndex((element) => element.crm_id === this.state.crm.crm_id)
            let new_data = !data[i].future_demand_selected_data ? [] : data[i].future_demand_selected_data
            if (new_data.length > 0) {
                new_data.splice(index, 1)
                data[i].future_demand_selected_data = new_data
                this.setState({ data: data, isLoading: true }, () => {
                    this.props.enqueueSnackbar('Schedule removed successfully.', {
                        variant: 'success'
                    });
                    let rows = []
                    for (let i = 0; i < data.length; i++) {
                        const element = data[i];
                        if (element?.future_demand_selected_data) {
                            for (let j = 0; j < element.future_demand_selected_data.length; j++) {
                                const element1 = element.future_demand_selected_data[j];
                                element1.crm_id = element.crm_id
                                rows.push(element1)
                            }
                        }
                    }
                    if (rows.length > 0) {
                        this.setState({ rows: rows, isLoading: false }, () => {
                            this.graphData()
                        })
                    } else {
                        this.setState({ rows: [], isLoading: false })
                    }
                })
            }
        }
    }

    fetchUnassignedSchedules = (data) => {
        Service.get(`/api/ptd/employee/promotion/get_unassinged_schlds_for_future_roles_for_one_crm/?crm_id=${data.crm_id}&promoted_role_id=${this.props.selectedRole.id}&promotion_date=${moment(this.props.promotionDate).format("YYYY-MM-DD")}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.future_demand && res.data.future_demand.length > 0) {
                    for (let i = 0; i < res.data.future_demand.length; i++) {
                        const element = res.data.future_demand[i];
                        element.isSelected = false
                        for (let j = 0; j < element.shdl_data.length; j++) {
                            const element1 = element.shdl_data[j];
                            element1.isSelected = false
                            element1.updated_qty = element1.qty
                        }
                    }
                    this.setState({
                        unassigned: true,
                        rowData: data.future_demand,
                        crm: data,
                        schedules: res.data.future_demand
                    })
                } else {
                    this.setState({
                        createNew: true,
                        crm: data
                    })
                }
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    render() {
        return (
            <Box>
                {this.state.data.length > 0 ?
                    <MaterialTable
                        title={'Unassigned Schedules'}
                        columns={[
                            { title: 'CRM Id', field: 'crm_id' },
                            { title: 'Opportunity Name', field: 'crm_name' },
                        ]}
                        data={this.state.data}
                        isLoading={this.state.isLoading}
                        actions={[
                            {
                                icon: () => <WorkIcon />,
                                tooltip: 'View schedules',
                                onClick: (event, rowData) => {
                                    this.fetchUnassignedSchedules(rowData)
                                }
                            }
                        ]}
                        options={{
                            toolbar: false,
                            paging: false,
                        }}
                        detailPanel={rowData => this.state.isLoading ? <CircularProgress color='primary' /> : (
                            <Box margin={1}>
                                <MaterialTable
                                    title={''}
                                    columns={[
                                        { title: 'From', field: 'from', },
                                        { title: 'To', field: 'to', },
                                        {
                                            title: 'Qty', field: 'qty',
                                            render: rowData => (rowData.hasOwnProperty('updated_qty') ? rowData.updated_qty : rowData.qty)
                                        },
                                    ]}
                                    options={{
                                        toolbar: false,
                                        paging: false,
                                    }}
                                    actions={[
                                        {
                                            icon: 'delete',
                                            tooltip: 'Remove schedule',
                                            onClick: (event, item) => {
                                                this.removeSchedule(item?.tableData?.id)
                                            }
                                        }
                                    ]}
                                    data={!rowData.future_demand_selected_data ? [] : rowData.future_demand_selected_data}
                                    onRowClick={(event, rowData, togglePanel) => { togglePanel() }}
                                />
                            </Box>)
                        }
                    /> :
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'100%'}>
                        Employee is not working on any project
                    </Box>}
                {
                    this.state.rows.length > 0 ?
                        <Box margin={1} width={"100%"}>
                            <Chart
                                options={this.state.options}
                                series={this.state.series}
                                dateLabels={this.state.dateLabels}
                                height={"400px"}
                                width={"1100px"}
                                type={"area"}
                            />
                        </Box> : null
                }

                <Box display={'flex'} flexDirection={'row-reverse'} mt={'10px'}>
                    <Button disabled={this.state.continueDisabled} variant="contained" color="primary" onClick={() => this.continueAction(true)} style={{ marginLeft: '10px' }}>Continue</Button>
                    <Button variant="contained" color="primary" onClick={() => {
                        if (this.props.closeDialog) {
                            this.props.closeDialog(false)
                        }
                    }}>Close</Button>
                </Box>

                <Dialog fullScreen open={this.state.unassigned} >
                    <AppBar >
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.closeDialog} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                UnAssigned Schedules
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Box mt={'80px'}>
                            <UnAssignedSchedules
                                schedules={this.state.schedules}
                                crm={this.state.crm}
                                user={this.props.selectedEmp}
                                isPromotion={this.getNewSchedule}
                                closeDialog={this.closeDialog}
                                getMenu={this.getMenu}
                                promotionDate={this.props.promotionDate}
                                selectedRole={this.props.selectedRole}
                            />
                        </Box>
                    </DialogContent>
                </Dialog>

                <Dialog fullWidth open={this.state.createNew} maxWidth="xs">
                    <DialogContent>
                        <AllocateSchedules
                            crm={this.state.crm}
                            user={this.props.selectedEmp}
                            isPromotion={this.getNewSchedule}
                            closeDialog={this.closeCreateNewDialog}
                        />
                    </DialogContent>
                </Dialog>

                <Dialog fullWidth open={this.state.alertDialog} maxWidth="xs">
                    <DialogTitle>
                        Are you sure! Do you want continue without assigning schedules for the promoted roles in {this.props.selectedEmp ? (this.props.selectedEmp.last_name + ", " + this.props.selectedEmp.first_name + "'s projects") : null} ?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => this.setState({ alertDialog: false })} color="primary">
                            No
                        </Button>
                        <Button onClick={() => this.continueAction(false)} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }
}

export default withSnackbar(PromotionUnassignedSchedules);
