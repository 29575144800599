import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Roles from './Roles';
import RolesGroup from './RolesGroup';
import GangStructures from '../GangStructures/GangStructures';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function RoleTabs(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper square>
            <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="Role Tabs"
                centered
            >
                <Tab label="All Roles" />
                <Tab label="Role Groups" />
                <Tab label="Gangs" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Roles isLoading={props.isLoading} access={props.access} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <RolesGroup isLoading={props.isLoading} access={props.access} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <GangStructures />
            </TabPanel>
        </Paper>
    );
}

export default RoleTabs