import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Service from '../../config/networkutils';
import { withSnackbar } from 'notistack';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { setCookie } from '../../utils/CookieHelper';

const styles = (theme) => ({
    root: {
        background: '#005D99',
        height: '100vh',
        margin: '0'
    },
    cardBox: {
        padding: "80px 152px"
    },
    card: {
        height: '90%'
    },
    image: {
        backgroundImage: 'url(https://images.unsplash.com/photo-1518607692857-bff9babd9d40?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjF9)',
        // backgroundImage: 'url(https://images.unsplash.com/random?work,office)',
        // backgroundImage: 'url(wp2.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '77vh',
        position: 'relative'
    },
    title: {
        color: " #fff",
        /* margin: 50px; */
        position: "absolute",
        top: "30px",
        left: "30px",
        width: "calc(100% - 140px)",
        // maxWidth: '350px',
        background: "#00000052",
        padding: "10px 20px",
        borderRadius: "4px",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    },
    form: {
        marginTop: "0px",
        padding: "20px"
    },
    submit: {
        marginTop: '25px'
    },
    bbImage: {
        width: '100%'
    },
    buttonRoot: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        // margin: theme.spacing(1),
        position: 'relative',
        width: '100%'
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },

    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
})

class Login extends Component {

    constructor() {
        super()
        this.state = {
            wrongData: false,
            failMessage: '',
            loading: false
        }
    }
    componentDidMount() {

    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ wrongData: false })
        const data = new FormData(e.target);
        const dataJson = Object.fromEntries(data);
        const username = dataJson.email.toLowerCase();
        const password = dataJson.password;
        const remember = dataJson.remember_me;

        this.setState({ loading: true })
        Service.post('/rest-auth/login/', {
            data: {
                email: username,
                password: password
            },
        })
            .then(res => {
                // setToken(res.data.key)
                Service.get(`/rest-auth/user/`, {
                    headers: {
                        Authorization: "Token " + res.data.key,
                    },
                })
                    .then(res1 => {

                        if (!res1.data.twof_auth) {
                            if (remember) {
                                setCookie('ptd_ts_token', res.data.key, { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', },);
                            } else {
                                setCookie('ptd_ts_token', res.data.key, { secure: true, 'max-age': 86400, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', },);
                            }
                            setCookie('ptd_ts_user', JSON.stringify(res1.data), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                            setTimeout(() => {
                                window.location.href = '/'
                            }, 100);
                        } else {
                            // setOpen(true)
                        }
                    })
                    .catch(error => {
                        this.props.enqueueSnackbar('Unable to fetch user details. Please try later!', {
                            variant: 'error',
                        });
                    });


            })
            .catch(err => {
                if (!err || !err.status || err.status === 500) {
                    this.props.enqueueSnackbar('Something went wrong. Please try later!', {
                        variant: 'error',
                    });
                    return;
                }
                if (err.status === 400) {
                    let errData = err.data;
                    if (errData.email) {
                        this.props.enqueueSnackbar(errData.email[0], {
                            variant: 'warning',
                        });
                    }
                    if (errData.password) {
                        this.props.enqueueSnackbar(errData.password[0], {
                            variant: 'warning',
                        });
                    }
                    if (errData.non_field_errors) {
                        this.props.enqueueSnackbar(errData.non_field_errors[0], {
                            variant: 'warning',
                        });
                    }
                }

            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    render() {
        const { classes } = this.props;

        const buttonStyle = clsx({
            [classes.buttonSuccess]: this.state.loading,
        })

        return (
            <div className={classes.root}>
                <Box className={classes.cardBox}>
                    <Card className={classes.card}>
                        <Grid container component="main">
                            <Grid item xs={false} sm={4} md={7} className={classes.image} >
                                <div className={classes.title}>
                                    <h1 style={{ margin: '0', fontSize: '34px' }}>
                                        PT&D <br />Resource Management
                                </h1>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                                <form className={classes.form} onSubmit={this.handleSubmit} noValidate>
                                    <img src="bb.png" className={classes.bbImage} alt="Balfour Beatty" />
                                    <h1>Sign In</h1>
                                    <TextField variant="outlined" margin="normal" required fullWidth id="email" label="User Name" name="email" autoComplete="text" autoFocus />
                                    <TextField variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
                                    <FormControlLabel control={<Checkbox value="remember" color="primary" id="remember_me" name="remember_me" />} label="Remember me" />
                                    {this.state.wrongData === true ? (
                                        <p style={{ color: "red", fontSize: 14 }}>
                                            {this.state.failMessage}
                                        </p>
                                    ) : null}
                                    {/* <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Sign In
                            </Button> */}
                                    <div className={classes.buttonRoot}>
                                        <div className={classes.wrapper}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={buttonStyle}
                                                disabled={this.state.loading}
                                                fullWidth
                                            >
                                                Sign In
                                        </Button>
                                            {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                        </div>
                                    </div>
                                    {/* <Box mt={5}>
                                <Copyright />
                            </Box> */}
                                </form>
                            </Grid>
                        </Grid>
                    </Card>
                </Box>
            </div>
        )
    }
}

export default withSnackbar(withRouter(withStyles(styles)(Login)))