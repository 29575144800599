import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from "notistack";
import { LoopCircleLoading } from 'react-loadingg';
import ResourceLocation from './ResourceLocation';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Divider, IconButton, InputAdornment, List, ListItem, ListItemText, Paper, TextField } from '@material-ui/core';
// import Checkbox from '@material-ui/core/Checkbox';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import GetAppIcon from '@material-ui/icons/GetApp';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import XLSX from "xlsx";
import { roles_crm_emp } from '../../../../config/apiList';
import { getCookie } from '../../../../utils/CookieHelper';
import Service from '../../../../config/networkutils';
import { groupByField } from '../../../../utils/reuseFunctions';
import { weekStartDates } from '../../../../utils/betweenDates';
import moment from 'moment';
import { getSecondaryColor } from '../../../Helper/CommonThings';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

const token = getCookie("ptd_ts_token");

const useStyle = (theme) => ({
    headRow: {
        backgroundColor: "#D5E1E5",
        // minWidth: '100%'
    },
    selectedRow: {
        backgroundColor: "#EDFAFF",
    },
})

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

class GFs extends React.Component {
    state = {
        gfs: this.props.gfs,
        gfsCopy: this.props.gfsCopy,
        showGFAdd: false,
        isLoading: false,
        allOHLEmp: [],
        selectedRoles: [],
        selectedCrms: [],
        selectedEmployees: [],
        getReport: false,
        allGangmen: [],
        search: ''
    }

    // ohlroles = [
    //     { 58: "General Foreman" },
    //     { 171: "Apprentice General Foreman" },
    //     { 124: "Senior General Foreman" },
    //     { 45: "Drivers" },
    //     { 138: "Storeman" },
    //     { 196: "General Operative" },
    //     { 203: "Foreman 1" },
    //     { 204: "Foreman 2" },
    //     { 205: "LE1" },
    //     { 206: "LE2" },
    //     { 207: "LH" },
    //     { 208: "Apprentice Linesman" },
    //     { 209: "Trainee Linesman" },
    //     { 210: "SPO" },
    // ]

    getEmpFomattedLookupObj(arr) {
        let obj = {}
        arr.forEach(ele => {
            obj = { ...obj, ...{ [ele.id]: `${ele.first_name} ${ele.last_name}` } }
        })
        return obj
    }
    getFomattedLookupObj(arr) {
        let obj = {}
        arr.forEach(ele => {
            obj = { ...obj, ...{ [ele.id]: ele.name } }
        })
        return obj
    }
    getPRPR(empIDSArray) {
        return new Promise((resolve, reject) => {

            Service.get(roles_crm_emp + `?is_archive=0&emp=${empIDSArray.join()}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => {
                resolve(res.data)
            }
            ).catch(error => {
                reject(error)
            }
            )
        })
    }

    gfRoleId = null
    ohlroles = null

    componentDidMount() {

        

    }
    handleRoleChange = (e, value) => {
        let searchParams = new URLSearchParams(window.location.search)
        if (value.length > 0) {
            let selectedRolesIds = [];
            value.forEach((region) => {
                selectedRolesIds.push(region.id)
            })
            if (searchParams.has("role")) {
                searchParams.set("role", selectedRolesIds.join(","))
            } else {
                searchParams.append("role", selectedRolesIds.join(","))
            }

        } else if (value.length === 0) {
            searchParams.delete("role")

        }
        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedRoles: value, reportedRoles: value, getReport: false })
    }
    handleCrmChange(e, value) {
        let searchParams = new URLSearchParams(window.location.search)
        if (value) {
            // let selectedCrmIds = [];
            // value.forEach((crm) => {
            //     selectedCrmIds.push(crm.id)
            // })
            if (searchParams.has("crm")) {
                searchParams.set("crm", value.id)
            } else {
                searchParams.append("crm", value.id)
            }

        } else {

            searchParams.delete("crm")
        }

        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedCrms: value, getReport: false })
    }

    handleEmployeeChange(e, value) {
        let searchParams = new URLSearchParams(window.location.search)
        if (value) {
            // let selectedCrmIds = [];
            // value.forEach((crm) => {
            //     selectedCrmIds.push(crm.id)
            // })
            if (searchParams.has("employee")) {
                searchParams.set("employee", value.id)
            } else {
                searchParams.append("employee", value.id)
            }

        } else {

            searchParams.delete("employee")
        }

        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedEmployees: value, getReport: false })

    }

    handleGangStructureDownload(allGfs, ohlEempObj, allGangEmps, allGangmen, gfs) {

        let allGangEmpsIds = []
        allGangEmps.forEach(gangman => {
            allGangEmpsIds.push(gangman.id)
        })

        let allGfIds = []
        allGfs.forEach(gf => {
            allGfIds.push(gf.id)
        })

        const gfGroups = groupByField(allGangmen, 'general_foreman')
        let gangManCrmGroup = []
        // let gfCrmGroup = []

        let gfEmpIdConvertedObj = {}

        gfs.forEach(gf => {

            for (let groupIndex = 0; groupIndex < Object.keys(gfGroups).length; groupIndex++) {
                const gfGroup = Object.keys(gfGroups)[groupIndex];

                if (gf.id === gfGroups[gfGroup][0].general_foreman) {

                    gfEmpIdConvertedObj = { ...gfEmpIdConvertedObj, [gf.ptd_employee]: [...gfGroups[gfGroup]] }
                }
            }

        })
        // for (let groupIndex = 0; groupIndex < Object.keys(gfGroups).length; groupIndex++) {
        //     const gfGroup = Object.keys(gfGroups)[groupIndex];

        //         if (gf.id === gfGroups[gfGroup][0].general_foreman) {

        //             gfEmpIdConvertedObj = { ...gfEmpIdConvertedObj, [gf.ptd_employee]: [...gfGroups[gfGroup]] }
        //         }
        //     }

        let allWeekDates = weekStartDates(moment().subtract(6, 'months').startOf('month'), moment().add(18, 'months').endOf('month'));

        let headerArray = ["EmployeeNo", 'EmployeeName', "Role", ...allWeekDates]

        const gangStructureWorkbook = XLSX.utils.book_new();


        Promise.all([this.getPRPR(allGangEmpsIds), this.getPRPR(allGangEmpsIds)]).then((res) => {

            gangManCrmGroup = groupByField(res[0], 'emp_id')
            // gfCrmGroup = groupByField(res[1], 'emp_id')

            for (let gfIndex = 0; gfIndex < gfs.length; gfIndex++) {
                const gf = gfs[gfIndex];
                let gfSheetArray = [headerArray]
                for (let gangManIndex = 0; gangManIndex < gfEmpIdConvertedObj[gf.ptd_employee].length; gangManIndex++) {
                    const gangman = gfEmpIdConvertedObj[gf.ptd_employee][gangManIndex];

                    let gangManRow = [gangman.ptd_employee_data.emp_no, `${gangman.ptd_employee_data.first_name} ${gangman.ptd_employee_data.last_name}`, this.state.rolesObj[gangman.ptd_employee_data.job_title]]

                    if (gangManCrmGroup.hasOwnProperty(gangman.ptd_employee)) {
                        for (let gangManCrmIndex = 0; gangManCrmIndex < gangManCrmGroup[gangman.ptd_employee].length; gangManCrmIndex++) {
                            const gangManCrm = gangManCrmGroup[gangman.ptd_employee][gangManCrmIndex];

                            for (let m = 0; m < allWeekDates.length; m++) {
                                let date = allWeekDates[m];

                                if (gangManCrm.dates) {
                                    if (gangManCrm.dates.hasOwnProperty(date) && gangManCrm.dates[date] !== 0) {


                                        if (gangManRow.indexOf(date) === -1) {

                                            gangManRow = [...gangManRow, gangManCrm.crm_opportunity_id]

                                        } else {
                                            // If already a project in the week then concatenate the date
                                            gangManRow[date] = [...gangManRow, " "]
                                        }
                                    }
                                }


                            }
                        }
                    }

                    gfSheetArray.push(gangManRow)
                }
                var worksheet = XLSX.utils.aoa_to_sheet(gfSheetArray)
                XLSX.utils.book_append_sheet(gangStructureWorkbook, worksheet, ohlEempObj[gf.ptd_employee]);
            }
            XLSX.writeFile(gangStructureWorkbook, "gangStructure.xlsx");

        }).catch((error) => {
            console.log(error)
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }


    handleClick(value, name) {
        this.props.data(value)
        this.props.name(name)
    }

    search(data) {
        this.setState({
            search: data
        })
        const data_lowercase = data.toLowerCase()
        var search_words = data_lowercase.split(" ");
        search_words = search_words.filter(n => n)
        var filtered_data = []
        if (search_words.length === 0) {
            filtered_data = this.state.gfsCopy
        }

        for (let i = 0; i < this.state.gfsCopy.length; i++) {
            const element = this.state.gfsCopy[i];
            var num_of_passed = false
            for (let j = 0; j < search_words.length; j++) {
                const el = search_words[j];
                // this.state.ohlEempObj[value.ptd_employee]
                num_of_passed = this.props.ohlEempObj[element.ptd_employee].toLowerCase().includes(el)
                if (!num_of_passed) {
                    break
                }
            }
            if (num_of_passed) {
                filtered_data.push(element)
            }
        }
        this.setState({ gfs: filtered_data })
        // if(data.length > 0){
        //     this.setState({
        //         showLiveAll : true
        //     })
        // }
    }




    render() {
        return (
            <>
                {
                    this.state.isLoading ? <LoopCircleLoading color='#005D99' /> :
                        <>
                            <div>
                                <Box>
                                    <Box display='flex'  justifyContent={'center'} alignItems={'center'}>
                                        <TextField
                                            variant='outlined'
                                            placeholder='Search'
                                            size="small"
                                            value={this.state.search}
                                            onChange={(event) => this.search(event.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        {this.state.search.length === 0 ?
                                                            <SearchIcon /> :
                                                            <IconButton
                                                                onClick={() => this.setState({
                                                                    gfs: this.props.gfsCopy,
                                                                    search: ''
                                                                },)}
                                                            >
                                                                <CloseIcon fontSize="small" />
                                                            </IconButton>}
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box marginTop={2}>
                                    <Paper elevation={3} variant={"outlined"}>
                                        {/* <Grid container justifyContent="center" spacing={2} style={{ marginBottom: '20px' }}>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        multiple
                                        id="Crm"
                                        options={this.state.allElectricityOHLTcRMs}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => this.handleCrmChange(e, value)}
                                        value={this.state.selectedCrms}

                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (

                                            <TextField {...params} variant="outlined" label="Crm" placeholder="Crm" />
                                        )}
                                    />

                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete

                                        multiple
                                        id="Employee"
                                        options={this.state.allOHLEmps}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                                        onChange={(e, value) => this.handleEmployeeChange(e, value)}
                                        value={this.state.selectedEmployees}

                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.first_name + ' ' + option.last_name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (

                                            <TextField {...params} variant="outlined" label="Employee" placeholder="Employee" />
                                        )}
                                    />

                                </Grid>

                                <Grid item xs={3}>
                                    <Button variant="outlined" color="primary" onClick={() => {
                                        // this.setState({ showRole: false, showRoleIndex: null })
                                        this.setState({ getReport: true })
                                    }
                                    }>Get Report</Button>
                                </Grid>
                            </Grid> */}
                                        {this.state.selectedEmployees.length > 0 || this.state.selectedCrms.length > 0 ? this.state.getReport ?
                                            <ResourceLocation selectedCrms={this.state.selectedCrms} selectedEmployees={this.state.selectedEmployees} /> : null :
                                            <>
                                                {/* <MaterialTable
                                    title={<Grid container direction={"row"} justifyContent='space-between' alignItems='center'>
                                        <Grid item><div style={{ padding: '15px' }}> <Typography variant="h6">{"General foremen and Gangs"}</Typography></div></Grid>
                                        <Grid item><Tooltip title='Download Gang Structures'><Button
                                            variant="contained"
                                            onClick={() => this.handleGangStructureDownload(this.state.allGfs, this.state.ohlEempObj, this.state.allGangEmps, this.state.allGangmen, this.state.gfs)}
                                            style={{ float: 'right', marginTop: '20px' }}
                                        // size={'small'}

                                        ><GetAppIcon />  XLSX</Button></Tooltip></Grid>
                                    </Grid>}
                                    columns={[{ title: 'General Foremen', field: 'ptd_employee', lookup: this.state.ohlEempObj }]}
                                    data={this.state.gfs}
                                    options={{
                                        search: true,
                                        paging: false,
                                        pageSize: 10,
                                        pageSizeOptions: [10, 20, 50, 100],
                                        headerStyle: {
                                            fontWeight: 'bold'
                                        },
                                        // fixedColumns: {
                                        //     left: 1,
                                        //     right: 0
                                        // },
                                        // exportButton: { csv: true, pdf: false },
                                        // filtering: true
                                    }}
                                    onRowClick={(event, rowData, togglePanel) => { event.preventDefault(); togglePanel() }}
                                    detailPanel={rowData => {
                                        let rowGangmen = this.state.allGangmen.filter(gm => gm.general_foreman === rowData.id)
                                        let unAllocatedGngmn = [...this.state.allGangEmps];
                                        if (this.state.allGangmen.length > 0) {
                                            for (let gfEmpIndex = 0; gfEmpIndex < this.state.allGangmen.length; gfEmpIndex++) {
                                                let filteredGfNotAllocatedGmn = unAllocatedGngmn.filter(emp => emp.id !== this.state.allGangmen[gfEmpIndex].ptd_employee)
                                                unAllocatedGngmn = [...filteredGfNotAllocatedGmn]
                                            }
                                        } else {
                                            unAllocatedGngmn = this.state.allGangEmps;
                                        }
                                        return (<ResourceLocation
                                            ohlEmpObj={this.state.ohlEempObj}
                                            allOHLEmp={this.state.allGangEmps}
                                            sector={this.state.sector}
                                            owner_delivery_unit={this.state.owner_delivery_unit}
                                            owner_region={this.state.owner_region}
                                            gf={rowData}
                                            rolesObj={this.state.rolesObj}
                                            gangEmployees={rowGangmen}
                                            id={rowData.ptd_employee} />
                                        )
                                    }}
                                /> */}
                                    <div>
                                        {this.props.gfs.length === 0 ?
                                            <Box margin={1} display='flex' justifyContent='center' alignItems='center' fontSize='large'>
                                                No Such Members are Present
                                            </Box> :

                                                <List dense={true}>
                                                    {this.props.gfs.map((value, index) => {
                                                        return (
                                                            <div>
                                                                <ListItem onClick={() => this.handleClick(value, this.props.ohlEempObj[value.ptd_employee])} button>
                                                                    <ListItemText style={{ padding: "10px" }}
                                                                        primary={this.props.ohlEempObj[value.ptd_employee]}
                                                                        secondary={null}
                                                                    />
                                                                </ListItem>
                                                                <div>
                                                                    {index !== this.state.gfs.length - 1 ?
                                                                        <Divider variant="middle" style={{ backgroundColor: getSecondaryColor() }} /> : null}
                                                                </div>
                                                            </div>)
                                                    })}
                                                </List>}
                                            </div>
                                            </>
                                        }
                                    </Paper>
                                </Box>
                            </div>
                        </>

                }
            </>
        );
    }
}

export default withSnackbar(withStyles(useStyle)(GFs));