import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import { getCookie } from '../../utils/CookieHelper';
import Service from '../../config/networkutils'
import OverwriteSchedules from './OverwriteSchedules';

const token = getCookie("ptd_ts_token");



class AllocateSchedules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addData: [{
                from: moment().weekday(1).format('YYYY-MM-DD'),
                to: moment().weekday(1).add(1, 'week').format('YYYY-MM-DD'),
                qty: 0,
            }],
            addBtnDisabled: false,
            columns: [],
            existingAllocationData: []
        }
    }

    handleCustomToDate = (date, i) => {
        if (moment(date).format('dddd') === 'Monday') {
            let data = this.state.addData
            data[i]['to'] = moment(date).format('YYYY-MM-DD')
            this.setState({ addData: data })
        } else {
            this.props.enqueueSnackbar('Please select Monday as To date', {
                variant: 'warning'
            });
        }
    }

    handleCustomFromDate = (date, i) => {
        if (moment(date).format('dddd') === 'Monday') {
            let data = this.state.addData
            data[i]['from'] = moment(date).format('YYYY-MM-DD')
            this.setState({ addData: data })
        } else {
            this.props.enqueueSnackbar('Please select Monday as From date', {
                variant: 'warning'
            });
        }
    }

    handleCustomQty = (e, i) => {
        let data = this.state.addData
        data[i]['qty'] = e.target.value
        this.setState({ addData: data })
    }

    addQty = () => {
        let data = this.state.addData
        data.push({
            from: moment().weekday(1).format('YYYY-MM-DD'),
            to: moment().weekday(1).add(1, 'week').format('YYYY-MM-DD'),
            qty: 0,
        })
        this.setState({ addData: data })
    }

    removeQty = (i) => {
        let data = this.state.addData
        data.splice(i, 1)
        this.setState({ addData: data })
    }

    backToMainMenu() {
        if (this.props.getMenu) {
            this.props.getMenu('main-menu')
        }
    }

    addSchedule(value) {
        this.setState({ addBtnDisabled: true })

        var addData = this.state.addData
        for (let i = 0; i < addData.length; i++) {
            const element = addData[i];
            if (!element.from || !element.to) {
                this.props.enqueueSnackbar('Please select From and To date', {
                    variant: 'warning'
                });
                this.setState({ addBtnDisabled: false })
                return
            } else if (element.qty <= 0) {
                this.props.enqueueSnackbar('Qty should be greater than 0', {
                    variant: 'warning'
                });
                this.setState({ addBtnDisabled: false })
                return
            }
        }

        let data = {
            "crm": this.props.crm.crm_id,
            "emp": this.props.user.id,
            "overwrite": value,
            "shdl_data": this.state.addData
        }
        Service.post('/api/prp/rolenew/allocate_user_to_staff_location/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        })
            .then(res => {
                if (this.props.closeDialog) {
                    this.props.closeDialog()
                }
                this.props.enqueueSnackbar('Employee added successfully', {
                    variant: 'success'
                });
                this.setState({ addBtnDisabled: false })
            })
            .catch(error => {
                if (error && error.status === 302) {
                    this.setState({ existingAllocationData: error.data, confirmationDialog: true })
                }
                else if (error && error.status === 406) {
                    this.props.enqueueSnackbar(error.data.message, {
                        variant: 'warning'
                    });
                } else {
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    })
                }
                this.setState({ addBtnDisabled: false })
                console.log(error)
            })

    }

    addPromoteSchedule() {
        var addData = this.state.addData
        for (let i = 0; i < addData.length; i++) {
            const element = addData[i];
            if (!element.from || !element.to) {
                this.props.enqueueSnackbar('Please select From and To date', {
                    variant: 'warning'
                });
                this.setState({ addBtnDisabled: false })
                return
            } else if (element.qty <= 0) {
                this.props.enqueueSnackbar('Qty should be greater than 0', {
                    variant: 'warning'
                });
                this.setState({ addBtnDisabled: false })
                return
            }
        }

        if (this.props.isPromotion) {
            this.props.isPromotion(this.state.addData)
        }
    }

    render() {
        return (
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} >

                <Box display={'flex'} flexDirection={'row'} width={'100%'} >
                    <h3>{this.props.crm.crm_name + " --> " + this.props.user.last_name + ", " + this.props.user.first_name + " (" + this.props.user.role_name + ")"}</h3>
                </Box>
                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                    <Box display={'flex'} flexDirection={'row'} width={'100%'} >
                        <Tooltip title="Add Schedule">
                            <IconButton size='small' onClick={() => this.addQty()}>
                                <AddCircleOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {
                        this.state.addData.map((element, i) =>
                            <Box alignItems='center' display='flex' flexDirection='row' justifyContent={'center'} m={1}>
                                <Box marginRight={'10px'}>
                                    <Tooltip title="Remove Schedule">
                                        <IconButton size='small' onClick={() => this.removeQty(i)} disabled={this.state.addData.length === 1}>
                                            <RemoveCircleOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Box marginRight={'10px'}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id={'from'}
                                            label="From"
                                            value={element.from ? element.from : undefined}
                                            onChange={(e) => this.handleCustomFromDate(e, i)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>
                                <Box marginRight={'10px'}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id={'to'}
                                            label="To"
                                            value={element.to ? element.to : undefined}
                                            onChange={(e) => this.handleCustomToDate(e, i)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>
                                <Box>
                                    <TextField
                                        id={'qty'}
                                        label="Qty"
                                        type="number"
                                        style={{ width: 60 }}
                                        margin="dense"
                                        value={element.qty}
                                        onChange={(e) => this.handleCustomQty(e, i)}
                                    />
                                </Box>
                            </Box>
                        )}
                </Box>

                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'100%'} m={1}>
                    {((this.props.allocationData?.prj) || (this.props.isPromotion)) ? null :
                        < Button size='small' variant="contained" color="primary" onClick={() => this.backToMainMenu()} style={{ marginRight: "10px" }}>
                            Back
                        </Button>}
                    {
                        this.props.isPromotion ?
                            <Button style={{ marginRight: "10px" }} size='small' variant="contained" color="primary" onClick={() => { if (this.props.closeDialog) { this.props.closeDialog() } }}>
                                Cancel
                            </Button> : null
                    }
                    <Button size='small' variant="contained" color="primary" disabled={this.state.addBtnDisabled} onClick={() => {
                        if (this.props.isPromotion) {
                            this.addPromoteSchedule()
                        } else {
                            this.addSchedule(false)
                        }
                    }}>
                        Add
                    </Button>
                </Box>
                <Dialog maxWidth="lg" open={this.state.confirmationDialog} onClose={() => this.setState({ confirmationDialog: false })}>
                    <DialogTitle>Are you sure? Do you want to overwrite the existing schedule?</DialogTitle>
                    <DialogContent>
                        <OverwriteSchedules firstname={this.props.user.first_name} lastname={this.props.user.last_name}  errorData={this.state.existingAllocationData} />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={this.state.addBtnDisabled}  onClick={() => this.addSchedule(true)} color="primary"  size='small' variant='contained'>
                            Proceed
                        </Button>
                        <Button onClick={() => this.setState({ confirmationDialog: false })} color="primary"  size='small' variant='contained'>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box >
        );
    }
}

export default withSnackbar(AllocateSchedules);