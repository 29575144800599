import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from '../utils/authenticate';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        Auth.isAuthenticated()  && Auth.user()
            ? <Component {...props} />
            : <Redirect to='/login' />
    )} />
)

export default PrivateRoute;