import React, { Component } from 'react'
import WeeklyProjectResourceGraph from './WeeklyProjectResourceGraph';
import NoneWorkingDaily from './NoneWorkingDaily';
import { Card, Grid } from '@material-ui/core';
import GangsGraph from './GangsGraph';
import SubContractorsGraph from './SubContractorsGraph';
import OverallDvsCGraph from './OverallDvsCGraph';

class GangDashboardGraph extends Component {
    render() {
        return (
            <div>
                {/* <Box margin={2} > */}
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <Card elevation={3} style={{ padding: 1 }}>
                            <WeeklyProjectResourceGraph gang={this.props.gang} />
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card elevation={3} style={{ padding: 1 }}>
                            <NoneWorkingDaily gang={this.props.gang} />
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card elevation={3} style={{ padding: 1 }}>
                            <SubContractorsGraph gang={this.props.gang} />
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Card elevation={3}>
                            <OverallDvsCGraph gang={this.props.gang} />
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card elevation={3} style={{ padding: 1 }}>
                            <GangsGraph gang={this.props.gang} />
                        </Card>
                    </Grid>
                </Grid>
                {/* </Box> */}
            </div>
        )
    }
}

export default GangDashboardGraph;
