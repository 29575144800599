import { Box, Divider, Paper, Typography } from '@material-ui/core';
import React, { Component } from 'react'
import { getPrimaryColor, getSecondaryColor } from '../../Helper/CommonThings';
import moment from 'moment';
import { getCookie } from '../../../utils/CookieHelper';
import Crm from '../Projects/Crm';
import Reports from '../Projects/Reports';
import CombinedResources from '../Projects/CombinedResources';

const user = JSON.parse(getCookie("ptd_ts_user"))


class Introduction extends Component {


    constructor(props) {
        super(props);

        this.state = {
            quotes: [],
            options: {
                chart: {
                    id: "basic-bar"
                },
                fill: {
                    colors: [getSecondaryColor()]
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
                },
                dataLabels: {
                    style: {
                        colors: ['#FFF']
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                }
            },
            series: [
                {
                    name: "series-1",
                    data: [30, 40, 45, 50, 49, 60, 70, 91]
                }
            ]
        }
    }

    screenSizeCheck() {
        if (window.innerWidth > 1600) {
            return true
        } else {
            return false
        }
    }

    componentDidMount() {
        // var startDate = moment().startOf('year').format('DD-MM-YYYY');
        // var endDate = moment().endOf('year').format('DD-MM-YYYY');
        var time = moment().format('HH');
        var morningQuotes = ['Rise and shine!', 'Top of the morning to you!', 'Good day to you!', 'Have a great day!', 'Hello there!', 'Morning!', 'Greetings!', 'Good morning sunshine!', 'What a lovely morning!', "Rise n’ shine!", " Very great morning!"]
        var afternoonQuotes = ['Have a wonderful afternoon!', 'Splendid noon!', 'Marvelous afternoon!', 'Have a glorious afternoon!', 'Have a astonishing noon!', 'Golden afternoons!', 'Hope your noon is wonderful!', 'Have a happy afternoon!', 'Bright as the afternoon sun!', 'Fun-filled afternoon!', 'Brightener noon!', 'Wishing you a splendid afternoon!', 'Here’s a wish for a fun afternoon!', 'May your noon be blessed!', 'Brighter noon!']
        var eveningQuotes = ['Have a great evening!', 'Have a wonderful evening!', 'Enjoy your evening!', 'Wishing you a fabulous evening!', 'Have an amazing evening!', 'Enjoy your evening to the fullest!', 'Wishing you the best day ever!']

        if (time < 12) {
            var morning_quotes = morningQuotes[moment().format('ss') % morningQuotes.length];
            this.setState({ quotes: morning_quotes })
        } else if (time < 16) {
            var afternoon_quotes = afternoonQuotes[moment().format('ss') % afternoonQuotes.length];
            this.setState({ quotes: afternoon_quotes })
        } else if (time >= 16) {
            var evening_quotes = eveningQuotes[moment().format('ss') % eveningQuotes.length];
            this.setState({ quotes: evening_quotes })
        }
    }


    render() {
        return (
            <div>
                <Paper style={{ border: "2px solid " + getPrimaryColor(), borderRadius: '10px' }} elevation={3}>
                    <Box p={2} style={{ fontWeight: 'bold' }}>
                        <Typography style={{ fontSize: this.screenSizeCheck() ? 'large': 'small' }}>Dear {user.first_name + " " + user.last_name}</Typography>
                        <Typography style={{ fontSize: this.screenSizeCheck() ? 'large': 'small' }}>{this.state.quotes}</Typography>
                    </Box>
                </Paper>
                <Box mt={2}>
                {/* <Paper style={{ height:window.innerHeight - 205 ,border: "2px solid " + getPrimaryColor(), borderRadius: '10px' ,position:'relative'}} elevation={3} > */}
                    <Box >
                        {/* <Box paddingTop={1} style={{ color: getSecondaryColor() }}>CHARTS</Box> */}
                        <Box>
                        <Paper style={{ border: "2px solid " + getPrimaryColor(), borderRadius: '10px' }} elevation={3}>
                            <Box p={this.screenSizeCheck() ? 2: 1} style={{  color: getSecondaryColor(),fontSize: this.screenSizeCheck() ? 'medium': 'small' }}>CRM</Box>
                            <Box>
                                <Divider orientation='horizontal' style={{ backgroundColor: getSecondaryColor(), borderBottomWidth: "20px" }} />
                            </Box>
                            <Crm access={this.props.access} />
                        </Paper>
                        <Box marginTop={2}>
                        <Paper style={{ border: "2px solid " + getPrimaryColor(), borderRadius: '10px' }} elevation={3}>
                            <Box p={this.screenSizeCheck() ? 2: 1} style={{  color: getSecondaryColor(),fontSize: this.screenSizeCheck() ? 'medium': 'small' }}>REPORTS</Box>
                            <Box>
                                <Divider orientation='horizontal' style={{ backgroundColor: getSecondaryColor() }} />
                            </Box>
                            <Reports access={this.props.access} />
                        </Paper>
                        </Box>
                        <CombinedResources access={this.props.access} />
                        <Box>
                            
                        </Box>

                            {/* <AllPowerDataGraph/> */}
                            {/* <Chart
                                options={this.state.options}
                                series={this.state.series}
                                type="bar"
                                width={this.screenSizeCheck() ? '400' : '270'}
                            /> */}
                        </Box>
                    </Box>
                {/* </Paper> */}
                </Box>
                {/* <Box style={{ height: this.screenSizeCheck() ? window.innerHeight - 565 : window.innerHeight - 482 }}>
                    <Tabs style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, padding: 0 }}>
                        <Paper style={{ margin: 0, padding: 0, borderRadius: "10px 10px 0 0", border: "2px solid " + getPrimaryColor(), borderBottom: 'none' }}>
                            <Tab label="Views" style={{ color: getSecondaryColor() }} />
                        </Paper>
                    </Tabs>
                    <Paper style={{ height: '100%', borderRadius: "0 10px 10px 10px", border: "2px solid " + getPrimaryColor() }} elevation={3}>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height="100%">
                            Coming Soon ...
                        </Box>
                    </Paper>
                </Box> */}
            </div>
        )
    }
}

export default Introduction;