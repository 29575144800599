import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

function Dropzone(props) {
    const { open, onChange, onClose, preview } = props
    return (
        <Dialog open={open} onClose={onClose} maxWidth={"lg"}>
            <DialogTitle id="form-dialog-title">
                Upload file
                <IconButton aria-label="close" onClick={onClose} style={{ position: 'absolute', top: '5px', right: '20px' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DropzoneArea
                    acceptedFiles={['.xls', '.xlsx']}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    useChipsForPreview
                    onChange={(e) => onChange(e)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Cancel</Button>
                <Button color="primary" onClick={preview}>Preview</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Dropzone;