import React, { Component } from 'react'
import RolesTable from '../DvsCReports.js/RolesTable';

 class FilterbyAllPowerData extends Component {
  render() {
    return (
      <div><RolesTable type={'all_power_data'}/></div>
    )
  }
}

export default FilterbyAllPowerData;
