import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Service from '../../config/networkutils';
import { withSnackbar } from 'notistack';
import { bid_res_api, crm_api, users_api } from '../../config/apiList';
import { RotateCircleLoading } from 'react-loadingg';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import { getCookie } from '../../utils/CookieHelper';

const token = getCookie("ptd_ts_token");

const useStyles = (theme) => ({
    root: {
        '& > *': {
            width: '100%',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    date: {
        margin: 0,
        width: '100%',
        'margin-top': '5px'
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class BIDRRForm extends React.Component {
    constructor() {
        super()
        this.state = {
            project_title: '',
            isLoading: false,
            data: {},
            opportunity_id: "",
            nominates_lead: "",
            date: undefined,
            from_date: undefined,
            operation: "",
            bid_estimate_lead: "",
            contract_manager: "",
            current_stage: "",
            engineering_pdm: "",
            temp_work_designer: "",
            planner: "",
            commercial: "",
            shwsq: "",
            procurment: "",
            estimating: "",
            client: "",
            project_framework: "",
            crm_no: "",
            project_category: "",
            decipline: null,
            brief_scope_outline: "",
            construction_period: "",
            estimated_value: null,
            link_to_tender_docs: "",
            eoi_iit_received: null,
            keep_up_meeting: null,
            site_visit: null,
            stage_gate_2: null,
            stage_gate_3: null,
            contractor_proposal_submission: null,
            contract_award: null,
            contract_completion: null,
            remarks: "",
            clientNames: [],
            disableForm: false,
            refreshCRM: false,
            employeesList: []
        }
    }

    componentDidMount() {
        let proj = this.props.project
        let access = this.props.access
        this.setState({
            disableForm: proj.gate_level_acheived === 4 || (!access.l1a || !access.l1a.l1a_f) ? true : false,
            current_stage: proj.gate_level_acheived,
            project_title: proj.name,
            crm_no: proj.opportunity_id,
            opportunity_id: proj.opportunity_id,
            eoi_ott_received: proj.itt_due_date,
            contractor_proposal_submission: proj.tender_submit_date,
            contract_award: proj.contractor_start_date,
            contract_completion: proj.contractor_end_date,
            client: proj.account_name,
            estimated_value: proj.bb_project_value,
            clientNames: this.props.clientNames
        }, () => {
            if (proj.bid_resource) {
                this.fetchFormAndEmpData(proj.bid_resource)
            }
            else {
                this.getEmployeeList()
            }
        })

    }

    fetchFormAndEmpData(bidRRid) {
        this.setState({ isLoading: true })
        Promise.all([
            this.fetchFormData(bidRRid),
            this.fetchEmployeeData()
        ])
            .then(res => {
                this.setState({ employeesList: res[1] }, () => {
                    this.setState((prevData) => {
                        return { ...prevData, ...res[0] }
                    }, () => {
                        this.setState({ isLoading: false })
                    })
                })

            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
                this.setState({ isLoading: false })
            })
    }


    fetchFormData(id) {
        return new Promise((resolve, reject) => {
            Service.get(bid_res_api + id + "/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    fetchEmployeeData() {
        return new Promise((resolve, reject) => {
            Service.get(users_api, {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }


    getEmployeeList() {
        this.setState({ isLoading: true })
        this.fetchEmployeeData()
            .then(res => {

                let resData = res.data;
                this.setState({ employeesList: resData })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState({ isLoading: false })
                }, 1000);
            });
    }

    // fillFormData
    handleChange = (e) => {
        let { id, value } = e.target;
        this.setState({ [id]: value })
    }

    setAutoCompleteInput(id, value) {
        this.setState({ [id]: value })
    }

    submitForm = () => {
        this.setState({ isLoading: true })
        Service.post(bid_res_api, {
            headers: {
                Authorization: "Token " + token,
            },
            data: this.state,
        })
            .then(res => {
                // this.setState({ isLoading: false, })
                // this.props.enqueueSnackbar('Client added successfully', {
                //     variant: 'success'
                // });
                this.updateCRMApi(res.data.id)
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    updateForm = () => {
        this.setState({ isLoading: true })
        Service.put(bid_res_api + this.props.project.bid_resource + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: this.state,
        })
            .then(res => {
                // this.setState({ isLoading: false, })
                // this.props.enqueueSnackbar('Client added successfully', {
                //     variant: 'success'
                // });
                this.updateCRMApi(res.data.id)
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    updateCRMApi = (bid_res_id) => {
        Service.put(crm_api + this.props.project.id, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                bid_resource: bid_res_id,
                name: this.state.project_title,
                opportunity_id: this.state.opportunity_id,
                gate_level_acheived: this.state.current_stage
            },
        })
            .then(res => {
                this.setState({ isLoading: false, refreshCRM: true })
                // this.getCRMTableData()
                this.props.enqueueSnackbar('BID RR Form updated', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    render() {
        const date_margin = {
            Margin: 0,
            Width: '100%'
        }
        const proj = this.props.project;
        const clients = this.props.clientNames;
        const { open, onClose } = this.props
        return (<>

            <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>

                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => onClose(this.state.refreshCRM)} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">
                            BID RR FORM - {proj.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {this.state.isLoading ? <RotateCircleLoading color="#005D99" /> :
                    <div style={{ padding: '25px' }}>
                        {this.state.current_stage === 4 ? <h3 style={{ color: '#FF9800' }}>Requests can not be edited at <b>GATE 4</b> stage</h3> : null}
                        <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <h3>Request For Tender Support</h3>
                            </Grid>


                            <Grid item xs={12} sm={4} md={4}>
                                <TextField
                                    id="nominates_lead"
                                    label="Nominated Lead"
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={this.handleChange}
                                    value={this.state.nominates_lead}
                                    disabled={this.state.disableForm}
                                />
                                {/* <AutcompleteInput
                                    id="nominates_lead"
                                    label="Nominated Lead"
                                    options={this.state.employeesList}
                                    onChange={(e, v) => this.setAutoCompleteInput('nomi nates_lead', v)}
                                    disabled={this.state.disableForm}
                                    optionLabel={option => option.first_name + ' '+ option.last_name }
                                    multiple
                                /> */}
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={this.props.classes.date}
                                        disableToolbar
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="date"
                                        label="Date"
                                        value={this.state.date}
                                        onChange={(date) => this.setState({ date: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disabled={this.state.disableForm}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className={this.props.classes.date}
                                        disableToolbar
                                        // variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="from_date"
                                        label="From"
                                        value={this.state.from_date}
                                        onChange={(date) => this.setState({ from_date: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disabled={this.state.disableForm}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="operation"
                                    label="Operations"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.operation}
                                    disabled={this.state.disableForm}
                                />
                                {/* <AutcompleteInput
                                    id="operation"
                                    label="Operations"
                                    options={this.state.employeesList}
                                    onChange={(e, v) => this.setAutoCompleteInput('operation', v)}
                                    disabled={this.state.disableForm}
                                    optionLabel={option => option.first_name + ' '+ option.last_name }
                                    multiple
                                /> */}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="bid_estimate_lead"
                                    label="Bid/Estimating Lead"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.bid_estimate_lead}
                                    disabled={this.state.disableForm}
                                />
                                {/* <AutcompleteInput
                                    id="bid_estimate_lead"
                                    label="Bid/Estimating Lead"
                                    options={this.state.employeesList}
                                    onChange={(e, v) => this.setAutoCompleteInput('bid_estimate_lead', v)}
                                    disabled={this.state.disableForm}
                                    optionLabel={option => option.first_name + ' '+ option.last_name }
                                    multiple
                                /> */}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="contract_manager"
                                    label="Contract Manager"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.contract_manager}
                                    disabled={this.state.disableForm}
                                />
                                {/* <AutcompleteInput
                                    id="contract_manager"
                                    label="Contract Manager"
                                    options={this.state.employeesList}
                                    onChange={(e, v) => this.setAutoCompleteInput('contract_manager', v)}
                                    disabled={this.state.disableForm}
                                    optionLabel={option => option.first_name + ' '+ option.last_name }
                                    multiple
                                /> */}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl component="fieldset" disabled={this.state.disableForm}>
                                    <FormLabel component="legend">Current Stage</FormLabel>
                                    <RadioGroup
                                        aria-label="stage"
                                        id="current_stage"
                                        name="current_stage"
                                        value={this.state.current_stage}
                                        onChange={(e) => this.setState({ current_stage: Number(e.target.value) })}
                                        row
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="Gate 1" />
                                        <FormControlLabel value={2} control={<Radio />} label="Gate 2" />
                                        <FormControlLabel value={3} control={<Radio />} label="Gate 3" />
                                        <FormControlLabel value={4} control={<Radio />} label="Gate 4" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="engineering_pdm"
                                    label="Engineering PDM"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.engineering_pdm}
                                    disabled={this.state.disableForm}
                                />
                                {/* <AutcompleteInput
                                    id="engineering_pdm"
                                    label="Engineering PDM"
                                    options={this.state.employeesList}
                                    onChange={(e, v) => this.setAutoCompleteInput('engineering_pdm', v)}
                                    disabled={this.state.disableForm}
                                    optionLabel={option => option.first_name + ' '+ option.last_name }
                                    multiple
                                /> */}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="temp_work_designer"
                                    label="Temp. Works Designer"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.temp_work_designer}
                                    disabled={this.state.disableForm}
                                />
                                {/* <AutcompleteInput
                                    id="temp_work_designer"
                                    label="Temp. Works Designer"
                                    options={this.state.employeesList}
                                    onChange={(e, v) => this.setAutoCompleteInput('temp_work_designer', v)}
                                    disabled={this.state.disableForm}
                                    optionLabel={option => option.first_name + ' '+ option.last_name }
                                    multiple
                                /> */}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="planner"
                                    label="Planner"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.planner}
                                    disabled={this.state.disableForm}
                                />
                                {/* <AutcompleteInput
                                    id="planner"
                                    label="Planner"
                                    options={this.state.employeesList}
                                    onChange={(e, v) => this.setAutoCompleteInput('planner', v)}
                                    disabled={this.state.disableForm}
                                    optionLabel={option => option.first_name + ' '+ option.last_name }
                                    multiple
                                /> */}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="commercial"
                                    label="Commercial"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.commercial}
                                    disabled={this.state.disableForm}
                                />
                                {/* <AutcompleteInput
                                    id="commercial"
                                    label="Commercial"
                                    options={this.state.employeesList}
                                    onChange={(e, v) => this.setAutoCompleteInput('commercial', v)}
                                    disabled={this.state.disableForm}
                                    optionLabel={option => option.first_name + ' '+ option.last_name }
                                    multiple
                                /> */}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="procurment"
                                    label="Procurement"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.procurment}
                                    disabled={this.state.disableForm}
                                />
                                {/* <AutcompleteInput
                                    id="procurment"
                                    label="Procurement"
                                    options={this.state.employeesList}
                                    onChange={(e, v) => this.setAutoCompleteInput('procurment', v)}
                                    disabled={this.state.disableForm}
                                    optionLabel={option => option.first_name + ' '+ option.last_name }
                                    multiple
                                /> */}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="shwsq"
                                    label="SHESQ"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.shwsq}
                                    disabled={this.state.disableForm}
                                />
                                {/* <AutcompleteInput
                                    id="shwsq"
                                    label="SHESQ"
                                    options={this.state.employeesList}
                                    onChange={(e, v) => this.setAutoCompleteInput('shwsq', v)}
                                    disabled={this.state.disableForm}
                                    optionLabel={option => option.first_name + ' '+ option.last_name }
                                    multiple
                                /> */}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="estimating"
                                    label="Estimating"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.estimating}
                                    disabled={this.state.disableForm}
                                />
                                {/* <AutcompleteInput
                                    id="estimating"
                                    label="Estimating"
                                    options={this.state.employeesList}
                                    onChange={(e, v) => this.setAutoCompleteInput('estimating', v)}
                                    disabled={this.state.disableForm}
                                    optionLabel={option => option.first_name + ' '+ option.last_name }
                                    multiple
                                /> */}
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                            <Grid item xs={12} sm={12}>
                                <h3>Request For Tender Support</h3>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="project_title"
                                    label="Project titles"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.project_title}
                                    // InputProps={{
                                    //     readOnly: true,
                                    // }}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl className={this.props.classes.formControl}>
                                    <InputLabel>Client</InputLabel>
                                    <Select
                                        id="client"
                                        onChange={e => this.setState({ client: e.target.value })}
                                        value={this.state.client}
                                        // inputProps={{ readOnly: true }}
                                        disabled={true}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {clients.map((cl) => (
                                            <MenuItem value={cl.id} key={cl.id}>{cl.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="project_framework"
                                    label="Project/Framework"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.project_framework}
                                    disabled={this.state.disableForm}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField id="crm_no"
                                    label="CRM No."
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.crm_no}
                                    disabled={true}
                                // InputProps={{
                                //     readOnly: true,
                                // }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="project_category"
                                    label="Project Categorisation"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.project_category}
                                    disabled={this.state.disableForm}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl className={this.props.classes.formControl}>
                                    <InputLabel id="demo-controlled-open-select-label">OHL / CAB / SUB</InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="decipline"
                                        onChange={e => this.setState({ decipline: e.target.value })}
                                        value={this.state.decipline}
                                        disabled={this.state.disableForm}
                                    >
                                        <MenuItem value={null}>
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={0}>OHL</MenuItem>
                                        <MenuItem value={1}>CAB</MenuItem>
                                        <MenuItem value={2}>SUB</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="estimated_value"
                                    value={this.state.estimated_value}
                                    label="Estimated Value"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    disabled={this.state.disableForm}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="link_to_tender_docs"
                                    label="Link to tender documentation"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.link_to_tender_docs}
                                    disabled={this.state.disableForm}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="brief_scope_outline"
                                    label="Brief Scope Outline"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    multiline
                                    rows={4}
                                    value={this.state.brief_scope_outline}
                                    disabled={this.state.disableForm}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="construction_period"
                                    label="Construction Period"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    multiline
                                    rows={4}
                                    value={this.state.construction_period}
                                    disabled={this.state.disableForm}
                                />
                            </Grid>

                        </Grid>

                        <Divider />
                        <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                            <Grid item xs={12} sm={12}>
                                <h3 style={{ marginBottom: '0' }}>Key Dates</h3>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        disableToolbar
                                        // variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="eoi_ott_received"
                                        label="EOI / ITT Received"
                                        value={this.state.eoi_iit_received}
                                        onChange={(date) => this.setState({ eoi_itt_received: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disabled={this.state.disableForm}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        disableToolbar
                                        // variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="keep_up_meeting"
                                        label="Kick-Off Meeting"
                                        value={this.state.keep_up_meeting}
                                        onChange={(date) => this.setState({ keep_up_meeting: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disabled={this.state.disableForm}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        disableToolbar
                                        // variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="site_visit"
                                        label="Site Visit"
                                        value={this.state.site_visit}
                                        onChange={(date) => this.setState({ site_visit: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disabled={this.state.disableForm}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        disableToolbar
                                        // variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="stage_gate2"
                                        label="Stage Gate 2"
                                        value={this.state.stage_gate_2}
                                        onChange={(date) => this.setState({ stage_gate2: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disabled={this.state.disableForm}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        disableToolbar
                                        // variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="stage_gate3"
                                        label="Stage Gate 3"
                                        value={this.state.stage_gate_3}
                                        onChange={(date) => this.setState({ stage_gate3: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disabled={this.state.disableForm}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        disableToolbar
                                        // variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="contractor_proposal_submission"
                                        label="Contractor Proposal Submission"
                                        value={this.state.contractor_proposal_submission}
                                        onChange={(date) => this.setState({ contractor_proposal_submission: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disabled={this.state.disableForm}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        disableToolbar
                                        // variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="contract_award"
                                        label="Contract Award"
                                        value={this.state.contract_award}
                                        onChange={(date) => this.setState({ contract_award: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disabled={this.state.disableForm}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        disableToolbar
                                        // variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="contract_completion"
                                        label="Contract Completion"
                                        value={this.state.contract_completion}
                                        onChange={(date) => this.setState({ contract_completion: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disabled={this.state.disableForm}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="remarks"
                                    label="Remark"
                                    value={this.state.remarks}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    multiline
                                    rows={4}
                                    disabled={this.state.disableForm}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* </CardContent> */}
                        {/* <CardActions> */}
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            {proj && proj.bid_resource ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={this.updateForm}
                                    disabled={this.state.disableForm}
                                >
                                    Update
                            </Button>
                                :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={this.submitForm}
                                    disabled={this.state.disableForm}
                                >
                                    Submit
                            </Button>
                            }
                        </div>
                    </div>
                }
            </Dialog>
        </>)
    }
}

export default withSnackbar(withStyles(useStyles)(BIDRRForm))