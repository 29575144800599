import React, { useEffect, useState } from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import Routes from './routes/Routes';
import { BrowserRouter } from 'react-router-dom'
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from './authProvider';
import { deleteCookie, getCookie, setCookie } from './utils/CookieHelper';
import { basicReduxStore } from './reduxStore';
import Service from "./config/networkutils";

var CryptoJS = require("crypto-js");

var key = CryptoJS.enc.Utf8.parse('1234567890123456');

const bbColorTheme = createTheme({
  palette: {
    primary: {
      main: '#007681',
      light: '#00d0e3',
      dark: '#002e33',

    },
    secondary: {
      main: '#EEBE86',
      light: '#febf76',
      dark: '#4d2a01',
    },

  },
  typography: {
    fontSize: 12,
    // htmlFontSize: 10,
  },
})

const list_of_excluded_domains = ["cloudapp.azure.com"]

function encrypt(msgString, key) {
  // msgString is expected to be Utf8 encoded
  var iv = CryptoJS.lib.WordArray.random(16);
  var encrypted = CryptoJS.AES.encrypt(msgString, key, {
    iv: iv
  });
  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

function App() {

  const [authSuccess, setAuthSuccess] = useState((window.location.hostname === 'localhost' || check_excluded_domains()));

  useEffect(() => {
    if (getCookie("ptd_ts_user") && getCookie("ptd_ts_token")) {
      setAuthSuccess(true)
    } else if (window.location.hostname === 'localhost' || check_excluded_domains()) {
      if (!window.location.href.includes('/login')) {
        window.location.href = '/login'
        deleteCookie("ptd_ts_token");
        deleteCookie("ptd_ts_user");
      }
      setAuthSuccess(true)
    } else {
      deleteCookie("ptd_ts_token");
      deleteCookie("ptd_ts_user");
    }
  },[])

  const getTokenFromDj = (data) => {

    Service.patch('/api/generate/token/token_update/', {
      data: {
        id_token: encrypt("P1a1R1a1M" + data.jwtIdToken + "P1a1R1a1M", key),
      }
    })
      .then(res => {
        if (res.data && res.data.token) {
          setCookie('ptd_ts_token', res.data.token, { secure: true, 'max-age': 172800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', },);
          Service.get(`/rest-auth/user/`, {
            headers: {
              Authorization: "Token " + res.data.token,
            },
          })
            .then(res1 => {
              // console.log(res1.data)

              if (!res1.data.twof_auth) {
                setCookie('ptd_ts_user', JSON.stringify(res1.data), { secure: true, 'max-age': 172800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                window.location.reload()
              } else {
                // setOpen(true)
              }
            })
            .catch(error => {
              this.props.enqueueSnackbar('Unable to fetch user details. Please try later!', {
                variant: 'error',
              });
            });
        } else {
          alert("We haven't added you to RMS.")
        }

      })
      .catch(error => {
        console.log(error)
      });
  }

  return (
    (!authSuccess) ?
      <AzureAD provider={authProvider} reduxStore={basicReduxStore} forceLogin={!getCookie("ptd_ts_token")} >
        {
          ({ login, logout, authenticationState, error, accountInfo }) => {

            switch (authenticationState) {
              case AuthenticationState.Authenticated:

                if (!(getCookie("ptd_ts_token") && getCookie("ptd_ts_user"))) {
                  getTokenFromDj(accountInfo)
                } else {
                  setAuthSuccess(true)
                }
                

                return (
                  authSuccess ? <BaseRoute /> : (<p>Authenticating...</p>)
                );
              case AuthenticationState.Unauthenticated:

                deleteCookie("ptd_ts_token");
                deleteCookie("ptd_ts_user");

                return (<p>Authenticating...</p>);
              case AuthenticationState.InProgress:
                return (<p>Authenticating...</p>);
              default:
                setTimeout(() => {
                  if (authenticationState !== AuthenticationState.Authenticated) {
                    window.location.reload()
                  }
                }, 3000);
                return (<p>Authenticating...</p>);
            }
          }
        }

      </AzureAD>
      : <BaseRoute />

  );
}

function BaseRoute() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={bbColorTheme}>
        <SnackbarProvider maxSnack={3}>
          <Routes />
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

function check_excluded_domains() {
  for (var i = 0; i < list_of_excluded_domains.length; i++) {
    if (window.location.hostname.includes(list_of_excluded_domains[i])) {
      return true
    }
  }
}

export default App;
