import { Box, Divider, Typography, } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
// import { getCookie } from '../../utils/CookieHelper';
// import Service from '../../config/networkutils';
import { LoopCircleLoading } from 'react-loadingg';
import GangRolesTransfer from './GangRolesTransfer';

// const token = getCookie("ptd_ts_token");
// const user = JSON.parse(getCookie("ptd_ts_user"));

class GangRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        // this.fetchGangRoles();
        this.setState({ isLoading: false });
    }

    // fetchGangRoles = () => {
    //     this.setState({ isLoading: true });
    //     Service.get(`/api/gang/report/roles/?category=${this.props.gang.id}`, {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {
    //             this.setState({ data: res.data, isLoading: false });
    //         })
    //         .catch(error => {
    //             console.log(error);
    //             this.props.enqueueSnackbar('Something went wrong!', {
    //                 variant: 'error'
    //             });
    //         })
    // }

    render() {
        return (
            <div>
                {
                    this.state.isLoading ? <LoopCircleLoading color='#3f51b5' /> :
                        <Box display='flex' flexDirection={'row'} justifyContent={'space-evenly'} width={'100%'} m={1}>
                            <Box display='flex' flexDirection={'column'} justifyContent={'center'} width={'100%'}>
                                <Typography variant='h6' >Gang Roles</Typography>
                                <GangRolesTransfer gang={this.props.gang} gang_level={0} />
                            </Box>
                            <Divider orientation="vertical" flexItem variant='middle' />
                            <Box display='flex' flexDirection={'column'} justifyContent={'center'} width={'100%'}>
                                <Typography variant='h6' >Member Roles</Typography>
                                <GangRolesTransfer gang={this.props.gang} gang_level={1} />
                            </Box>
                        </Box>}
            </div>
        );
    }
}

export default withSnackbar(GangRoles);