export const account_name_api = "/api/ptd/account/";
export const sales_stage_api = "/api/salesstage/";
export const gate_stage_api = "/api/gatestages/";
export const sub_sector_api = "/api/subsector/";
export const market_sub_vertical_api = "/api/marketsubvertical/";
export const owner_delivery_api = "/api/ownerdelivery/";
export const owner_region_sector_api = "/api/ownerregionsector/";
export const opportunity_type_api = "/api/opportunitytype/";
export const roles_api = "/api/roles/";
export const roles_api_bulk_update = "/api/roles/bulk_update";
export const crm_api = "/api/crm/";
export const crm_api_bulk_update = "/api/crm/bulk_update/";
export const bid_res_api = "/api/bid-res/";
export const prp_template_api = "/api/prp/template/";
export const prp_template_api_bulk_update = "/api/prp/role/bulk_update/";
export const prp_roles_api = "/api/prp/role/";
export const prp_roles_api_bulk_update = "/api/prp/role/bulk_update/";
export const users_api = "/api/ptd/employees/";
export const get_users_api = "/api/ptd/employees/employee_data/";
export const users_api_bulk_update = "/api/ptd/employees/bulk_update/";
export const sales_status_api = "/api/sales_status/";
export const dvcReport_api = "/api/crm/report_api/";
export const roles_group_api = "/api/role/group/";
export const roles_group_allocation_api = "/api/allocation/role/group/";
export const roles_group_remove_api = "/api/allocation/role/group/delete/";
export const employees_and_projects = "/api/prp/role/custom_api/";
export const roles_sectors_regions = "/api/prp/rolenew/get_data/";
export const roles_crm_emp = "/api/prp/rolenew/get_data/";
export const generalforeman = "/api/ohl/generalforeman/";
export const get_foreman_by_role = "/api/ohl/generalforeman/get_foreman_by_role/";
export const generalforemanGang = "/api/ohl/gang/";
export const otherTasks = "/api/other/tasks/";
export const ptdEmployeesLeave = "/api/ptd/employees/leave/";
export const promotion = "/api/ptd/employee/promotion/employee_role_upgrade/";
export const getPromotion = "/api/ptd/employee/promotion/";
export const crmEmployees = "/api/prp/role/crm_based_employees_api/";
export const regionHistory = "/api/ptd/history/employees/";
export const getElectricityOHLTcRMs = "/api/crm/get_sector_data/?sector=2";
export const getGangEmployees = "/api/ohl/gang/get_emp_data/";
export const global_edit = '/api/prp/role/global_edit/';
export const prptandPrprDeleteByPRPtid = '/api/prp/role/delete_prp_template_api/?prp_template=';
export const bulkPRPTandPRPRcreate = '/api/crm/multiple_gang_addition_to_project/';
export const conditionalPRPTandPRPRcreate = '/api/prp/role/prp_template_and_prp_role_create/';
export const prprSchedulesBulkUpdate = "/api/prp/role/prp_role_shdl_data_update/";
