import { CircularProgress } from '@material-ui/core';
import dayjs from 'dayjs';
import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts';

export default class BarGraphComponent extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          isLoading: true,
          series: null,
          height: this.props.dashboard ? this.screenSizeCheck() ? 220 : 180 : this.screenSizeCheck() ? 450 : 250,
          options : {
            chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: 'normal'
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'center',
                hideOverflowingLabels: false,
                
              },
            }
          },
          dataLabels: {
            style:{
              colors: ["#000"]
            },
            // formatter: function(val, opt) {
            //   return val !== 0 ? val : ''
            // }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }],

          xaxis: {
            type: 'category',
            labels: {
              show: true,
              formatter: function (val = new Date()) {
                let dateArray = String(val).split("-")
                return "Q" + Math.floor(Number(dateArray[1]) / 3 + 1)
              }
            },
            tickPlacement: 'between',
            group: {
              style: {
                fontSize: '10px',
                fontWeight: 700
              },
              groups: null
            }
          },
          legend:{
            show: this.props.dashboard ? false : true
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            x: {
              formatter: function (val) {
                let dateArray = String(val).split("-")
                return "Q" + Math.floor(Number(dateArray[1]) / 3 + 1) + " " + dayjs(val).format("YYYY")
              }
            }
          }
          }
    
    
        };
      }

      screenSizeCheck() {
        if (window.innerWidth > 1600) {
            return true
        } else {
            return false
        }
      }


      getData(){
        let capacityObj = {}

    for (let index = 0; index < this.props.rowData.quater.length; index++) {
        // let capacityObj =   Object.entries(this.props.rowData.quater).map( x => ({
      const element = this.props.rowData.quater[index];
      let year = element.quarter_start.split('-')
      if (!(year[0] in capacityObj)) {
        capacityObj[year[0]] = 1
      } else {
        capacityObj[year[0]] += 1
      }

    }

    var groupObj = Object.entries(capacityObj).map(x => ({
      'title': x[0],
      'cols': x[1]
    }))

    // let demand = Object.entries(this.props.rowData.quater).map(x => ({
    //   'x': x[1].quarter_start,
    //   'y': x[1].demand
    // }))
    // let capacity = Object.entries(this.props.rowData.quater).map(x => ({
    //   'x': x[1].quarter_start,
    //   'y': x[1].capacity
    // }))
    // let otherDemand = Object.entries(this.props.rowData.quater).map(x => ({
    //   'x': x[1].quarter_start,
    //   'y': x[1].other_demand
    // }))
    let attrition =  Object.entries(this.props.rowData.quater).map(x => ({
      'x': x[1].quarter_start,
      'y': x[1].attrition
    }))
    let thCapacity = Object.entries(this.props.rowData.quater).map(x => ({
      'x': x[1].quarter_start,
      'y': x[1].th_capacity
    }))
    let requiredDemand = Object.entries(this.props.rowData.quater).map(x => ({
      'x': x[1].quarter_start,
      'y': x[1].required_demand
    }))


    let series = [{ name: 'Capacity', data: thCapacity }, { name: 'Growth Needed', data: requiredDemand }, { name: 'Attrition@10%', data: attrition }]
    this.setState({
      series: series,
      options: {
        ...this.state.options,
        xaxis: {
          ...this.state.options.xaxis,
          group: {
            ...this.state.options.xaxis.group,
            groups: groupObj
          }
        }
      }
    }, () => {
      // this.props.loading()
      this.setState({
        isLoading: false
      })
      
    })
      }

      componentDidMount(){
        this.getData()
      }

  render() {
    return (
      <div>
        {this.state.isLoading ? <CircularProgress/> :
        <ReactApexChart options={this.state.options} series={this.state.series}  type="bar" height={this.state.height} />
        }
      </div>
    )
  }
}
