import React from 'react';
import { Chart } from "react-google-charts";
import { RotateCircleLoading } from 'react-loadingg';
import { Box, Button } from '@material-ui/core';
import { jsPDF } from "jspdf";
import domtoimage from 'dom-to-image';

class ROAPGraph extends React.Component {
    constructor(props) {
        super(props)
        this.canvas = React.createRef()
        this.state = {
            chartData: [

                [
                    'Month',
                    'Civils',
                    'Engineer',
                    'Linesmen',
                    'Site Manager',
                    'PM',
                    'Supervisor',
                    'Others',
                    'Forecast'
                ],
            ]
        }
        this.ref = React.createRef();
    }

    componentDidMount() {
        let totalMonths = this.props.columns;
        let data = this.props.data;

        let chartData = [...this.state.chartData]

        totalMonths.forEach(element => {
            let arr = [element, 0, 0, 0, 0, 0, 0, 0, 0]

            // if (data.hasOwnProperty(element)) { arr[1] = Number(data[element]) }
            // for (const date in data.capacity) {
            //     if (date === element) {
            //         arr[4] = Number(data.capacity[date])
            //     }
            // }

            for (let i = 0; i < data.length; i++) {
                if (data[i].hasOwnProperty(element) && data[i].role === 'Civils') { arr[1] = Number(data[i][element]) }
                else if (data[i].hasOwnProperty(element) && data[i].role === 'Engineer') { arr[2] = Number(data[i][element]) }
                else if (data[i].hasOwnProperty(element) && data[i].role === 'Linesmen') { arr[3] = Number(data[i][element]) }
                else if (data[i].hasOwnProperty(element) && data[i].role === 'Site Manager') { arr[4] = Number(data[i][element]) }
                else if (data[i].hasOwnProperty(element) && data[i].role === 'Project Manager') { arr[5] = Number(data[i][element]) }
                else if (data[i].hasOwnProperty(element) && data[i].role === 'Supervisor') { arr[6] = Number(data[i][element]) }
                else if (data[i].hasOwnProperty(element) && data[i].role === 'Others') { arr[7] = Number(data[i][element]) }
                else if (data[i].hasOwnProperty(element) && data[i].role === 'Forecast') { arr[8] = Number(data[i][element]) }
            }

            chartData.push(arr)
        });

        this.setState({ chartData: chartData })
    }

    downloadPdf() {

    }

    handlePdf = () => {
        const input = document.getElementById('roapReport');

        domtoimage.toPng(input)
            .then((dataUrl) => {
                // const pdf = new jsPDF({
                //     orientation: 'l', // landscape
                //     unit: 'pt', // points, pixels won't work properly
                //     // format: [canvas.width, canvas.height] // set needed dimensions for any element
                // })
                let pdf = new jsPDF('l', 'pt', 'a3');
                // let width = pdf.internal.pageSize.getWidth();
                // let height = pdf.internal.pageSize.getHeight();
            // let height = 350;
            // let width = 1000;
            //     if (window.screen.width >= 1400) {
            //         width = 990
            //     }
            //     if (window.screen.width >= 1600) {
            //         width = 970
            //     }
            //     if (window.screen.width >= 1900) {
            //         width = 940
            //     }
              
                pdf.addImage(dataUrl, 'PNG', 0, 0);
                pdf.save("Roap Report.pdf");

            })
            .catch(function (error) {
                // console.error('oops, something went wrong!', error);
            });
        //     html2canvas(input,
        //         {
        //             allowTaint: true,
        //             useCORS: true,
        //         }
        //     )
        //         .then((canvas) => {
        //             // document.body.appendChild(canvas);
        //             const imgData = canvas.toDataURL('image/png');
        //             var img = new Image();
        //             img.src = imgData;
        //             document.body.appendChild(img);
        //             const pdf = new jsPDF()
        //             var width = pdf.internal.pageSize.getWidth();
        //             var height = pdf.internal.pageSize.getHeight();
        //             // pdf.addImage(imgData, 'PNG', 0, 0);
        //             // pdf.save("test.pdf");
        //         });

        //     // const string = renderToString(<Graph />);
        //     // const pdf = new jsPDF("p", "mm", "a4");
        //     // pdf.fromHTML(string);
        //     // pdf.save("pdf");
    }



    render() {

        return (
            <React.Fragment>
                {this.props.columns.length ?
                    <Button
                        variant="contained"
                        onClick={this.handlePdf}
                        style={{ float: 'right', marginTop: '20px' }}
                        size={'small'}
                    > Download Report PDF</Button>
                    : null}

                <div id="roapReport">
                    {/* <Pdf targetRef={this.ref} filename="code-example.pdf">
                    {({ toPdf }) => <Button variant="contained" onClick={toPdf}> Download Report PDF</Button>}
                </Pdf> */}
                    {/* <button onClick={this.handlePdf}>PDF download</button> */}
                    {/* <div id="roapReport" ref={this.canvas}><h1>sdkfhksdy f osidufoidsu o</h1></div> */}
                    <Box display="flex" p={1} bgcolor="background.paper" ref={this.ref} >
                        {this.props.columns.length ? <Chart
                            height={'400px'}
                            width='100%'
                            chartType="ComboChart"
                            loader={<RotateCircleLoading color="#005D99" />}
                            data={this.state.chartData}
                            options={{
                                title: 'Resource Profile',
                                vAxis: { title: 'No. Resources' },
                                hAxis: {
                                    title: 'Months',
                                    // direction: -1, 
                                    // slantedText: true, 
                                    // slantedTextAngle: 90,
                                },
                                seriesType: 'bars',
                                series: { 7: { type: 'line' } },
                                isStacked: true,
                                colors: ['F44336', '8BC34A', 'B39DDB', '3F51B5', '2196F3', 'FFEB3B', 'FFAB91', 'FF9800']
                            }}
                        /> :
                            <h2>No demand available</h2>}
                    </Box>
                </div>
            </React.Fragment>
        )
    }
}

export default ROAPGraph;