import React, { Component } from 'react'
import { getCookie } from '../../utils/CookieHelper';
import Service from '../../config/networkutils'
import { withSnackbar } from 'notistack';
import { Box, CircularProgress } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';

const token = getCookie("ptd_ts_token");
class GangsGraph extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            series: [],
            options: {
                chart: {
                    height: 350,
                    type: 'bar',
                    parentHeightOffset: 0
                },
                // colors: ['#3399ff', '#ff9933', '#808080', '#ffd11a'],
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        distributed: true,
                    }
                },
                title: {
                    text: 'Gangs',
                    align: 'center'
                },
                grid: {
                    padding: {
                        bottom: 0
                    }
                },
                legend: {
                    show: true
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    title: 'Gangs',
                    categories: [],
                    labels: {
                        style: {
                            fontSize: '12px'
                        }
                    }
                }
            }
        }
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        Service.get(`/api/subcontractor/other_reports_gangs/?category=${this.props.gang.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let data = []
                let categories = []
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    categories.push(element.GeneralForeman)
                    data.push(element.GeneralForemanGang)
                }
                let series = [{
                    name: 'Gang Count',
                    data: data
                }]
                this.setState({ series: series, options: { ...this.state.options, xaxis: { ...this.state.options.xaxis, categories: categories }, }, isLoading: false })
            })
            .catch(error => {
                console.log(error);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    render() {
        return (
            <div>{this.state.isLoading ?
                <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress /></Box> :
                <ReactApexChart options={this.state.options} series={this.state.series} width={'100%'} type="bar" height={window.innerHeight / 2} />}
            </div>
        )
    }
}

export default withSnackbar(GangsGraph);
