import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { getCookie } from '../../utils/CookieHelper';
import MaterialTable from 'material-table';
import Service from '../../config/networkutils'
import GangRoles from './GangRoles';

const token = getCookie("ptd_ts_token");
const user = JSON.parse(getCookie("ptd_ts_user"));

class GangStructures extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        this.fetchGangStructures();
    }

    fetchGangStructures = () => {
        this.setState({ isLoading: true });
        Service.get(`/api/gang/report/category/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false });
            })
            .catch(error => {
                console.log(error);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    postData = (data) => {

        data.created_by = user.id;
        this.setState({ isLoading: true });
        Service.post(`/api/gang/report/category/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.props.enqueueSnackbar("Gang Structure added successfully", { variant: "success" });
                this.fetchGangStructures();
            })
            .catch(error => {
                console.log(error);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false });
            })
    }

    putData = (data) => {
        this.setState({ isLoading: true });
        Service.put(`/api/gang/report/category/${data.id}/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.props.enqueueSnackbar("Updated successfully!", { variant: "success" });
                this.setState({ isLoading: false });
                this.fetchGangStructures();
            })
            .catch(error => {
                console.log(error);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false });
            })
    }

    deleteData = (data) => {
        this.setState({ isLoading: true });
        Service.delete(`/api/gang/report/category/${data.id}/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar("Deleted successfully!", { variant: "success" });
                this.setState({ isLoading: false });
                this.fetchGangStructures();
            })
            .catch(error => {
                console.log(error);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false });
            })
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title={"Gangs"}
                    columns={[
                        { title: "Name", field: "name" },
                        { title: "Key", field: "key" },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        paging: false,
                        padding: "dense",
                        actionsColumnIndex: -1
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "No gangs to display"
                        }
                    }}
                    detailPanel={rowData => {
                        return (
                            <div style={{ margin: 1 }}>
                                <GangRoles gang={rowData} />
                            </div>
                        );
                    }
                    }
                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                    editable={
                        {
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        if (!newData.name || !newData.key) {
                                            this.props.enqueueSnackbar("Name and Key are required", { variant: "warning" });
                                            resolve();
                                        } else {
                                            const data = this.state.data;
                                            data.push(newData);
                                            this.setState({ data }, () => resolve());
                                            this.postData(newData);
                                        }
                                    }, 200)
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        if (!newData.name || !newData.key) {
                                            this.props.enqueueSnackbar("Name and Key are required", { variant: "warning" });
                                            resolve();
                                        } else {
                                            const data = this.state.data;
                                            const index = data.indexOf(oldData);
                                            data[index] = newData;
                                            this.setState({ data }, () => resolve());
                                            this.putData(newData);
                                        }
                                    }, 200)
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        let data = this.state.data;
                                        const index = data.indexOf(oldData);
                                        data.splice(index, 1);
                                        this.setState({ data }, () => resolve());
                                        this.deleteData(oldData);
                                    }, 200)
                                }),
                        }
                    }
                />
            </div>
        );
    }
}

export default withSnackbar(GangStructures);