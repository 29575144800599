export const generalSortOrder = [
    'Head of Operations',
    'Senior Project Manager (SPM)',
    'Project Manager (PM)',
    'Project Engineer',
    'Site Manager',
    'Site Engineer',
    'General Foreman',
    'Linesmen_Gang 1',
    'Civil Supervisor',
    'Civil Operative'
]
export const ohlOldSortOrder = [
    "Apprentice General Foreman",
    "Senior General Foreman",
    "Drivers",
    "Storeman",
    "General Operative",
    "Foreman 1",
    "Foreman 2",
    "LE1",
    "LE2",
    "LH",
    "Apprentice Linesman",
    "Trainee Linesman",
    "SPO",
]

export const ohlSortOrder = [
    "Senior General Foreman",
    "General Foreman",
    "Foreman",
    "Foreman 1",
    "Foreman 2",
    "LH",
    "LE1",
    "LE2",
    "Improver",
    "Trainee Over Head Line Technician",
    "Apprentice Over Head Line Technician",
    "General Operatives",
    "Storeman",
    "Driver 1",
    "Driver 2",
]
export const ohlroles = [
    { id: 58 },
    { id: 171 },
    { id: 124 },
    { id: 45 },
    { id: 138 },
    { id: 203 },
    { id: 196 },
    { id: 204 },
    { id: 205 },
    { id: 206 },
    { id: 207 },
    { id: 208 },
    { id: 209 },
    { id: 210 },
    { id: 270 },
]

export const months = { 0: 'January', 1: 'Febrauary', 2: 'March', 3: 'April', 4: 'May', 5: 'June', 6: 'July', 7: 'August', 8: 'September', 9: 'October', 10: 'November', 11: 'December' }
