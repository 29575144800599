import React, { Component } from 'react'
import { getCookie } from '../../utils/CookieHelper';
import Service from '../../config/networkutils'
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { withSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import CloseIcon from '@material-ui/icons/Close';


const token = getCookie("ptd_ts_token");
class NoneWorkingDaily extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            open: false,
            title: '',
            empData: [],
            isLoading: true,
            series: [],
            options: {
                chart: {
                    height: 350,
                    type: 'bar',
                    parentHeightOffset: 0,
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            this.setState({
                                open: true,
                                empData: this.state.data[config.dataPointIndex],
                                title: this.state.options.xaxis.categories[config.dataPointIndex]
                            })
                        }
                    }
                },
                colors: ['#3399ff', '#ff9933', '#808080', '#ffd11a'],
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        distributed: true,

                    }
                },
                title: {
                    text: 'None Working Daily Update',
                    align: 'center'
                },
                grid: {
                    padding: {
                        bottom: 0
                    }
                },
                legend: {
                    show: true
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    title: 'None Working Daily Update',
                    categories: [],
                    labels: {
                        style: {
                            fontSize: '12px'
                        }
                    },
                },
            }
        }
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        Service.get(`/api/subcontractor/other_report_daily_none_working/?category=${this.props.gang.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let data = []
                let categories = []
                let empData = []
                let categoryKeys = Object.keys(res.data)
                for (let index = 0; index < categoryKeys.length; index++) {
                    const element = categoryKeys[index];
                    data.push({ x: element.charAt(0).toUpperCase() + element.slice(1), y: res.data[element].value })
                    categories.push(element.charAt(0).toUpperCase() + element.slice(1))
                    empData.push(res.data[element].employees)
                }
                let series = [
                    {
                        data: data
                    }
                ]
                this.setState({ series: series, options: { ...this.state.options, xaxis: { ...this.state.options.xaxis, categories: categories }, }, isLoading: false, data: empData });
            })
            .catch(error => {
                console.log(error);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    render() {
        return (
            <div>{this.state.isLoading ?
                <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress /></Box> :
                <ReactApexChart options={this.state.options} series={this.state.series} width={'100%'} type="bar" height={window.innerHeight / 2 - 50} />
            }
                <Dialog open={this.state.open} maxWidth={"md"} onClose={() => this.setState({ open: false })}>
                    <DialogTitle>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Box>{this.state.title}</Box>
                            <IconButton onClick={() => this.setState({ open: false })}><CloseIcon /></IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                            <MaterialTable
                                title={"Employees "}
                                data={this.state.empData}
                                columns={[
                                    { title: 'Employee Number', field: 'emp_no', width: '200px' },
                                    { title: 'First Name', field: 'first_name', width: '200px' },
                                    { title: 'Last Name', field: 'last_name', width: '200px' }
                                ]}
                                options={{
                                    tabLayout: 'fixed',
                                    paging: false
                                }}
                            />
                    </DialogContent>
                </Dialog>

            </div>
        )
    }
}

export default withSnackbar(NoneWorkingDaily);
