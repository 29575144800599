import React from 'react'
import Service from '../../config/networkutils';
import { withStyles } from '@material-ui/core/styles';
// import ResourceReport from './ResourceReport';
import { AppBar, Box, Button, Dialog, DialogContent, FormControlLabel, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import {
    crm_api,
    dvcReport_api,
    roles_group_allocation_api
} from '../../config/apiList';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../../utils/CookieHelper';
import { getActiveRoles, getEmployeeRegionWithHistory, getEmployees, getMarketSubVerticle, getOwnerDelivery, getRegion, getRoles, getRolesGroup, getSubSector } from '../../config/PromisesHandler';
import { getRolesLookupObj } from '../../utils/reuseFunctions';
import RolesTable from '../DvsCReports.js/RolesTable';
import CloseIcon from '@material-ui/icons/Close';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { getPrimaryColor } from '../Helper/CommonThings';


const token = getCookie("ptd_ts_token");

const useStyles = (theme) => ({
    appBar: {
        position: 'fixed',
        top: 0,
    },
    formContainer: {
        marginTop: '64px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// let url = `${window.location.protocol}//${window.location.host}/#Dashboard`


class DemandVsCapacityHome extends React.Component {
    constructor() {
        super()
        this.state = {
            modelOpen: false,
            selectedOpportunities: [],
            filteredOpportunities: [],
            reportModal: false,
            opportunity: [],
            allProjects: [],
            opportunitis: [],
            subSectors: [],
            subVerticles: [],
            allOwnerRegions: [],
            allEmpRegionHistory: [],
            ownerDeliveries: [],
            selectedSubSectors: [],
            selectedSubVerticles: [],
            selectedOwnerRegions: [],
            selectedOwnerDeliveries: [],
            loading: false,
            rolesGroups: [],
            selectedRoleGroups: [],
            roles: [],
            selectedRole: [],
            id: null,
            isRole: false,
            isRoleGroups: false,
            value: '1',
            prevSelectedRole: [],
            prevSelectedRoleGroup: [],
            prevOwnerDeliveries: [],
            prevAllOwnerRegions: [],
            prevSubVerticles: [],
            prevSubSectors: [],
            allSelectSectors: false,
            allSelectVerticles: false,
            allSelectOwnerRegions: false,
            allSelectOwnerDeliveries: false,
            allSelectRoleGroups: false,
            allSelectRole: false,
            allSelectProject: false,
            checked: false,
            sector: false,
            gateStages: [],
            gateStageSelected: [],
        }
    }

    getGateStages(){
        const gate_stage_api = "/api/gatestages/";
        Service.get(gate_stage_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
               this.setState({
                gateStages: res.data
               },() =>{
                let gate = []
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    if(element.id === 3 || element.id === 5){
                        gate.push(element)
                    }
                }
                this.setState({
                    gateStageSelected: gate
                })
               })
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    componentDidMount() {
        this.setState({ loading: true })
        Promise.all([
            this.getCRM(),
            getEmployees(),
            getActiveRoles(),
            getSubSector(),
            getMarketSubVerticle(),
            getEmployeeRegionWithHistory(),
            getRegion(),
            getOwnerDelivery(),
            getRolesGroup(),
            getRoles(),
            this.getGateStages()
        ])
            .then(res => {

                let liveRoles = res[9].filter(ele => ele.is_active === true)

                this.setState({
                    allProjects: res[0],
                    allEmp: res[1],
                    allActiveRoles: res[2],
                    rolesObj: getRolesLookupObj(res[2]),
                    loading: false,
                    subSectors: res[3],
                    subVerticles: res[4],
                    allEmpRegionHistory: res[5],
                    allOwnerRegions: res[6],
                    ownerDeliveries: res[7],
                    rolesGroups: res[8],
                    roles: liveRoles,
                    opportunitis: res[0]
                }, () => {

                    let searchParams = new URLSearchParams(window.location.search)

                    this.setState(prevState => ({
                        subSectors: [{ name: 'Select All' }, ...prevState.subSectors],
                        subVerticles: [{ name: 'Select All' }, ...prevState.subVerticles],
                        allOwnerRegions: [{ name: 'Select All' }, ...prevState.allOwnerRegions],
                        ownerDeliveries: [{ name: 'Select All' }, ...prevState.ownerDeliveries],
                        rolesGroups: [{ name: 'Select All' }, ...prevState.rolesGroups],
                        roles: [{ job_roles: 'Select All' }, ...prevState.roles],
                        opportunitis: [{ name: 'Select All' }, ...prevState.opportunitis]
                    }))


                    let selectedSubSectors = []
                    let selectedSubVerticles = []
                    let selectedOwnerRegions = []
                    let selectedOwnerDeliveries = []
                    let selectedRoleGroups = []
                    // let selectedSalesStages = []
                    // let selectedSalesStatuses = []
                    let selectedOpportunities = []
                    if (searchParams.get("selectedSubSectors")) {
                        let idNum = searchParams.get("selectedSubSectors").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perSS = this.state.subSectors.filter((reg) => reg.id === parseInt(idNum[id]))
                            selectedSubSectors.push(perSS[0])
                        }
                        // let parRole = this.state.allRoles.filter((rol) => rol.id === idNum)
                        // if (parRole.length > 0) { this.setState({ selectedRole: parRole[0] }, () => this.fetchOps()) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    }
                    if (searchParams.get("selectedSubVerticless")) {
                        let idNum = searchParams.get("selectedSubVerticless").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perSSV = this.state.subVerticles.filter((reg) => reg.id === parseInt(idNum[id]))
                            selectedSubVerticles.push(perSSV[0])
                        }
                        // let parRole = this.state.allRoles.filter((rol) => rol.id === idNum)
                        // if (parRole.length > 0) { this.setState({ selectedRole: parRole[0] }, () => this.fetchOps()) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    }
                    if (searchParams.get("selectedOwnerRegions")) {
                        let idNum = searchParams.get("selectedOwnerRegions").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perRegion = this.state.allOwnerRegions.filter((reg) => reg.id === parseInt(idNum[id]))
                            selectedOwnerRegions.push(perRegion[0])
                        }
                        // let parRole = this.state.allRoles.filter((rol) => rol.id === idNum)
                        // if (parRole.length > 0) { this.setState({ selectedRole: parRole[0] }, () => this.fetchOps()) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    }
                    if (searchParams.get("selectedOwnerDeliveries")) {
                        let idNum = searchParams.get("selectedOwnerDeliveries").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perOD = this.state.ownerDeliveries.filter((od) => od.id === parseInt(idNum[id]))
                            selectedOwnerDeliveries.push(perOD[0])
                        }
                        // let projectWithRegions = this.state.allProjects.filter(ele => ele.owner_region_sector === idNum)
                        // if (parRegion.length > 0) { this.setState({ selectedOwnerRegion: parRegion }, () => this.templateDataWithProjects(projectWithRegions)) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    }
                    if (searchParams.get("selectedRoleGroups")) {
                        let idNum = searchParams.get("selectedRoleGroups").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perRG = this.state.rolesGroups.filter((rg) => rg.id === parseInt(idNum[id]))
                            selectedRoleGroups.push(perRG[0])
                        }
                        // let projectWithRegions = this.state.allProjects.filter(ele => ele.owner_region_sector === idNum)
                        // if (parRegion.length > 0) { this.setState({ selectedOwnerRegion: parRegion }, () => this.templateDataWithProjects(projectWithRegions)) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    }
                    // if (searchParams.get("selectedSalesStage")) {
                    //     let idNum = searchParams.get("selectedSalesStage").split(',')
                    //     for (let id = 0; id < idNum.length; id++) {
                    //         let perStage = this.state.salesStage.filter((stag) => stag.id === parseInt(idNum[id]))
                    //         selectedSalesStages.push(perStage[0])
                    //     }
                    //     // let subSector = this.state.allSubSectors.filter((subSector) => subSector.id === idNum)
                    //     // if (subSector.length > 0) { this.setState({ selectedSubSector: subSector }) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    // }
                    // if (searchParams.get("selectedSalesStatus")) {
                    //     let idNum = searchParams.get("selectedSalesStatus").split(',')
                    //     for (let id = 0; id < idNum.length; id++) {
                    //         let perSS = this.state.salesStatus.filter((ss) => ss.id === parseInt(idNum[id]))
                    //         selectedSalesStatuses.push(perSS[0])
                    //     }
                    //     // let subSector = this.state.allSubSectors.filter((subSector) => subSector.id === idNum)
                    //     // if (subSector.length > 0) { this.setState({ selectedSubSector: subSector }) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    // }
                    if (searchParams.get("selectedOpportunity")) {
                        let idNum = searchParams.get("selectedOpportunity").split(',')
                        if (idNum[0] === "") {
                            idNum.shift()
                        }
                        for (let id = 0; id < idNum.length; id++) {
                            let perOppoty = this.state.allProjects.filter((oppoty) => oppoty.id === parseInt(idNum[id]))
                            selectedOpportunities.push(perOppoty[0])
                        }
                        // let subSector = this.state.allSubSectors.filter((subSector) => subSector.id === idNum)
                        // if (subSector.length > 0) { this.setState({ selectedSubSector: subSector }) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    }

                    if (
                        selectedSubSectors.length > 0 ||
                        selectedSubVerticles.length > 0 ||
                        selectedOwnerRegions.length > 0 ||
                        selectedOwnerDeliveries.length > 0 ||
                        selectedRoleGroups.length > 0 ||
                        // selectedSalesStages.length > 0 ||
                        // selectedSalesStatuses.length > 0 ||
                        selectedOpportunities.length > 0
                    ) {

                      
                        this.setState({
                            selectedSubSectors,
                            selectedSubVerticles,
                            selectedOwnerRegions,
                            selectedOwnerDeliveries,
                            selectedRoleGroups,
                            //  selectedSalesStages,
                            // selectedSalesStatuses,
                            filteredOpportunities: selectedOpportunities,


                        },
                            () => this.viewReport()
                        )
                    }
                    // else { window.history.pushState({ id: window.location.href }, '', `${url}?comp=Reports&tab=2`) }


                });

            })
            .catch(e => {
                this.props.enqueueSnackbar('Something went wrong. Please try again later.', {
                    variant: 'error'
                });
            })
    }

    getAllApi() {
        return new Promise((resolve, reject) => {
            Service.get(dvcReport_api, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {

                    let data = res.data
                    this.setState({ opportunity: data.crm, allEmp: data.ptdEmployees, roles: data.roles, loading: false });
                    this.allEmp = data.ptdEmployees
                    this.roles = data.roles
                    resolve(res.data)
                })
                .catch(error => reject(error))
        })
    }

    getCRM() {
        return new Promise((resolve, reject) => {
            Service.get(crm_api + '?is_trashed=false', {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    let filtered = res.data.filter(ele => ele.bid_resource)
                    let live = [];
                    filtered.forEach(opportunity => {
                        if (opportunity.archive_reason === 0) {
                            live.push(opportunity)
                        }
                    })
                    this.setState({ data: live })
                    // live.unshift({ name: 'Select All' });
                    resolve(live)
                })
                .catch(error => reject(error))
        })
    }


    rowClicked = (e, rowdata) => {

        this.setState({ selectedOpportunity: rowdata })
        this.handleClickOpen()
    }

    handleClickOpen = () => { this.setState({ modelOpen: true }) }

    handleClose = () => { this.setState({ modelOpen: false }) }


    // handleChange = (event) => {

    //     this.setState({ filteredOpportunities: event.target.value });
    // };

    viewReport = () => {
        // this.setState({ loading: true })
        // let crmId = [];
        // this.state.selectedOpportunities.forEach(project => {
        //     if (project.id) { crmId.push(project.id) }
        // })
        // Service.patch(prp_template_api + "get_data/", {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        //     data: crmId
        // })
        //     .then(res => {
        //         // this.setState({ responseData: data })
        //         this.setState({ crmPRPs: res.data }, () => {

        //             if (this.state.selectedRoleGroups.length >= 1) { this.getAllocatedRoles() }
        //             else this.setState({ loading: false, reportModal: true })
        //         })
        //     })
        //     .catch(error => {
        //         console.log(error)
        //         this.props.enqueueSnackbar('Something went wrong!', {
        //             variant: 'error'
        //         });
        //         this.setState({ loading: false })
        //     })
        if (this.state.selectedOpportunities.length === 0) {
            this.props.enqueueSnackbar('No projects for the filter', {
                variant: 'warning'
            });
            return;
        } else if (this.state.selectedRole.length > 0 && this.state.selectedRoleGroups.length) {
            this.props.enqueueSnackbar('Both Role Group and Roles cannot be selected at the same time', {
                variant: 'warning'
            });
        }
        else {
            this.setState({
                graphDialog: true
            })
        }
    }

    getAllocatedRoles() {
        let selectedRoleGroupsIds = [];
        this.state.selectedRoleGroups.forEach((roleGrp) => {
            selectedRoleGroupsIds.push(roleGrp.id);
        });
        Service.get(roles_group_allocation_api + `role_group_multiple_data/?role_group=${selectedRoleGroupsIds.join()}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                // this.setState({ data: res.data })
                let d = []
                for (let i = 0; i < res.data.length; i++) {
                    for (let j = 0; j < this.state.allActiveRoles.length; j++) {
                        if (res.data[i].role === this.state.allActiveRoles[j].id) {
                            d.push(this.state.allActiveRoles[j])
                        }
                    }
                }

                this.groupRoles = d;
                this.setState({ loading: false, reportModal: true, multipleRoleGroups: res.data, groupedRoles: d })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });

            })
    }

    closeReport = () => {
        this.setState({ reportModal: false })
    }

    setValue(e, value) {
        value.forEach(ele => {
            if (ele.name === "Select All") {
                // this.setState({filteredOpportunities : this.state.opportunity})
                value = this.state.allProjects
            }
        })
        let searchParams = new URLSearchParams(window.location.search)
        let selectedOpportunityIds = [];
        if (value.length > 0) {

            value.forEach((region) => {
                selectedOpportunityIds.push(region.id)
            })

            if (searchParams.has("selectedOpportunity")) {
                searchParams.set("selectedOpportunity", selectedOpportunityIds.join(","))
            } else {
                searchParams.append("selectedOpportunity", selectedOpportunityIds.join(","))
            }

        } else if (value.length === 0) {

            searchParams.delete("selectedOpportunity")
        }
        if (!searchParams.get("selectedOpportunity")) {

            searchParams.delete("selectedOpportunity")
        }

        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash

        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);


        this.setState({ filteredOpportunities: value });
    }
    filterOpportunity(value, projects, nameField) {
        if (value.length > 0) {
            let filteredOpportunities = []
            for (let valueIndex = 0; valueIndex < value.length; valueIndex++) {
                const eachValue = value[valueIndex];
                for (let opportunityIndex = 0; opportunityIndex < projects.length; opportunityIndex++) {
                    const opportunity = projects[opportunityIndex];
                    if (opportunity[nameField] === eachValue['id']) {
                        filteredOpportunities.push(opportunity)
                    }
                }
            }
            if (filteredOpportunities.length <= 0) {
                this.props.enqueueSnackbar('No projects for the filter', {
                    variant: 'info'
                });
                return []
            }
            return filteredOpportunities
        }
    }

    filterProjectsOnChange(name, value) {
        let filteredOpportunities = [...this.state.allProjects]
        this.setState({ [name]: value }, () => {
            if (filteredOpportunities.length > 0 && this.state.selectedSubSectors.length > 0) {
                filteredOpportunities = this.filterOpportunity(this.state.selectedSubSectors, filteredOpportunities, 'sub_sector')
            }
            if (filteredOpportunities.length > 0 && this.state.selectedSubVerticles.length > 0) {
                filteredOpportunities = this.filterOpportunity(this.state.selectedSubVerticles, filteredOpportunities, 'market_sub_vertical')
            }
            if (filteredOpportunities.length > 0 && this.state.selectedOwnerRegions.length > 0) {
                filteredOpportunities = this.filterOpportunity(this.state.selectedOwnerRegions, filteredOpportunities, 'owner_region_sector')
            }
            if (filteredOpportunities.length > 0 && this.state.gateStageSelected.length > 0) {
                filteredOpportunities = this.filterOpportunity(this.state.gateStageSelected, filteredOpportunities, 'gate_level_acheived')
            }
            if (value.length > 0) {
                this.setState({ selectedOpportunities: filteredOpportunities, opportunitis: filteredOpportunities })
            } else {
                this.setState({ selectedOpportunities: [], opportunitis: this.state.allProjects }, () => {
                    this.setState(prevState => ({
                        opportunitis: [{ name: 'Select All' }, ...prevState.opportunitis]
                    }))
                })
            }
        })


    }


    filterChange = (name, value) => {
        let searchParams = new URLSearchParams(window.location.search)
        let hash = window.location.hash
        if (name === 'selectedSubSectors') {
            if (value.length > 0) {
                let selectedSubSectorIds = [];
                value.forEach((region) => {
                    selectedSubSectorIds.push(region.id)
                })
                if (searchParams.has("selectedSubSectors")) {
                    searchParams.set("selectedSubSectors", selectedSubSectorIds.join(","))
                } else {
                    searchParams.append("selectedSubSectors", selectedSubSectorIds.join(","))
                }

            } else if (value.length === 0) {
                searchParams.delete("selectedSubSectors")

            }
            // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
            // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
            let hash = window.location.hash

            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

            window.history.pushState(null, '', newRelativePathQuery);


        }
        if (name === 'selectedSubVerticless') {
            if (value.length > 0) {
                let selectedSubVerticleIds = [];
                value.forEach((region) => {
                    selectedSubVerticleIds.push(region.id)
                })
                if (searchParams.has("selectedSubVerticless")) {
                    searchParams.set("selectedSubVerticless", selectedSubVerticleIds.join(","))
                } else {
                    searchParams.append("selectedSubVerticless", selectedSubVerticleIds.join(","))
                }

            } else if (value.length === 0) {
                searchParams.delete("selectedSubVerticless")

            }
            // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
            // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())


            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

            window.history.pushState(null, '', newRelativePathQuery);

        }

        let selectedValues = []
        if (name === 'selectedOwnerDeliveries') {
            selectedValues = value
            if (value.length > 0) {
                let selectedOwnerDeliveryIds = [];
                value.forEach((region) => {
                    selectedOwnerDeliveryIds.push(region.id)
                })
                if (searchParams.has("selectedOwnerDeliveries")) {
                    searchParams.set("selectedOwnerDeliveries", selectedOwnerDeliveryIds.join(","))
                } else {
                    searchParams.append("selectedOwnerDeliveries", selectedOwnerDeliveryIds.join(","))
                }

            } else if (value.length === 0) {
                searchParams.delete("selectedOwnerDeliveries")

            }
            // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
            // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())


            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

            window.history.pushState(null, '', newRelativePathQuery);

        }
        if (name === 'selectedRoleGroups') {
            if (value.length > 0) {
                let selectedRoleGroupsIds = [];
                value.forEach((region) => {
                    selectedRoleGroupsIds.push(region.id)
                })
                if (searchParams.has("selectedRoleGroups")) {
                    searchParams.set("selectedRoleGroups", selectedRoleGroupsIds.join(","))
                } else {
                    searchParams.append("selectedRoleGroups", selectedRoleGroupsIds.join(","))
                }

            } else if (value.length === 0) {
                searchParams.delete("selectedRoleGroups")

            }
            // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
            // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())


            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

            window.history.pushState(null, '', newRelativePathQuery);

        }

        if (name === 'selectedOwnerRegions') {
            if (value.length > 0) {
                let selectedOwnerRegionIds = [];
                value.forEach((region) => {
                    selectedOwnerRegionIds.push(region.id)
                })
                if (searchParams.has("selectedOwnerRegions")) {
                    searchParams.set("selectedOwnerRegions", selectedOwnerRegionIds.join(","))
                } else {
                    searchParams.append("selectedOwnerRegions", selectedOwnerRegionIds.join(","))
                }

            } else if (value.length === 0) {
                searchParams.delete("selectedOwnerRegions")

            }
            // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
            // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())


            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

            window.history.pushState(null, '', newRelativePathQuery);

            for (let i = 0; i < value.length; i++) {
                if (value[i].id === 14) {
                    for (let j = 0; j < this.state.ownerDeliveries.length; j++) {
                        if (
                            this.state.ownerDeliveries[j].id === 39 ||
                            this.state.ownerDeliveries[j].id === 40 ||
                            this.state.ownerDeliveries[j].id === 41
                        ) {
                            selectedValues.push(this.state.ownerDeliveries[j])
                        }
                    }
                }
                if (value[i].id === 13) {
                    for (let j = 0; j < this.state.ownerDeliveries.length; j++) {
                        if (
                            this.state.ownerDeliveries[j].id === 19 ||
                            this.state.ownerDeliveries[j].id === 12 ||
                            this.state.ownerDeliveries[j].id === 42 ||
                            this.state.ownerDeliveries[j].id === 1 ||
                            this.state.ownerDeliveries[j].id === 46
                        ) {
                            selectedValues.push(this.state.ownerDeliveries[j])
                        }
                    }
                }
                if (value[i].id === 8) {
                    for (let j = 0; j < this.state.ownerDeliveries.length; j++) {
                        if (
                            this.state.ownerDeliveries[j].id === 16 ||
                            this.state.ownerDeliveries[j].id === 29 ||
                            this.state.ownerDeliveries[j].id === 30
                        ) {
                            selectedValues.push(this.state.ownerDeliveries[j])
                        }
                    }
                }
                if (value[i].id === 4) {
                    for (let j = 0; j < this.state.ownerDeliveries.length; j++) {
                        if (
                            this.state.ownerDeliveries[j].id === 21 ||
                            this.state.ownerDeliveries[j].id === 18 ||
                            this.state.ownerDeliveries[j].id === 14 ||
                            this.state.ownerDeliveries[j].id === 36
                        ) {
                            selectedValues.push(this.state.ownerDeliveries[j])
                        }
                    }
                }
            }
        }

        this.setState({
            [name]: value,
            selectedOwnerDeliveries: value
        }, () => this.filterProjectsOnChange())
    }
    findOutIsSelected(crmArray, selectedArray) {
        let selectFiltered = []
        for (let crmIndex = 0; crmIndex < crmArray.length; crmIndex++) {
            for (let selectIndex = 0; selectIndex < selectedArray.length; selectIndex++) {
                if (crmArray[crmIndex].sub_sector === selectedArray[selectIndex].id) {
                    selectFiltered.push(crmArray[crmIndex])
                }
            }

        }
        return selectFiltered
    }
    filttterOpportunity() {
        let allProjects = [...this.state.allProjects]
        let filter1 = [];
        let filter2 = [];
        let filter3 = [];
        let filter4 = [];

        if (!(this.state.selectedSubSectors.length > 0)) {
            filter1 = [...this.state.allProjects]
        }
        else {
            allProjects.forEach(ele => {
                for (let i = 0; i < this.state.selectedSubSectors.length; i++) {
                    if (this.state.selectedSubSectors[i].id === ele.sub_sector) { filter1.push(ele) }
                }
            })
        }

        if (!this.state.selectedSubVerticles || !this.state.selectedSubVerticles.length) {
            filter2 = [...filter1]
        }
        else {
            filter1.forEach(ele => {
                for (let i = 0; i < this.state.selectedSubVerticles.length; i++) {
                    if (this.state.selectedSubVerticles[i].id === ele.market_sub_vertical) { filter2.push(ele) }
                }
            })
        }

        if (!this.state.selectedOwnerRegions || !this.state.selectedOwnerRegions.length) {
            filter3 = [...filter2]
        }
        else {
            filter2.forEach(ele => {
                for (let i = 0; i < this.state.selectedOwnerRegions.length; i++) {
                    if (this.state.selectedOwnerRegions[i].id === ele.owner_region_sector) { filter3.push(ele) }
                }
            })
        }

        if (!this.state.selectedOwnerDeliveries || !this.state.selectedOwnerDeliveries.length) {
            filter4 = [...filter3]
        }
        else {
            filter3.forEach(ele => {
                for (let i = 0; i < this.state.selectedOwnerDeliveries.length; i++) {
                    if (this.state.selectedOwnerDeliveries[i].id === ele.owner_delivery) { filter4.push(ele) }
                }
            })
        }
        let allFilteredCRMs = [...this.state.allProjects]


        if (this.state.selectedSubSectors.length > 0) {
            allFilteredCRMs = this.findOutIsSelected(allFilteredCRMs, this.state.selectedSubSectors)
        }
        if (this.state.selectedSubVerticles.length > 0) {
            allFilteredCRMs = this.findOutIsSelected(allFilteredCRMs, this.state.selectedSubVerticles)
        }
        if (this.state.selectedOwnerRegions.length > 0) {
            allFilteredCRMs = this.findOutIsSelected(allFilteredCRMs, this.state.selectedOwnerRegions)
        }
        if (this.state.selectedOwnerDeliveries.length > 0) {
            allFilteredCRMs = this.findOutIsSelected(allFilteredCRMs, this.state.selectedOwnerDeliveries)
        }
        let filteredCRMsarray = [];
        allFilteredCRMs.forEach(crm => filteredCRMsarray.push(crm.id))

        let searchParams = new URLSearchParams(window.location.search)
        if (filteredCRMsarray.length > 0) {
            if (searchParams.has("selectedOpportunity")) {
                searchParams.set("selectedOpportunity", filteredCRMsarray.join(","))
            } else {
                searchParams.append("selectedOpportunity", filteredCRMsarray.join(","))
            }

        } else if (filteredCRMsarray.length === 0) {
            searchParams.delete("selectedOpportunity")
        }
        if (!searchParams.get("selectedOpportunity")) {

            searchParams.delete("selectedOpportunity")
        }

        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash

        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ opportunitis: allFilteredCRMs, filteredOpportunities: allFilteredCRMs })

    }


    handleChange = (event, newValue) => {
        this.setState({ value: newValue, }, () => {
            // this.setState({ projectLoading: false, loading: false })\
            this.setState({
                selectedRole: [],
                selectedRoleGroups: [],
                checked: false
            })
        })
    };

    render() {

        // const filterOptions = (options) =>{
        //     return [, ...options}]
        // }

        return (<>
            {this.state.loading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                <div>

                    <Grid container style={{ marginBottom: '20px' }} spacing={2}>
                        <Grid item xs={4}>
                            <Autocomplete
                                multiple
                                id="sub-sector"
                                options={this.state.subSectors}
                                disableCloseOnSelect
                                // filterOptions={filterOptions}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => {
                                    let index = value.findIndex(x => x.name === 'Select All')


                                    if (index >= 0) {
                                        this.setState({
                                            allSelectSectors: true
                                        }, () => {
                                            this.filterProjectsOnChange('selectedSubSectors', this.state.subSectors)

                                        })
                                    } else {
                                        if (this.state.allSelectSectors) {
                                            this.filterProjectsOnChange('selectedSubSectors', [])
                                            this.setState({
                                                allSelectSectors: false,
                                                selectedOpportunities: [],
                                                opportunitis: []
                                            })
                                        } else {
                                            this.filterProjectsOnChange('selectedSubSectors', value)
                                        }
                                    }
                                }}
                                value={this.state.selectedSubSectors}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Sector" placeholder="Sector" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                multiple
                                id="market-sub-verticle"
                                options={this.state.subVerticles}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => {
                                    let index = value.findIndex(x => x.name === 'Select All')


                                    if (index >= 0) {
                                        this.setState({
                                            allSelectVerticles: true
                                        }, () => {
                                            this.filterProjectsOnChange('selectedSubVerticles', this.state.subVerticles)

                                        })
                                    } else {
                                        if (this.state.allSelectVerticles) {
                                            this.filterProjectsOnChange('selectedSubVerticles', [])
                                            this.setState({
                                                allSelectVerticles: false,
                                                selectedOpportunities: [],
                                                opportunitis: []
                                            })
                                        } else {
                                            this.filterProjectsOnChange('selectedSubVerticles', value)
                                        }
                                    }
                                }}
                                value={this.state.selectedSubVerticles}
                                size="small"
                                getOptionSelected={(option, value) => {
                                    if (option) {
                                        return (option.id === value.id);
                                    }
                                    return false;
                                }}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Market Sub Verticle" placeholder="Market Sub Verticle" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                multiple
                                id="owner-region-sector"
                                options={this.state.allOwnerRegions}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => {
                                    let index = value.findIndex(x => x.name === 'Select All')


                                    if (index >= 0) {
                                        this.setState({
                                            allSelectOwnerRegions: true
                                        }, () => {
                                            this.filterProjectsOnChange('selectedOwnerRegions', this.state.allOwnerRegions)

                                        })
                                    } else {
                                        if (this.state.allSelectOwnerRegions) {
                                            this.filterProjectsOnChange('selectedOwnerRegions', [])
                                            this.setState({
                                                allSelectOwnerRegions: false,
                                                selectedOpportunities: [],
                                                opportunitis: []
                                            })
                                        } else {
                                            this.filterProjectsOnChange('selectedOwnerRegions', value)
                                        }
                                    }
                                }}
                                value={this.state.selectedOwnerRegions}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Region" placeholder="Region" />
                                )}
                            />
                        </Grid>
                        </Grid>
                        <Grid container style={{ marginBottom: '20px' }} spacing={2}>
                        <Grid item xs={6}>
                            <Autocomplete
                                multiple
                                id="owner-delivery-unit"
                                options={this.state.ownerDeliveries}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => {
                                    let index = value.findIndex(x => x.name === 'Select All')
                                    if (index >= 0) {
                                        this.setState({
                                            allSelectOwnerDeliveries: true
                                        }, () => {
                                            this.filterProjectsOnChange('selectedOwnerDeliveries', this.state.ownerDeliveries)

                                        })
                                    } else {
                                        if (this.state.allSelectOwnerDeliveries) {
                                            this.filterProjectsOnChange('selectedOwnerDeliveries', [])
                                            this.setState({
                                                allSelectOwnerDeliveries: false,
                                                selectedOpportunities: [],
                                                opportunitis: []
                                            })
                                        } else {
                                            this.filterProjectsOnChange('selectedOwnerDeliveries', value)
                                        }
                                    }
                                }}
                                value={this.state.selectedOwnerDeliveries}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Owner Delivery Unit" placeholder="Owner Delivery Unit" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                        <Autocomplete
                                multiple
                                options={this.state.gateStages}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => {
                                    let index = value.findIndex(x => x.name === 'Select All')
                                    if (index >= 0) {
                                        this.setState({
                                            allSelectOwnerDeliveries: true
                                        }, () => {
                                            this.filterProjectsOnChange('gateStageSelected', this.state.gateStages)

                                        })
                                    } else {
                                        if (this.state.allSelectOwnerDeliveries) {
                                            this.filterProjectsOnChange('gateStageSelected', [])
                                            this.setState({
                                                allSelectOwnerDeliveries: false,
                                                selectedOpportunities: [],
                                                opportunitis: []
                                            })
                                        } else {
                                            this.filterProjectsOnChange('gateStageSelected', value)
                                        }
                                    }
                                }}
                                value={this.state.gateStageSelected}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Gate Stages" placeholder="Gate Stages" />
                                )}
                            />
                        </Grid>
                        <ToggleButtonGroup
                            value={this.state.value}
                            exclusive
                            size="small"
                            style={{ padding: '10px' }}
                            onChange={this.handleChange}
                        >
                            <ToggleButton value="1" aria-label="left aligned">
                                Role
                            </ToggleButton>
                            <ToggleButton value="2" aria-label="centered">
                                Role Groups
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.checked}
                                    onChange={() => this.setState({checked: !this.state.checked})}
                                    color="primary"
                                />
                            }
                            label="Regionalized"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.sector}
                                    onChange={() => this.setState({sector: !this.state.sector})}
                                    color="primary"
                                />
                            }
                            label="Sectorized"
                        />
                        {this.state.value === '2' ?
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    id="roles-group"
                                    options={this.state.rolesGroups}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, value) => {
                                        let index = value.findIndex(x => x.name === 'Select All')


                                        if (index >= 0) {
                                            this.setState({
                                                allSelectRoleGroups: true
                                            }, () => {
                                                this.setState({
                                                    selectedRoleGroups: this.state.rolesGroups
                                                })

                                            })
                                        } else {
                                            if (this.state.allSelectRoleGroups) {
                                                this.setState({
                                                    selectedRoleGroups: []
                                                }, () => {
                                                    this.setState({
                                                        allSelectRoleGroups: false,
                                                        selectedOpportunities: []
                                                    })
                                                })
                                            } else {
                                                this.setState({
                                                    selectedRoleGroups: value
                                                })
                                            }
                                        }

                                    }}
                                    value={this.state.selectedRoleGroups}
                                    size="small"
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.name}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Role Groups" placeholder="Roles Groups" />
                                    )}
                                />
                            </Grid> : null}
                        {this.state.value === '1' ?
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    id="roles"
                                    options={this.state.roles}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.job_roles}
                                    onChange={(e, value) => {
                                        let index = value.findIndex(x => x.job_roles === 'Select All')


                                        if (index >= 0) {
                                            this.setState({
                                                allSelectRole: true
                                            }, () => {
                                                this.setState({

                                                    selectedRole: this.state.roles
                                                })

                                            })
                                        } else {
                                            if (this.state.allSelectRole) {
                                                this.setState({
                                                    selectedRole: []
                                                }, () => {
                                                    this.setState({
                                                        allSelectRole: false,
                                                        selectedOpportunities: []
                                                    })
                                                })
                                            } else {
                                                this.setState({
                                                    selectedRole: value
                                                })
                                            }
                                        }
                                    }}
                                    value={this.state.selectedRole}
                                    size="small"
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.job_roles}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Roles" />
                                    )}
                                />
                            </Grid> : null}
                       
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="opportunity"
                                options={this.state.opportunitis}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => {
                                    // if (value.length === 0) {
                                    //     this.setState({
                                    //         selectedRole: [],
                                    //         selectedRoleGroups: [],
                                    //         selectedOwnerDeliveries: [],
                                    //         selectedOwnerRegions: [],
                                    //         selectedSubSectors: [],
                                    //         selectedSubVerticles: [],
                                    //         selectedOpportunities: [],
                                    //         opportunitis: this.state.allProjects,
                                    //     })
                                    // } 
                                    // else{
                                    let index = value.findIndex(x => x.name === 'Select All')


                                    if (index >= 0) {
                                        this.setState({
                                            allSelectProject: true
                                        }, () => {
                                            this.setState({
                                                selectedOpportunities: this.state.opportunitis
                                            })
                                        })
                                    } else {
                                        if (this.state.allSelectProject) {
                                            this.setState({
                                                selectedRole: []
                                            }, () => {
                                                this.setState({
                                                    allSelectProject: false,
                                                    selectedOpportunities: []
                                                })
                                            })
                                        } else {
                                            this.setState({
                                                selectedOpportunities: value
                                            })
                                        }
                                    }
                                    // }
                                }}
                                value={this.state.selectedOpportunities}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Projects" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ position: 'relative', textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                // size="medium"
                                color="primary"
                                onClick={this.viewReport}
                                // style={{ position: 'absolute', bottom: '4px', marginLeft: '20px' }}
                                disabled={this.state.selectedOpportunities.length === 0}>Submit</Button>
                        </Grid>
                    </Grid>
                    <Dialog fullScreen open={this.state.graphDialog}>
                        <AppBar>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={() => { this.setState({ graphDialog: false }) }} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" >
                                    {/* <Box display={'flex'} flexDirection={'row'}>
                      {this.state?.selectedProject?.map((data, index) => {
                        return <Box marginRight={2}>{data.name}</Box>
                      })}
                    </Box> */}
                                    Filter by Project
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <Box marginTop={10}>
                                <RolesTable selectedProject={this.state.selectedOpportunities}
                                    id={this.state.id}
                                    type={'project'}
                                    selectedRole={this.state.selectedRole}
                                    selectedRoleGroups={this.state.selectedRoleGroups}
                                    withRegion={this.state.checked}
                                    withSector={this.state.sector}
                                />
                            </Box>
                        </DialogContent>
                    </Dialog>

                    {/* {this.state.reportModal ?

                        <ResourceReport
                            crmData={this.state.crmPRPs}
                            // isLoading={this.props.isLoading}
                            allEmp={this.state.allEmp}
                            roles={this.state.allActiveRoles}
                            rolesObj={this.state.rolesObj}
                            groupRoles={this.state.groupedRoles}
                            allRolesGroup={this.state.rolesGroups}
                            selectedRoleGroup={this.state.selectedRoleGroups}
                            selectedOwnerRegion={this.state.selectedOwnerRegions}
                            selectedSubSector={this.state.selectedSubSectors}
                            selectedOwnerDelivery={this.state.selectedOwnerDeliveries}
                            selectedSubVerticle={this.state.selectedSubVerticles}
                            allEmpRegionHistory={this.state.allEmpRegionHistory}
                            multipleRoleGroups={this.state.multipleRoleGroups}
                        />
                        : null} */}
                </div >
            }
        </>)
    }
}

export default withSnackbar(withStyles(useStyles)(DemandVsCapacityHome))